import React from "react";
import ElementsTreeView from "./ElementsTreeView";
import ProjectsTreeView from "./ProjectsTreeView";

import materials from "../../data/materials";
import profiles from "../../data/profiles2";

const Explorer = () => {
  return (
    <>
      <ProjectsTreeView />
      <ElementsTreeView elements={profiles} elementsName={"profilesHeader"} />
      <ElementsTreeView elements={materials} elementsName={"materialsHeader"} />
    </>
  );
};

export default Explorer;
