import React from "react";
import "./ModelTab.css";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import mefaActions from "../../../app/mefa-app/duck/actions";

class ScrollableTabsButtonAuto extends React.Component {
  state = {
    value: 0,
    removeTabFlag: false,
  };

  handleChange = (event, newValue) => {
    if (this.state.removeTabFlag === false) {
      this.props.changeModel(newValue);
      this.setState({
        value: newValue
      });
    }
  };


  deleteModel = model => {
    if (this.props.selectedModelID !== model.id) {
      this.props.deleteModel(model)
      this.props.changeModel(this.props.selectedModelID)
    } else if (this.props.models.length === 1 && this.props.selectedModelID === model.id) {
       this.props.deleteModel(model)
       this.setState({ removeTabFlag: false })
    }
    else {
      let modell=this.props.models.find(mode=> {return this.props.selectedModelID !== mode.id ;})
      this.props.changeModel(modell.id);
      this.props.deleteModel(model)
      this.props.changeModel(modell.id);
    }
  };

  render() {
    return (
      <div className="modelTabWrapper">
        <AppBar position="static" color="default">
          <Tabs
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={this.props.selectedModelID}
          >
            {this.props.models.map(elem => {
              return (
                    <Tab 
                        key={elem.id} 
                        label={elem.name} 
                        value={elem.id}
                        icon={
                          <CloseIcon 
                            onMouseOver={() => this.setState({ removeTabFlag: true })} 
                            onMouseLeave={() => this.setState({ removeTabFlag: false })}
                            onClick={() => this.deleteModel(elem)} />} 
                          >
                      </Tab>);
                    })
            }
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeModel: model => dispatch(mefaActions.changeModel(model)),
  deleteModel: model => dispatch(mefaActions.deleteModel(model)),
});

const mapStateToProps = state => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  selectedModelID: state.mefa.present.selectedModelId,
  models: state.mefa.present.models
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollableTabsButtonAuto);
