import React from "react";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { connect } from "react-redux";
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';

let UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }) => (
  <div style={{marginLeft: "10px",marginRight: "5px"}}>
    <button onClick={onUndo} disabled={!canUndo} style={{borderRadius:"4px",border:"2px solid gray",margin:"1px"}}>
      <UndoIcon style={{fontSize:"1.3rem",color: "rgb(0 0 0 / 74%)"}}/>
    </button>
    <button onClick={onRedo} disabled={!canRedo} style={{borderRadius:"4px",border:"2px solid gray"}}>
      <RedoIcon style={{fontSize:"1.3rem",color: "rgb(0 0 0 / 74%)"}}/>
    </button>
  </div>
);

const mapStateToProps = state => {
  return {
    canUndo: state.mefa.past.length > 0,
    canRedo: state.mefa.future.length > 0
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo())
  };
};

UndoRedo = connect(mapStateToProps, mapDispatchToProps)(UndoRedo);

export default UndoRedo;