import types from './types'
import undoable, { excludeAction } from 'redux-undo';
import BeamModel from '../../classes/model/BeamModel'
import SectionElement from '../../classes/element/SectionElement'
import ConcentratedLoadElement from '../../classes/load/ConcentratedLoadElement'
import DistributedLoadElement from '../../classes/load/DistributedLoadElement'
import NodeElement from '../../classes/node/NodeElement'
import FixedSupportElement from '../../classes/support/FixedSupportElement'
import NoneSupportElement from '../../classes/support/NoneSupportElement'
import SupportElement from '../../classes/support/SupportElement'
import MomentLoadElement from '../../classes/load/MomentLoadElement'


const INITIAL_STATE = {
    selectedLanguage: null,
    selectedModelElement: null,
    selectedModelId: 0,
    selectedModelIndex: 0,

    charPointsMomentTemp: [],
    charPointsDeflectionTemp: [],
    supportReactions:[],

    globalMaxM: 0,
    globalMinM: 0,
    globalMaxV: 0,
    globalMinV: 0,

    pdfNmax: 0,
    pdfNdop: 0,
    pdfNpr: 0,

    pdfVmax: 0,
    pdfVdop: 0,
    pdfVpr: 0,

    SAFTLY_FACTOR: 1.54,
    DEFLECTION_ARROW: false,
    GLOBAL_OFFSET: 0.1,
    GLOBAL_MODE: false,
    GLOBAL_MASS_LOAD: false,
    BENDING_MOMENT_VALUE_MODE: false,

    models: [],
}

function mefaReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.CHANGE_LANGUAGE: {
            return { ...state, selectedLanguage: action.item.value }
        }
        case types.CHANGE_GLOBAL_OFFSET: {
            return { ...state, GLOBAL_OFFSET: action.item }
        }
        case types.CHANGE_GLOBAL_MODE: {
            return { ...state, GLOBAL_MODE: action.item }
        }
        case types.CHANGE_GLOBAL_MASS_LOAD: {
            return { ...state, GLOBAL_MASS_LOAD: action.item }
        }
        case types.CHANGE_BENDING_MOMENT_VALUE_MODE: {
            return { ...state, BENDING_MOMENT_VALUE_MODE: action.item }
        }
        case types.CHANGE_SAFTLY_FACTOR: {
            return { ...state, SAFTLY_FACTOR: action.item }
        }
        case types.CHANGE_DEFLECTION_ARROW: {
            return { ...state, DEFLECTION_ARROW: action.item }
        }
        case types.CHANGE_CHAR_POINTS_MOMENT_TEMP: {
            return { ...state, charPointsMomentTemp: action.item }
        }
        case types.CHANGE_CHAR_POINTS_DEFLECTION_TEMP: {
            return { ...state, charPointsDeflectionTemp: action.item }
        }
        case types.CHANGE_M_V: {
            return { ...state, globalMaxM: action.item.maxM, globalMinM: action.item.minM,
            globalMaxV: action.item.maxV, globalMinV: action.item.minV }
        }
        case types.UPDATE_PDF_DATA:{
            return{ ...state, pdfNmax: action.item.pdfNmax,  pdfNdop: action.item.pdfNdop, pdfNpr: action.item.pdfNpr,
                pdfVmax: action.item.pdfVmax,  pdfVdop: action.item.pdfVdop, pdfVpr: action.item.pdfVpr,
             }
        }

        case types.UPDATE_ALL_GLOBAL_DATA:{
            return{ ...state, supportReactions: action.item.supportReactions,
                charPointsMomentTemp: action.item.charPointsMoment,
                charPointsDeflectionTemp: action.item.charPointsDeflection,

                globalMinM: action.item.minM,
                globalMaxM: action.item.maxM,
                globalMinV: action.item.minV,
                globalMaxV: action.item.maxV,

                pdfNmax: action.item.pdfNmax,  pdfNdop: action.item.pdfNdop, pdfNpr: action.item.pdfNpr,
                pdfVmax: action.item.pdfVmax,  pdfVdop: action.item.pdfVdop, pdfVpr: action.item.pdfVpr,
             }
        }
        case types.CHANGE_PROFILE: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf( {...model, profileId: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
              }
            }

        case types.UPDATE_LOAD_NODE_NAME: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf( {...model, loadNodeParent: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                    })
                }
        }

        case types.UPDATE_SUPPORT_NODE_NAME: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf( {...model, supportNodeParent: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                    })
                }
        }

        case types.UPDATE_NODE_NODE_NAME: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf( {...model, nodeNodeParent: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                    })
                }
        }

        case types.UPDATE_SECTION_NODE_NAME: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf( {...model, elementNodeParent: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                    })
                }
        }

        case types.CHANGE_MODEL_ELEMENT: {
            return { ...state, selectedModelElement: action.item, }
        }
        case types.CHANGE_MODEL: {
            return { ...state,selectedModelElement: null, selectedModelId: action.item, selectedModelIndex:   
                state.models.findIndex(model => model.id === action.item)
            }
        }
        case types.CHANGE_SELECTED_MODEL_ID: {
            return { ...state,selectedModelElement: null, selectedModelId: action.item}
        }
        case types.CHANGE_DISPLACEMENT: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf({...model, displacement: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
              }
        }
        case types.CHANGE_ROTATION: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        return Object.setPrototypeOf({...model, rotation: action.item},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
              }
        }
        case types.UPDATE_SECTION_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
        
                        return Object.setPrototypeOf({...model,elements: model.elements.map(element=>{
                                if(element.id === action.item.index){
                                  
                                    if(element instanceof SectionElement){
                                        return  Object.setPrototypeOf({...element,lengthL: parseFloat(action.item.value)},SectionElement.prototype)
                                    }   
                                }
                                return element;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_LOAD_L1_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,loads: model.loads.map(load=>{
                                if(load.id === action.item.index){
                                    if(load instanceof ConcentratedLoadElement){
                                        return  Object.setPrototypeOf({...load,lengthL1: parseFloat(action.item.value)},ConcentratedLoadElement.prototype)
                                    }else if(load instanceof MomentLoadElement){
                                        return  Object.setPrototypeOf({...load,lengthL1: parseFloat(action.item.value)},MomentLoadElement.prototype)
                                    }else{
                                        return  Object.setPrototypeOf({...load,lengthL1: parseFloat(action.item.value)},DistributedLoadElement.prototype)
                                    }
                                }
                                return load;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_LOAD_L1_AND_PARENT_ID__ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,loads: model.loads.map(load=>{
                                if(load.id === action.item.index){
                                    if(load instanceof ConcentratedLoadElement){
                                        return  Object.setPrototypeOf({...load,lengthL1: parseFloat(action.item.value),parentID: action.item.parentID},ConcentratedLoadElement.prototype)
                                    }else if(load instanceof MomentLoadElement){
                                        return  Object.setPrototypeOf({...load,lengthL1: parseFloat(action.item.value),parentID: action.item.parentID},MomentLoadElement.prototype)
                                    }else{
                                        return  Object.setPrototypeOf({...load,lengthL1: parseFloat(action.item.value),parentID: action.item.parentID},DistributedLoadElement.prototype)
                                    }
                                }
                                return load;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_LOAD_L2_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,loads: model.loads.map(load=>{
                                if(load.id === action.item.index){
                                   
                                    return  Object.setPrototypeOf({...load,lengthL2: parseFloat(action.item.value)},DistributedLoadElement.prototype)
                                }
                                return load;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_LOAD_FORCE_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,loads: model.loads.map(load=>{
                                if(load.id === action.item.index){
                                   
                                    if(load instanceof ConcentratedLoadElement){
                                        return  Object.setPrototypeOf({...load,force: parseFloat(action.item.value)},ConcentratedLoadElement.prototype)
                                    }else if(load instanceof MomentLoadElement){
                                        return  Object.setPrototypeOf({...load,force: parseFloat(action.item.value)},MomentLoadElement.prototype)
                                    }else{
                                        return  Object.setPrototypeOf({...load,force: parseFloat(action.item.value)},DistributedLoadElement.prototype)
                                    }
                                }
                                return load;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }

        case types.UPDATE_SECTION_ELEMENT_NAME:{
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
        
                        return Object.setPrototypeOf({...model,elements: model.elements.map(element=>{
                                if(element.id === action.item.index){
                                  
                                    if(element instanceof SectionElement){
                                        return  Object.setPrototypeOf({...element,name: action.item.value},SectionElement.prototype)
                                    }   
                                }
                                return element;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_LOAD_ELEMENT_NAME:{
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,loads: model.loads.map(load=>{
                                if(load.id === action.item.index){
                                   
                                    if(load instanceof ConcentratedLoadElement){
                                        return  Object.setPrototypeOf({...load,name: action.item.value},ConcentratedLoadElement.prototype)
                                    }else if(load instanceof MomentLoadElement){
                                        return  Object.setPrototypeOf({...load,name: action.item.value},MomentLoadElement.prototype)
                                    }else{
                                        return  Object.setPrototypeOf({...load,name: action.item.value},DistributedLoadElement.prototype)
                                    }
                                }
                                return load;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_SUPPORT_ELEMENT_NAME:{
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,supports: model.supports.map(support=>{
                                if(support.id === action.item.index){
                                   
                                    if(support instanceof FixedSupportElement){
                                        return  Object.setPrototypeOf({...support,name: action.item.value},FixedSupportElement.prototype)
                                    }else if(support instanceof NoneSupportElement){
                                        return  Object.setPrototypeOf({...support,name: action.item.value},NoneSupportElement.prototype)
                                    }else{
                                        return  Object.setPrototypeOf({...support,name: action.item.value},SupportElement.prototype)
                                    }
                                }
                                return support;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_SUPPORT_REACTIONS:{
            return {
                ...state, supportReactions: action.item
            }
        }
        // case types.UPDATE_SUPPORT_REACTIONS:{
        //     return {
        //         ...state, models: state.models.map(model => {
        //             if(model.id === state.selectedModelId) {
                            
        //                 return Object.setPrototypeOf({...model,supports: model.supports.map((support,index)=>{
        //                         let newSupportReaction=action.item[index];
                                   
        //                             if(support instanceof FixedSupportElement){
        //                                 return  Object.setPrototypeOf({...support,supportReaction: parseFloat(newSupportReaction)},FixedSupportElement.prototype)
        //                             }else if(support instanceof NoneSupportElement){
        //                                 return  Object.setPrototypeOf({...support,supportReaction: parseFloat(newSupportReaction)},NoneSupportElement.prototype)
        //                             }else{
        //                                 return  Object.setPrototypeOf({...support,supportReaction: parseFloat(newSupportReaction)},SupportElement.prototype)
        //                             }
                                
                                
        //                     })},BeamModel.prototype);
        //             }
        //             return Object.setPrototypeOf({...model},BeamModel.prototype);
        //           })
        //     }
        // }
        case types.UPDATE_SUPPORT_ELEMENT:{
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,supports: model.supports.map((support,index)=>{
                                if(index !== 0 && index !== (model.supports.length-1) ){
                                   
                                    return  Object.setPrototypeOf({...support, classType: "SupportElement" },SupportElement.prototype) 
                                }else{
                                
                                    if(support instanceof FixedSupportElement){
                                        return  Object.setPrototypeOf({...support},FixedSupportElement.prototype)
                                    }else if(support instanceof NoneSupportElement){
                                        return  Object.setPrototypeOf({...support},NoneSupportElement.prototype)
                                    }else{
                                        return  Object.setPrototypeOf({...support},SupportElement.prototype)
                                    }
                            }
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_NODE_ELEMENT_NAME:{
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
        
                        return Object.setPrototypeOf({...model,nodes: model.nodes.map(node=>{
                                if(node.id === action.item.index){
                                  
                                    if(node instanceof NodeElement){
                                        return  Object.setPrototypeOf({...node,name: action.item.value},NodeElement.prototype)
                                    }   
                                }
                                return node;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.ADD_SECTION_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {

                       let newArray = model.elements.slice()
                       newArray.splice(model.elements.length, 0, action.item)

                       return Object.setPrototypeOf({...model,elements: newArray},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.ADD_SUPPORT_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {

                       let newArray = model.supports.slice()
                       newArray.splice(model.supports.length, 0, action.item)

                       return Object.setPrototypeOf({...model,supports: newArray},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.ADD_LOAD_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {

                       let newArray = model.loads.slice()
                       newArray.splice(model.loads.length, 0, action.item)

                       return Object.setPrototypeOf({...model,loads: newArray},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.ADD_NODE_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {

                       let newArray = model.nodes.slice()
                       newArray.splice(model.nodes.length, 0, action.item)

                       return Object.setPrototypeOf({...model,nodes: newArray},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_SUPPORT_ELEMENT_LEFT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,supports: model.supports.map((support,index)=>{
                                if(index === 0){
                                   support=action.item
                                }
                                return support;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.UPDATE_SUPPORT_ELEMENT_RIGHT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {
                            
                        return Object.setPrototypeOf({...model,supports: model.supports.map((support,index)=>{
                                if(index === (model.supports.length-1)){
                                   support=action.item
                                }
                                return support;
                            })},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }
        case types.DELETE_LOAD_ELEMENT: {
            return {
                ...state, models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {

                        return Object.setPrototypeOf( {...model,loads: model.loads.filter((load) => load.id !== action.item.id)},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }

        case types.DELETE_SECTION_ELEMENT: {

            let lastSectionElementId= state.models[state.selectedModelIndex].elements[state.models[state.selectedModelIndex].elements.length-1].id;
           
            return {
                ...state,selectedModelElement:null, charPointsDeflectionTemp:[], charPointsMomentTemp:[],supportReactions:[], models: state.models.map(model => {
                    if(model.id === state.selectedModelId) {

                        return Object.setPrototypeOf( {...model, loads: model.loads.filter((load) => load.parentID !== lastSectionElementId),
                        supports: model.supports.filter((element,index) => index !== (model.supports.length-1)),
                        nodes: model.nodes.filter((element,index) => index !== (model.nodes.length-1)),
                        elements: model.elements.filter((element,index) => index !== (model.elements.length-1)),
                    
                    },BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
        }

        case types.UPDATE_MODEL: {
            return {...state, selectedModelElement: null,charPointsDeflectionTemp:[], charPointsMomentTemp:[],supportReactions:[],
                models: state.models.map((model) => {
                    if(model.id === state.selectedModelId) {
                        model= action.item;
                        return Object.setPrototypeOf({...model},BeamModel.prototype);
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
              }
         }
         case types.UPDATE_MODEL_NAME: {
            return {...state,
                models: state.models.map((model) => {
                    if(model.id === action.item.index) {
                            if(model instanceof BeamModel){
                                return  Object.setPrototypeOf({...model,name: action.item.value},BeamModel.prototype)
                            }   
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
              }
         }
         case types.ADD_MODEL:{
            let newArray = state.models.slice()
            newArray.splice(state.models.length, 0, action.item)
    
            return {...state,selectedModelElement: null, models: newArray}
         }
         case types.ADD_INITIAL_MODEL:{
            let newArray = state.models.slice()
            newArray.splice(state.models.length, 0, action.item)
    
            return {...state,selectedModelElement: null, models: newArray}
         }
         case types.DELETE_MODEL:{
             if(state.models.length === 1){ 
                return {
                    ...state,selectedModelElement: null, models: [],selectedModelIndex: 0 
                }
             }
            return {
                ...state, models: state.models.filter((model,index) => model.id !== action.item.id),
            }
         }


        //  case types.DELETE_MODEL:{
        //     let niuselectedid=[...state.selectedModelId];
        //     //let niusele=[]
        //     let test =[...state.models];
        //     let newtab =test.filter((model,index) => model.id !== action.item.id)
        //     let newIndex=newtab.findIndex(model => model.id === state.selectedModelId)
        //     if(newIndex === -1)
        //      {newIndex =0
        //         if(newtab.length === 0){
        //             niuselectedid=[]
        //             niuselectedid.push(null)
        //         }else{
        //             newIndex=0
        //             niuselectedid=[]
        //             niuselectedid.push(newtab[0].id)
        //         }
        //     }
        //     console.log()
        //     console.log(newtab)
        //     return {
        //         ...state,selectedModelElement: null,selectedModelIndex: newIndex,selectedModelId: niuselectedid[0], models: state.models.filter((model,index) =>
        //                 {
        //                 if(model.id !== action.item.id){
        //                     //if(state.selectedModelId === model.id){
        //                        // state.selectedModelIndex=0
        //                         //state.selectedModelId=newtab[state.selectedModelIndex].id;
        //                     //}else{
        //                     return model;
        //                     //}
                            
        //                 } 
        //             }
        //         )
        //     }
        //  }

         case types.LOAD_PROJECT_FROM_FILE:{

            return {
                ...state,selectedModelElement: null, selectedModelId: action.item.models.length >= 1? action.item.models[0].id : state.selectedModelId  , models: action.item.models.map(model => {
                    if(model.classType === "BeamModel") {
                        state.selectedModelId=action.item.models[0].id //TODO ERROR ITP
                        state.selectedModelIndex=0;
                        return Object.setPrototypeOf({...model,loads: model.loads.map(load=>{
                                   console.log(state.selectedModelId)
                                if(load.classType === "ConcentratedLoadElement"){
                                     return  Object.setPrototypeOf({...load},ConcentratedLoadElement.prototype)
                                }else if(load.classType === "MomentLoadElement"){
                                       return  Object.setPrototypeOf({...load},MomentLoadElement.prototype)
                                 }else if(load.classType === "DistributedLoadElement"){
                                     return  Object.setPrototypeOf({...load},DistributedLoadElement.prototype)
                                }
                               return load;
                            }),
                            supports: model.supports.map(support=>{
                                   
                                if(support.classType === "FixedSupportElement"){
                                     return  Object.setPrototypeOf({...support},FixedSupportElement.prototype)
                                }else if(support.classType === "NoneSupportElement"){
                                       return  Object.setPrototypeOf({...support},NoneSupportElement.prototype)
                                 }else if(support.classType === "SupportElement"){
                                     return  Object.setPrototypeOf({...support},SupportElement.prototype)
                                }
                               return support;
                            }),
                            elements: model.elements.map(element=>{
                                if(element.classType === "SectionElement"){
                                     return  Object.setPrototypeOf({...element},SectionElement.prototype)
                                }
                               return element;
                            }),
                            nodes: model.nodes.map(node=>{
                                if(node.classType === "NodeElement"){
                                     return  Object.setPrototypeOf({...node},NodeElement.prototype)
                                }
                               return node;
                            }),

                        },BeamModel.prototype)
                    }
                    return Object.setPrototypeOf({...model},BeamModel.prototype);
                  })
            }
         }
        
        default:
            return state
    }
}

const undoableTodos = undoable(mefaReducer, { filter: excludeAction([
    types.CHANGE_LANGUAGE,
    types.CHANGE_MODEL_ELEMENT,
    types.CHANGE_DISPLACEMENT,
    types.UPDATE_MODEL,
    types.ADD_SUPPORT_ELEMENT,
    types.ADD_NODE_ELEMENT,
    types.UPDATE_SUPPORT_REACTIONS,
    types.CHANGE_PROFILE,
    types.CHANGE_MODEL,
    types.CHANGE_CHAR_POINTS_DEFLECTION_TEMP,
    types.CHANGE_CHAR_POINTS_MOMENT_TEMP,
    types.CHANGE_M_V,
    types.UPDATE_SUPPORT_REACTIONS,
    types.UPDATE_SUPPORT_ELEMENT,
    types.UPDATE_PDF_DATA,
    types.CHANGE_SELECTED_MODEL_ID,
    types.ADD_INITIAL_MODEL,
    types.GLOBAL_OFFSET,
    types.UPDATE_ALL_GLOBAL_DATA,
    types.CHANGE_GLOBAL_MODE,
    types.CHANGE_GLOBAL_MASS_LOAD,
    

]) })

export default undoableTodos