export default class NodeElement {
  constructor(id, name, point) {
    this.id = id;
    this.name = name;
    this.point = point;

    this.classType="NodeElement";

  }
}
