const languages = {
  pl: {
    copyPaste: "Kopiuj-Wklej",
    removeLoad: "Usuń obciążenie",
    supportWarning:
      "Zmiana wskazanej podpory doprowadzi\u0142aby do niestabilno\u015bci uk\u0142adu",
    save: "Zapisz",
    fsv: "fsv - oc. ogniowy (S235JR)",
    fbv: "fbv - taśma oc. ogniowo met. Sendzimira (S250GD-Z275-N-A)",
    programInformation: "Informacje o projekcie",
    writerH: "Autor:",
    objectH: "Obiekt:",
    adviceH: "Uwagi:",
    generatePDFButton: "Generuj raport",
    profilHeaderSummaryPanel: "Profil",
    DispRotHeader: "Ugięcie/orientacja",
    SectionHeader: "Przęsło",
    LoadHeader: "Obciążenie",
    changeName: "Nowa nazwa",
    profilesHeader: "Profile",
    materialsHeader: "Materiały",
    materialName: "Materiał",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Pole przekroju",
    centroidDistanceEoInfo: "Położenie środka ciężkości",
    centroidDistanceEuInfo: "Położenie środka ciężkości",
    centroidDistanceEzInfo: "Położenie środka ciężkości",
    radiOfGyrationIyInfo: "Promień bezwładności",
    radiOfGyrationIzInfo: "Promień bezwładności",
    momentsOfInteriaJyInfo: "Moment bezwładności",
    momentsOfInteriaJzInfo: "Moment bezwładności",
    sectionModuliWyoInfo: "Wskaźnik wytrzymałości",
    sectionModuliWyuInfo: "Wskaźnik wytrzymałości",
    sectionModuliWzInfo: "Wskaźnik wytrzymałości",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Moduł Younga",
    yieldStrengthFykInfo: "Granica plastyczności",

    projects: "Projekty",
    nodes: "Węzły",
    loads: "Obciążenia",
    supports: "Podpory",
    elements: "Elementy",
    nodeChild: "węzeł",
    loadChild: "obciążenie",
    supportChild: "podpora",
    elementChild: "element",
    modelChild: "model",

    geometry: "Geometria",
    addSection: "Dodaj przęsło",
    deleteSection: "Usuń przęsło",
    load: "Obciążenia",
    singleLoad: "Siła skupiona",
    lineLoad: "Obc. rozłożone",
    singleMoment: "Moment skupiony",
    deleteLoad: "Usuń obciążenie",
    support: "Podparcie",
    nodeL: "Podparcie",
    nodeR: "Podparcie",
    fixNodeL: "Utwierdzenie",
    fixNodeR: "Utwierdzenie",
    noneNodeL: "Brak podpory",
    noneNodeR: "Brak podpory",
    schemas: "Schematy",
    schemaLoadCase1: "Schemat 1",
    schemaLoadCase2: "Schemat 2",
    schemaLoadCase3: "Schemat 3",
    schemaLoadCase4: "Schemat 4",
    schemaLoadCase5: "Schemat 5",
    schemaLoadCase6: "Schemat 6",
    schemaLoadCase7: "Schemat 7",

    profile: "Profil",
    momentSummary: "Moment [kNm]:",
    deflectionSummary: "Ugięcie [mm]:",
    maximalSummary: "Maksymalne:",
    admissibleSummary: "Dopuszczalne:",

    bendingMomentChartHeader: "Momenty [kNm]",
    deflectionChartHeader: "Ugięcia [mm]",

    PDFsystemProfileSelectionHeader: "Dobór szyny montażowej",
    PDFagent: "Opracował: ",
    PDFobject: "Obiekt: ",
    PDFadvice: "Uwagi: ",

    PDFnodes: "Punkty podparcia:",
    PDFprofileRailtotalLength: "Szyna profilowa: dł. całkowita: ",
    PDFprojectInformation: "Dane projektowe: ",

    PDFload: "Obciążenie",
    PDFvalue: "Wartość",
    PDFpositionA: "Pozycja A [L1]",
    PDFpositionB: "Pozycja B [L2]",
    PDFnetWeight: "Ciężar własny",

    PDFprofil: "Profil",

    PDFloadContinous: "Rozłożone",
    PDFloadConcentrated: "Skupione",
    PDFloadMoment: "Moment",

    PDFtensionAndBendingProfileRailHeader:
      "Wytrzymałość i odkształcenie szyny profilowej:",
    PDFcondition: "Warunek",
    PDFmaximum: "Maksymalne",
    PDFallowed: "Dopuszczalne",
    PDFutilisation: "Wykorzystanie [%]",
    PDFstatus: "Status",

    PDFstress: "Naprężenia",
    PDFdeflections: "Ugięcia",

    PDFconditionNotFulfilled: "Nośność przekroczona",
    PDFconditionFulfilled: "Warunek spełniony",

    PDFbendingMomentChartAndBendingChartHeader:
      "Wykres momentów zginających i linia ugięcia:",

    PDFloadPlan: "Schemat obciążeń:",

    PDFbendingMomentChart: "Wykres momentów zginających [kNm]",
    PDFbendingChart: "Linia ugięcia [mm]",

    PDFnodeReactiveForce: "Węzeł",
    PDFreaction: "Reakcja [kN]",

    PDFadvice2: "Uwagi:",
    PDFsafetyFactorInfo:
      "W obliczeniach przyjęto współczynnik bezpieczeństwa równy ",
    PDFsafetyFactorInfoEnd: " ",

    PDFsummary:
      "Wszystkie dane zawarte w programie dotyczą elementów firmy MEFA. Wyniki są generowane do raportu obliczeń na podstawie wprowadzonych przez Użytkownika danych. Użytkownik ponosi pełną odpowiedzialność za poprawność i wiarygodność umieszczanych danych, za weryfikacje wyników raportu obliczeń w odniesieniu do obowiązujących norm i przepisów. Ważność niniejszego dokumentu jest zapewniona jedynie w przypadku wymienionych w dokumencie elementów MEFA z zachowaniem zgodnych warunków budowlanych. Oprogramowanie służy tylko jako pomoc w obliczeniu statycznych nośności elementów MEFA i zapewnia bez wszelkich gwarancji co do braku błędów. Należy pamiętać o przeprowadzaniu regularnych aktualizacji oprogramowania oferowanych przez firmę MEFA",

    companyName: "MEFA Polska Sp.z.o.o.",
    streetName: "ul. Słoneczna 52G",
    cityName: "PL-05-500 Stara Iwiczna",
    websiteName: "www.mefa-polska.com.pl",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "utworzone za pomocą MEFA StatiCAL online",

    phoneNumber: "Telefon +48 22 737 20 90",
    companyEmail: "poczta@mefa-polska.com.pl",
  },
  en: {
    copyPaste: "Copy-Paste",
    removeLoad: "Delete load",
    supportWarning:
      "If you change the node it will cause the instability of the system",
    save: "Save",
    fsv: "fsv - hot-dip galvanized (S235JR)",
    fbv: "fbv - pre-galvanized (S250GD-Z275-N-A)",
    programInformation: "Program information",
    writerH: "Writer:",
    objectH: "Object:",
    adviceH: "Advice:",
    generatePDFButton: "Generate Report",
    profilHeaderSummaryPanel: "Profile",
    DispRotHeader: "Displacement/Rotation",
    SectionHeader: "Section",
    LoadHeader: "Load",
    changeName: "New name",
    profilesHeader: "Profiles",
    materialsHeader: "Materials",
    materialName: "Material",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Shear areas",
    centroidDistanceEoInfo: "Centroid distance",
    centroidDistanceEuInfo: "Centroid distance",
    centroidDistanceEzInfo: "Centroid distance",
    radiOfGyrationIyInfo: "Radi of gyration",
    radiOfGyrationIzInfo: "Radi of gyration",
    momentsOfInteriaJyInfo: "MomentsOfInteria Iy/Iz",
    momentsOfInteriaJzInfo: "MomentsOfInteria Iy/Iz",
    sectionModuliWyoInfo: "Section moduli, Wy/Wz",
    sectionModuliWyuInfo: "Section moduli, Wy/Wz",
    sectionModuliWzInfo: "Section moduli, Wy/Wz",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Module of elasticity",
    yieldStrengthFykInfo: "Yield strength fyk",

    projects: "Projects",
    nodes: "Nodes",
    loads: "Loads",
    supports: "Supports",
    elements: "Elements",
    nodeChild: "node",
    loadChild: "load",
    supportChild: "support",
    elementChild: "element",
    modelChild: "model",

    geometry: "Geometry",
    addSection: "Add section",
    deleteSection: "Delete section",
    load: "Load",
    singleLoad: "Single load",
    lineLoad: "Line Load",
    singleMoment: "Single moment",
    deleteLoad: "Delete load",
    support: "Support",
    nodeL: "Node",
    nodeR: "Node",
    fixNodeL: "Fix node",
    fixNodeR: "Fix node",
    noneNodeL: "None node",
    noneNodeR: "None node",
    schemas: "Schemas",
    schemaLoadCase1: "Load case 1",
    schemaLoadCase2: "Load case 2",
    schemaLoadCase3: "Load case 3",
    schemaLoadCase4: "Load case 4",
    schemaLoadCase5: "Load case 5",
    schemaLoadCase6: "Load case 6",
    schemaLoadCase7: "Load case 7",

    profile: "Profile",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maximal:",
    admissibleSummary: "Admissible:",

    bendingMomentChartHeader: "Bending moment [kNm]",
    deflectionChartHeader: "Disp. [mm]",

    PDFsystemProfileSelectionHeader: "System profile selection",
    PDFagent: "Agent:",
    PDFobject: "Object:",
    PDFadvice: "Advice:",

    PDFnodes: "Node:",
    PDFprofileRailtotalLength: "Profile rail:total length",
    PDFprojectInformation: "Project information:",

    PDFload: "Load",
    PDFvalue: "Value",
    PDFpositionA: "Position A [L1]",
    PDFpositionB: "Position B [L2]",
    PDFnetWeight: "net weight",

    PDFprofil: "Cross section profile",

    PDFloadContinous: "continous load",
    PDFloadConcentrated: "concentrated load",
    PDFloadMoment: "Bending moment",

    PDFtensionAndBendingProfileRailHeader: "Tension and bending profile rail:",
    PDFcondition: "Condition",
    PDFmaximum: "Maximum",
    PDFallowed: "Allowed",
    PDFutilisation: "Utilisation [%]",
    PDFstatus: "Status",

    PDFstress: "Stress",
    PDFdeflections: "Deflections [mm]",

    PDFconditionNotFulfilled: "condition not fulfilled",
    PDFconditionFulfilled: " condition fulfilled ",

    PDFbendingMomentChartAndBendingChartHeader:
      "Bending moment chart and bending chart",

    PDFloadPlan: "Load plan:",

    PDFbendingMomentChart: "Bending moment chart [kNm]",
    PDFbendingChart: "Bending chart [mm]",

    PDFnodeReactiveForce: "Node reactive force [kN] ",
    PDFreaction: " ",

    PDFadvice2: "Advice:",
    PDFsafetyFactorInfo: "Calculation safety factor ",
    PDFsafetyFactorInfoEnd: " ",

    PDFsummary:
      "All data in this programm refer to products of MEFA. The results of the report are based on data which were established by the user. The user has the responsibility for the correctness and authenticity of the data, for the verification of the results relating to the compliance regulations and standards. The validity of this document refers exclusive on MEFA-Elements. The program is only a help for statics calculations with MEFA-Elements. The program updates offered by MEFA should be used constant.",

    companyName: " ",
    streetName: " ",
    cityName: " ",
    websiteName: " ",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "created with MEFA StatiCAL online",
  },

  de: {
    copyPaste: "Kopieren-Einf\u00fcgen",
    removeLoad: "Belastung l\u00f6schen",
    supportWarning:
      "Eine \u00c4nderung der angegebenen Lagerung w\u00fcrde zu einer Instabilit\u00e4t des Systems f\u00fchren",
    save: "Speichern",
    fsv: "fsv- feuerst\u00fcckverzinkt (S235JR)",
    fbv: "fbv- feuerbandverzinkt (S250GD-Z275-N-A)",
    programInformation: "Programminformationen",
    writerH: "Autor:",
    objectH: "Objekt:",
    adviceH: "Hinweise:",
    generatePDFButton: "Bericht generieren",
    profilHeaderSummaryPanel: "Profil",
    DispRotHeader: "Durchbiegung/Profillage",
    SectionHeader: "Balkenfeld",
    LoadHeader: "Belastung",
    changeName: "Neue Bezeichnung",
    profilesHeader: "Profil",
    materialsHeader: "Werkstoffe",
    materialName: "Werkstoff",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Querschnittsfl\u00e4che",
    centroidDistanceEoInfo: "Schwerpunktabstand",
    centroidDistanceEuInfo: "Schwerpunktabstand",
    centroidDistanceEzInfo: "Schwerpunktabstand",
    radiOfGyrationIyInfo: "Tr\u00e4gheitsradius",
    radiOfGyrationIzInfo: "Tr\u00e4gheitsradius",
    momentsOfInteriaJyInfo: "Fl\u00e4schentr\u00e4gheitsmoment",
    momentsOfInteriaJzInfo: "Fl\u00e4schentr\u00e4gheitsmoment",
    sectionModuliWyoInfo: "Wiederstandsmoment",
    sectionModuliWyuInfo: "Wiederstandsmoment",
    sectionModuliWzInfo: "Wiederstandsmoment",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Elastizit\u00e4tsmodul",
    yieldStrengthFykInfo: "Streckgrenze",

    projects: "Projekte",
    nodes: "Knoten",
    loads: "Belastung",
    supports: "Lagerung",
    elements: "Elemente",
    nodeChild: "Knoten",
    loadChild: "Belastung",
    supportChild: "Lagerung",
    elementChild: "Element",
    modelChild: "Modell",

    geometry: "Geometrie",
    addSection: "Balkenfeld hinzuf\u00fcgen",
    deleteSection: "Balkenfeld l\u00f6schen",
    load: "Belastung",
    singleLoad: "Einzelkraft",
    lineLoad: "Streckenlast",
    singleMoment: "Einzelmoment",
    deleteLoad: "Belastung l\u00f6schen",
    support: "Lager",
    nodeL: "Lager",
    nodeR: "Lager",
    fixNodeL: "Starre Lagerung",
    fixNodeR: "Starre Lagerung",
    noneNodeL: "Keine Lagerung",
    noneNodeR: "Keine Lagerung",
    schemas: "Schemen",
    schemaLoadCase1: "Lastfall 1",
    schemaLoadCase2: "Lastfall 2",
    schemaLoadCase3: "Lastfall 3",
    schemaLoadCase4: "Lastfall 4",
    schemaLoadCase5: "Lastfall 5",
    schemaLoadCase6: "Lastfall 6",
    schemaLoadCase7: "Lastfall 7",

    profile: "Profil",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maximal:",
    admissibleSummary: "Zul\u00e4ssig:",

    bendingMomentChartHeader: "Biegemoment [kNm]",
    deflectionChartHeader: "Durchbiegung [mm]",

    PDFsystemProfileSelectionHeader: "Systemprofil Auswahl",
    PDFagent: "Bearbeiter:",
    PDFobject: "Objekt:",
    PDFadvice: "Hinweise:",

    PDFnodes: "Knoten:",
    PDFprofileRailtotalLength: "Profilschiene:Gesamtl\u00e4nge:",
    PDFprojectInformation: "Projekt Daten:",

    PDFload: "Belastung",
    PDFvalue: "Wert",
    PDFpositionA: "Position A [L1]",
    PDFpositionB: "Position B [L2]",
    PDFnetWeight: "Eigengewicht",

    PDFprofil: "Profil",

    PDFloadContinous: "Streckenlast",
    PDFloadConcentrated: "Einzelkraft",
    PDFloadMoment: "Biegemoment",

    PDFtensionAndBendingProfileRailHeader:
      "Spannung und Durchbiegung Profilschiene",
    PDFcondition: "Bedingung",
    PDFmaximum: "Maximal",
    PDFallowed: "Zul\u00e4ssig",
    PDFutilisation: "Ausnutzung [%]",
    PDFstatus: "Status",

    PDFstress: "Spannungen",
    PDFdeflections: "Durchbiegung [mm]",

    PDFconditionNotFulfilled: "Bedingungen nicht erf\u00fcllt",
    PDFconditionFulfilled: "Bedingungen erf\u00fcllt",

    PDFbendingMomentChartAndBendingChartHeader:
      "Biegemoment Diagramm und Durchbiegung Diagramm",

    PDFloadPlan: "Belastungsschema",

    PDFbendingMomentChart: "Biegemoment Diagramm [kNm]",
    PDFbendingChart: "Durchbiegung Diagramm [mm]",

    PDFnodeReactiveForce: "Knotennummer",
    PDFreaction: "Lagerkr\u00e4fte [kN]",

    PDFadvice2: "Hinweise:",
    PDFsafetyFactorInfo: "Den Berechnungen liegt ein Sicherheitsbeiwert von ",
    PDFsafetyFactorInfoEnd: " zugrunde.",

    PDFsummary:
      "Alle im Programm enthaltenen Daten beziehen sich auf Produkte der Firma MEFA. Die Ergebnisse für den Bericht werden auf der Grundlage von Daten generiert, die vom Benutzer eigenverantwortlich eingetragen wurden. Der Benutzer tragt die volle Verantwortung für die Korrektheit und Glaubwürdigkeit der eingetragenen Daten/Angaben, für die Verifikation der Berichtergebnisse bezüglich der geltenden Normen und Vorschriften. Die Gültigkeit dieses Dokuments bezieht sich ausschließlich auf die im Dokument genannten MEFA-Elemente. Das Programm dient nur als Hilfe bei statischen Berechnungen von MEFA –Elementen.",
    PDFgarantieHeader: "EINGESCHRÄNKTE GARANTIE",

    PDFkeineGarantieHeader: "KEINE GARANTIEN",
    PDFkeineGarantieText:
      'Der Autor dieser Software lehnt ausdrücklich jegliche Gewährleistung für die Software ab. MEFA StatiCAL und alle zugehörigen Unterlagen werden "wie besehen" ohne ausdrückliche oder stillschweigende Gewährleistung jeglicher Art zur Verfügung gestellt, einschließlich, ohne Einschränkung, der stillschweigenden Gewährleistung oder der Handelsfähigkeit, der Eignung für einen bestimmten Zweck oder der Nichtverletzung. Das gesamte Risiko, das sich aus der Nutzung oder Leistung von MEFA StatiCAL ergibt, verbleibt beim Nutzer.',

    PDFkeineHauftungHeader: "KEINE HAFTUNG FÜR SCHÄDEN",
    PDFkeineHauftungText:
      "In keinem Fall haftet der Autor dieser Software für besondere, Folgeschäden, zufällige oder indirekte Schäden (einschließlich, ohne Einschränkung, Schäden für den Verlust von Geschäftsgewinnen, Betriebsunterbrechungen, Verlust von Geschäftsinformationen oder sonstige finanzielle Verluste) aus der Verwendung oder Unfähigkeit, dieses Produkt zu verwenden, auch wenn der Autor dieser Software die Möglichkeit solcher Schäden und bekannter Mängel kennt.",

    companyName: "MEFA Befestigungs- und Montagesysteme GmbH",
    streetName: "Schillerstraße 15",
    cityName: "D-74635 Kupferzell",
    websiteName: "www.mefa.de",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "erstellt mit MEFA StatiCAL online",
  },
  da: {
    copyPaste: "Kopiere-Inds\u00e6tte",
    removeLoad: "Slet last",
    supportWarning:
      "Hvis du \u00e6dre underst\u00f8tning vil modelen blive ustabil",
    save: "Gem",
    fsv: "FSV- Varmtgalvaniseret",
    fbv: "FBV- galvaniseret plade (S250GD-Z275-N-A)",
    programInformation: "Program information",
    writerH: "Udf\u00f8rt af:",
    objectH: "Objekt:",
    adviceH: "Henvisning:",
    generatePDFButton: "Gennere rapport",
    profilHeaderSummaryPanel: "Skinneprofil",
    DispRotHeader: "Orientiering",
    SectionHeader: "Profill\u00e6ndge",
    LoadHeader: "Last",
    changeName: "Ny navn",
    profilesHeader: "Profil",
    materialsHeader: "Materiale",
    materialName: "Materiale",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Tv\u00e6rsnitsareal (Profilens mindste tv\u00e6rsnit)",
    centroidDistanceEoInfo: "Center distance",
    centroidDistanceEuInfo: "Center distance",
    centroidDistanceEzInfo: "Center distance",
    radiOfGyrationIyInfo: "Inertiradius",
    radiOfGyrationIzInfo: "Inertiradius",
    momentsOfInteriaJyInfo: "Inertimoment",
    momentsOfInteriaJzInfo: "Inertimoment",
    sectionModuliWyoInfo: "Modstandsmoment",
    sectionModuliWyuInfo: "Modstandsmoment",
    sectionModuliWzInfo: "Modstandsmoment",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "E-modul",
    yieldStrengthFykInfo: "Flydesp\u00e6nding",

    projects: "Projekt",
    nodes: "Knudepunkter",
    loads: "Last",
    supports: "Underst\u00f8tning",
    elements: "Elementer",
    nodeChild: "Knudepunkter",
    loadChild: "Last",
    supportChild: "Underst\u00f8tning",
    elementChild: "Element",
    modelChild: "Model",

    geometry: "Geometri",
    addSection: "Tilf\u00f8j  profil sektion",
    deleteSection: "Slet sektion",
    load: "Last",
    singleLoad: "Enkelt last",
    lineLoad: "Linie last",
    singleMoment: "Enkelt moment",
    deleteLoad: "Slet last",
    support: "Underst\u00f8tning",
    nodeL: "Underst\u00f8tning",
    nodeR: "Underst\u00f8tning",
    fixNodeL: "Indesp\u00e6ndt",
    fixNodeR: "Indesp\u00e6ndt",
    noneNodeL: "Ingen Underst\u00f8tning",
    noneNodeR: "Ingen Underst\u00f8tning",
    schemas: "Ordninger",
    schemaLoadCase1: "Last 1",
    schemaLoadCase2: "Last 2",
    schemaLoadCase3: "Last 3",
    schemaLoadCase4: "Last 4",
    schemaLoadCase5: "Last 5",
    schemaLoadCase6: "Last 6",
    schemaLoadCase7: "Last 7",

    profile: "Profil",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maxmial:",
    admissibleSummary: "Tilladt:",

    bendingMomentChartHeader: "B\u00f8jningsmoment [kNm]",
    deflectionChartHeader: "Deformation [mm]",

    PDFsystemProfileSelectionHeader: "Skinne udvalg",
    PDFagent: "Udf\u00f8rt af:",
    PDFobject: "Objekt:",
    PDFadvice: "Henvisning:",

    PDFnodes: "Node:",
    PDFprofileRailtotalLength: "Skinneprofil:Samlet l\u00e6ngde",
    PDFprojectInformation: "Projekt infomation:",

    PDFload: "Last",
    PDFvalue: "V\u00e6rdi",
    PDFpositionA: "Position A(L1)",
    PDFpositionB: "Position B(L2)",
    PDFnetWeight: "Nettov\u00e6gt",

    PDFprofil: "Profil tv\u00e6rsnit",

    PDFloadContinous: "Linie last",
    PDFloadConcentrated: "Enkelt kraft",
    PDFloadMoment: "B\u00f8jnings moment",

    PDFtensionAndBendingProfileRailHeader:
      "Sp\u00e6nding og b\u00f8jning af skinne",
    PDFcondition: "Betingelser",
    PDFmaximum: "Maksimum",
    PDFallowed: "Tilladt",
    PDFutilisation: "Udnyttelse(%)",
    PDFstatus: "Status",

    PDFstress: "Sp\u00e6nding",
    PDFdeflections: "B\u00f8jning",

    PDFconditionNotFulfilled: "Betingelser ikke opfyldt",
    PDFconditionFulfilled: "Betingelser opfyldt",

    PDFbendingMomentChartAndBendingChartHeader:
      "B\u00f8jningsmoment og b\u00f8jningsmoment diagram:",

    PDFloadPlan: "Belatningsskema:",

    PDFbendingMomentChart: "B\u00f8jningsmoment diagram (kNm)",
    PDFbendingChart: "Deformations diagram (mm)",

    PDFnodeReactiveForce: "Node",
    PDFreaction: "Reaktion [kN]",

    PDFadvice2: "Noter:",
    PDFsafetyFactorInfo: "Anvendt sikkerhedsfaktor ",
    PDFsafetyFactorInfoEnd: " ",

    PDFsummary:
      "Alle data i dette programm refererer til produkter fra MEFA. Resultaterne i rapporten er baseret p\u00e5 data som er tilf\u00f8jet af brugeren. Brugeren har ansvaret for rigtigheden og \u00e6gtheden af \u200b\u200bde data til verifikation af resultaterne som relatere sig til den til enhver tid g\u00e6ldende standard. Gyldigheden af \u200b\u200bdette dokument henvises eksklusive p\u00e5 MEFA-produkter. Programmet er kun en hj\u00e6lp for statiske beregninger med MEFA-skinneprofiler. Programmets opdateringer tilbydes af MEFA b\u00f8r l\u00f8bende kontroleres.",

    companyName: "MEFA Nordic A/S",
    streetName: "Nyholmsvej 11",
    cityName: "DK-8930 Randers NO",
    websiteName: "www.mefanordic.dk",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "oprettet med MEFA StatiCAL online",
  },
  hu: {
    copyPaste: "M\u00e1sol\u00e1s-Beilleszt\u00e9s",
    removeLoad: "Terhel\u00e9s t\u00f6rl\u00e9se",
    supportWarning:
      "Verandering van het steunpunt leidt tot instabiliteit van het systeem.",
    save: "Ment\u00e9s",
    fsv: "Egyedileg tüzihorganyzott (S235JR)",
    fbv: "Tüzihorganyzott szalagac\u00e9l (S250GD-Z275-N-A)",
    programInformation: "Programinform\u00e1ci\u00f3k",
    writerH: "Szerző:",
    objectH: "T\u00e1rgy:",
    adviceH: "Megjegyz\u00e9s:",
    generatePDFButton: "Jelent\u00e9s k\u00e9sz\u00edt\u00e9se",
    profilHeaderSummaryPanel: "Profil",
    DispRotHeader: "T\u00e1jol\u00e1s",
    SectionHeader: "Tart\u00f3t\u00e9r",
    LoadHeader: "Terhel\u00e9s",
    changeName: "Modell megnevezése",
    profilesHeader: "Profil",
    materialsHeader: "Anyagmin\u0151s\u00e9g",
    materialName: "Anyagmin\u0151s\u00e9g",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Keresztmetszeti fel\u00fclet",
    centroidDistanceEoInfo: "S\u00falyponti t\u00e1vols\u00e1g",
    centroidDistanceEuInfo: "S\u00falyponti t\u00e1vols\u00e1g",
    centroidDistanceEzInfo: "S\u00falyponti t\u00e1vols\u00e1g",
    radiOfGyrationIyInfo: "Inerciasug\u00e1r",
    radiOfGyrationIzInfo: "Inerciasug\u00e1r",
    momentsOfInteriaJyInfo: "M\u00e1sodrend\u0171 nyomat\u00e9k",
    momentsOfInteriaJzInfo: "M\u00e1sodrend\u0171 nyomat\u00e9k",
    sectionModuliWyoInfo: "Keresztmetszeti t\u00e9nyez\u0151",
    sectionModuliWyuInfo: "Keresztmetszeti t\u00e9nyez\u0151",
    sectionModuliWzInfo: "Keresztmetszeti t\u00e9nyez\u0151",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Rugalmass\u00e1gi t\u00e9nyez\u0151",
    yieldStrengthFykInfo: "Foly\u00e1shat\u00e1r",

    projects: "Projekt",
    nodes: "Csomópontok",
    loads: "Terhel\u00e9s",
    supports: "Al\u00e1t\u00e1maszt\u00e1s",
    elements: "Elem",
    nodeChild: "Csomópontok",
    loadChild: "Terhel\u00e9s",
    supportChild: "Al\u00e1t\u00e1maszt\u00e1s",
    elementChild: "Elem",
    modelChild: "Modell",

    geometry: "M\u00e9rtan",
    addSection: "Tart\u00f3t\u00e9r hozz\u00e1ad\u00e1s",
    deleteSection: "Tart\u00f3t\u00e9r t\u00f6rl\u00e9se",
    load: "Terhel\u00e9s",
    singleLoad: "Pontszer\u0171 terhel\u00e9s",
    lineLoad: "Megoszl\u00f3 terhel\u00e9s",
    singleMoment: "Pontszer\u0171 nyomat\u00e9k",
    deleteLoad: "Terhel\u00e9s t\u00f6rl\u00e9se",
    support: "Al\u00e1t\u00e1maszt\u00e1s",
    nodeL: "Al\u00e1t\u00e1maszt\u00e1s",
    nodeR: "Al\u00e1t\u00e1maszt\u00e1s",
    fixNodeL: "Merev al\u00e1t\u00e1maszt\u00e1s",
    fixNodeR: "Merev al\u00e1t\u00e1maszt\u00e1s",
    noneNodeL: "Al\u00e1t\u00e1maszt\u00e1s n\u00e9lk\u00fcl",
    noneNodeR: "Al\u00e1t\u00e1maszt\u00e1s n\u00e9lk\u00fcl",
    schemas: "Rendszerek",
    schemaLoadCase1: "Terhel\u00e9si eset 1",
    schemaLoadCase2: "Terhel\u00e9si eset 2",
    schemaLoadCase3: "Terhel\u00e9si eset 3",
    schemaLoadCase4: "Terhel\u00e9si eset 4",
    schemaLoadCase5: "Terhel\u00e9si eset 5",
    schemaLoadCase6: "Terhel\u00e9si eset 6",
    schemaLoadCase7: "Terhel\u00e9si eset 7",

    profile: "Profils\u00edn",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maxim\u00e1lis:",
    admissibleSummary: "Elfogadhat\u00f3:",

    bendingMomentChartHeader: "Hajl\u00edt\u00f3nyomat\u00e9k [kNm]",
    deflectionChartHeader: "Lehajl\u00e1s [mm]",

    PDFsystemProfileSelectionHeader: "Profils\u00edn kiv\u00e1laszt\u00e1s",
    PDFagent: "Szerkesztő", ///
    PDFobject: "T\u00e1rgy:",
    PDFadvice: "Megjegyz\u00e9s:",

    PDFnodes: "Csom\u00f3pont:",
    PDFprofileRailtotalLength: "Profils\u00edn teljes hossz",
    PDFprojectInformation: "Projekt adatok:",

    PDFload: "Terhel\u00e9s",
    PDFvalue: "\u00c9rt\u00e9k",
    PDFpositionA: "Poz\u00edci\u00f3 A [L1]",
    PDFpositionB: "Poz\u00edci\u00f3 A [L2]",
    PDFnetWeight: "S\u00faly",

    PDFprofil: "Profil",

    PDFloadContinous: "Megoszló tehelés",
    PDFloadConcentrated: "Pontszerű terhelés ", /////
    PDFloadMoment: "Hajlítónyomaték",

    PDFtensionAndBendingProfileRailHeader:
      "Profils\u00edn fesz\u00fclts\u00e9ge \u00e9s lehajl\u00e1sa:",
    PDFcondition: "\u00c1llapot",
    PDFmaximum: "Maximum",
    PDFallowed: "Megengedhető", //////
    PDFutilisation: "Kihaszn\u00e1lts\u00e1g [%]",
    PDFstatus: "St\u00e1tusz",

    PDFstress: "Fesz\u00fclts\u00e9g",
    PDFdeflections: "Lahajl\u00e1s [mm]",

    PDFconditionNotFulfilled: "Felt\u00e9teleknek nem Megfelelő", /////
    PDFconditionFulfilled: "Felt\u00e9teleknek Megfelelő", ////

    PDFbendingMomentChartAndBendingChartHeader:
      "Nyomat\u00e9ki \u00e9s lehajl\u00e1si \u00e1bra:",

    PDFloadPlan: "Terhel\u00e9si s\u00e9ma:",

    PDFbendingMomentChart: "Nyomat\u00e9ki diagramm [kNm]",
    PDFbendingChart: "Lehajl\u00e1si diagramm [mm]",

    PDFnodeReactiveForce: "Sorszám",
    PDFreaction: "Reakci\u00f3 [kN]",

    PDFadvice2: "Megjegyz\u00e9s:",
    PDFsafetyFactorInfo:
      "Sz\u00e1m\u00edt\u00e1sn\u00e1l alkalmazott biztons\u00e1gi Tényező ",
    PDFsafetyFactorInfoEnd: " ",

    PDFsummary:
      "A sz\u00e1m\u00edt\u00e1sok a MEFA \u00e1ltal forgalmazott term\u00e9kek \u00e9rt\u00e9kei alapj\u00e1n t\u00f6rt\u00e9ntek. Az eredm\u00e9nyek a megadott inform\u00e1ci\u00f3k alapj\u00e1n ker\u00fcltek kisz\u00e1m\u00edt\u00e1sra. Az alapadatok pontoss\u00e1g\u00e1\u00e9rt, illetve megb\u00edzhat\u00f3s\u00e1g\u00e1\u00e9rt a felelősség a felhaszn\u00e1l\u00f3t terheli, a sz\u00e1m\u00edt\u00e1sok eredm\u00e9nyei tekintet\u00e9ben az \u00e9rv\u00e9nyes szabv\u00e1nyokat, illetve előírásokat vett\u00fck figyelembe. A sz\u00e1m\u00edt\u00e1sok \u00e9rv\u00e9nyes\u00e9ge csak a szakszerű, előírás szerinti be\u00e9p\u00edt\u00e9s eset\u00e9n \u00e9rv\u00e9nyes. A sz\u00e1m\u00edt\u00e1sok csak statikus ig\u00e9nybev\u00e9tel eset\u00e9n \u00e9rv\u00e9nyesek! A MEFA \u00e1ltal kiadott programfriss\u00edt\u00e9sek minden esetben sz\u00fcks\u00e9gesek!",

    companyName: "MEFA Promt Hungaria Kft.",
    streetName: "Szentlászlói u. 160",
    cityName: "H-2000 Szentendre",
    websiteName: "www.mefahungaria.hu",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "készítette a MEFA StatiCAL online",
  },
  it: {
    copyPaste: "Copia-Incolla",
    removeLoad: "Elimina Carico",
    supportWarning:
      "La modifica del nodo comporter? l'instabilit? del Sistema.",
    save: "Salva",
    fsv: "fsv - Pezzo Zincato a Caldo (S235JR)",
    fbv: "fbv - Lamina Zincata a Caldo (S250GD-Z275-N-A)",
    programInformation: "Informazioni sul Programma",
    writerH: "Cliente:",
    objectH: "Progetto:",
    adviceH: "Nome Supporto:",
    generatePDFButton: "Genera Report",
    profilHeaderSummaryPanel: "Binario di montaggio",
    DispRotHeader: "Deviazione/Orientamento",
    SectionHeader: "Libreria Sezioni",
    LoadHeader: "Carico",
    changeName: "Nuovo Nome",
    profilesHeader: "Binario di Montaggio",
    materialsHeader: "Materiali",
    materialName: "Materiale",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Aree a Taglio",
    centroidDistanceEoInfo: "Distanza dal Baricentro",
    centroidDistanceEuInfo: "Distanza dal Baricentro",
    centroidDistanceEzInfo: "Distanza dal Baricentro",
    radiOfGyrationIyInfo: "Raggio d'Inerzia",
    radiOfGyrationIzInfo: "Raggio d'Inerzia",
    momentsOfInteriaJyInfo: "Momenti d'Inerzia",
    momentsOfInteriaJzInfo: "Momenti d'Inerzia",
    sectionModuliWyoInfo: "Moduli di Resistenza a Flessione",
    sectionModuliWyuInfo: "Moduli di Resistenza a Flessione",
    sectionModuliWzInfo: "Moduli di Resistenza a Flessione",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Modulo di Elasticità",
    yieldStrengthFykInfo: "Tensione di snervamento",

    projects: "Progetto",
    nodes: "Nodi",
    loads: "Carico",
    supports: "Nodo",
    elements: "Elemento",
    nodeChild: "Nodi",
    loadChild: "Carico",
    supportChild: "Nodo",
    elementChild: "element",
    modelChild: "Modello",

    geometry: "Geometria",
    addSection: "AGGIUNGI ASTA",
    deleteSection: "ELIMINA ASTA",
    load: "Carico",
    singleLoad: "Singolo Carico",
    lineLoad: "Carico distribuito",
    singleMoment: "Singolo Momento",
    deleteLoad: "Elimina Carico",
    support: "Nodo",
    nodeL: "Nodo",
    nodeR: "Nodo",
    fixNodeL: "Nodo fisso",
    fixNodeR: "Nodo fisso",
    noneNodeL: "Nessun nodo",
    noneNodeR: "Nessun nodo",
    schemas: "Schemi",
    schemaLoadCase1: "Situazione di Carico 1",
    schemaLoadCase2: "Situazione di Carico 2",
    schemaLoadCase3: "Situazione di Carico 3",
    schemaLoadCase4: "Situazione di Carico 4",
    schemaLoadCase5: "Situazione di Carico 5",
    schemaLoadCase6: "Situazione di Carico 6",
    schemaLoadCase7: "Situazione di Carico 7",

    profile: "Binario di montaggio",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maximal:",
    admissibleSummary: "Ammissibile:",

    bendingMomentChartHeader: "Momento flettente [kNm]",
    deflectionChartHeader: "Inflessione [mm]",

    PDFsystemProfileSelectionHeader: "Selezione del Sistema di Binari",
    PDFagent: "Cliente:",
    PDFobject: "Progetto:",
    PDFadvice: "Nome Supporto:",

    PDFnodes: "Nodo:",
    PDFprofileRailtotalLength: "Binario di montaggio:lunghezza totale :",
    PDFprojectInformation: "Informazioni sul Progetto:",

    PDFload: "Carico",
    PDFvalue: "Valore",
    PDFpositionA: "Posizione A [L1]",
    PDFpositionB: "Posizione A [L2]",
    PDFnetWeight: "Peso Netto",

    PDFprofil: "Sezione Trasversale del Binario",

    PDFloadContinous: "Carico Distribuito",
    PDFloadConcentrated: "Carico Concentrato",
    PDFloadMoment: "Momento Flettente",

    PDFtensionAndBendingProfileRailHeader:
      "Binario di Montaggio: Tensione e Deformazione:",
    PDFcondition: "Condizione",
    PDFmaximum: "Massimo",
    PDFallowed: "Ammesso",
    PDFutilisation: "Utilizzo [%]",
    PDFstatus: "Stato",

    PDFstress: "Sforzo",
    PDFdeflections: "Inflessione",

    PDFconditionNotFulfilled: "Condizione non verificata ",
    PDFconditionFulfilled: "Condizione verificata",

    PDFbendingMomentChartAndBendingChartHeader:
      "Diagramma del Momento Flettente e Diagramma della Deformata:",

    PDFloadPlan: "Schema di carico:",

    PDFbendingMomentChart: "Diagramma del Momento Flettente [kNm]",
    PDFbendingChart: "Diagramma Deformata [mm]",

    PDFnodeReactiveForce: "Nodo",
    PDFreaction: "Reazione [kN]",

    PDFadvice2: "Nota",

    PDFsafetyFactorInfo:
      "Il presente Documento costituisce unicamente un\u2019indicazione sul Sistema di staffaggio MEFA per gli Impianti Tecnologici; tale Documento dovr\u00e0 essere obbligatoriamente controllato, verificato, timbrato e firmato da un Progettista abilitato, incaricato dal Cliente, prima dell\u2019installazione del Supporto stesso.",

    PDFsummary:
      "Si precisa che:",

    PDFsummaryp1:"Tutti i Carichi considerati agenti devono essere sempre verificati. Nel caso di variazioni dei Carichi agenti e/o in presenza di Carichi aggiuntivi a quelli qui considerati, il presente Documento non risulta pi\u00f9 valido.",
    PDFsummaryp2: "Nel caso di variazioni della geometria del Supporto, il presente Documento non risulta pi\u00f9 valido.",
    PDFsummaryp3: "Non sono state eseguite valutazioni e verifiche di resistenza complete degli Elementi e degli Accessori componenti il Supporto proposto; tali verifiche rimangono comunque in carico al Progettista del Supporto.",
    PDFsummaryp4: "Non sono state eseguite valutazioni e verifiche in merito al collegamento “Supporto” – “Struttura Edile”; tali verifiche rimangono comunque in carico al Cliente.",
    PDFsummaryp5: "Non sono state eseguite valutazioni e verifiche in merito al collegamento “Elemento Impiantistico” – “Supporto”; tali verifiche rimangono comunque in carico al Cliente.",
    PDFsummaryp6: "Non sono state eseguite valutazioni e verifiche in merito alla resistenza e alla deformabilit\u00e0 del generico Elemento Impiantistico sostenuto in funzione dell\u2019interasse di staffaggio e delle azioni di conseguenza agenti; tali verifiche rimangono comunque in carico al Cliente.",
    PDFsummaryp7: "Non sono state eseguite valutazioni e verifiche in merito alla resistenza e alla deformabilit\u00e0 delle Strutture Edili a cui il Supporto è connesso; tali verifiche rimangono comunque in carico al Progettista delle Strutture Edili stesse.",

    PDFsafetyFactorInfoEnd:"MEFA Italia declina ogni responsabilità derivante dall’eventuale inesattezza dei dati assunti e da eventuali errori e/o difetti del Sistema nonché dall’impiego di Materiale differente da quello indicato.",

    companyName: "MEFA Italia SpA",
    streetName: "Via G. B. Morgagni 16/B",
    cityName: "I-20005 Pogliano Milanese (MI)",
    websiteName: "www.mefa.it",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "creato con MEFA StatiCAL online",
  },
  nl: {
    copyPaste: "Knippen-Plakken",
    removeLoad: "Belasting verwijderen",
    supportWarning:
      "Verandering van het steunpunt leidt tot instabiliteit van het systeem.",
    save: "Opslaan",
    fsv: "fsv- thermisch verzinkt (S235JR)",
    fbv: "fbv-verzinkt (S250GD-Z275-N-A)",
    programInformation: "Programma-informatie",
    writerH: "Auteur:",
    objectH: "Object:",
    adviceH: "Advies:",
    generatePDFButton: "Rapport Genereren",
    profilHeaderSummaryPanel: "Montagerail",
    DispRotHeader: "Ori\u00ebntering",
    SectionHeader: "Veld / sectie",
    LoadHeader: "Belasting",
    changeName: "Nieuwe naam",
    profilesHeader: "Montagerail",
    materialsHeader: "Materiaal",
    materialName: "Materiaal",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Dwarsdoorsnede",
    centroidDistanceEoInfo: "Zwaartepunt afstand",
    centroidDistanceEuInfo: "Zwaartepunt afstand",
    centroidDistanceEzInfo: "Zwaartepunt afstand",
    radiOfGyrationIyInfo: "Traagheidsstraal",
    radiOfGyrationIzInfo: "Traagheidsstraal",
    momentsOfInteriaJyInfo: "Traagheids moment",
    momentsOfInteriaJzInfo: "Traagheids moment",
    sectionModuliWyoInfo: "Weerstandsmoment",
    sectionModuliWyuInfo: "Weerstandsmoment",
    sectionModuliWzInfo: "Weerstandsmoment",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Elasticiteitsmodulus",
    yieldStrengthFykInfo: "Rekgrens",

    projects: "Project",
    nodes: "Knooppunten",
    loads: "Belasting",
    supports: "Bevestiging",
    elements: "Element",
    nodeChild: "Knooppunten",
    loadChild: "Belasting",
    supportChild: "Bevestiging",
    elementChild: "Element",
    modelChild: "Model",

    geometry: "Geometrie",
    addSection: "Veld / sectie toevoegen",
    deleteSection: "Veld / sectie verwijderen",
    load: "Belasting",
    singleLoad: "Rechtse bevestiging",
    lineLoad: "Gelijkmatige belasting",
    singleMoment: "Enkel moment",
    deleteLoad: "Belasting verwijderen",
    support: "Bevestiging",
    nodeL: "Steunpunt",
    nodeR: "Steunpunt",
    fixNodeL: "Starre bevestiging",
    fixNodeR: "Starre bevestiging",
    noneNodeL: "Geen bevestiging",
    noneNodeR: "Geen bevestiging",
    schemas: "Schema",
    schemaLoadCase1: "Belasting soort 1",
    schemaLoadCase2: "Belasting soort 2",
    schemaLoadCase3: "Belasting soort 3",
    schemaLoadCase4: "Belasting soort 4",
    schemaLoadCase5: "Belasting soort 5",
    schemaLoadCase6: "Belasting soort 6",
    schemaLoadCase7: "Belasting soort 7",

    profile: "Profil",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maximaal:",
    admissibleSummary: "Toelaatbaar:",

    bendingMomentChartHeader: "Buigmoment [kNm]",
    deflectionChartHeader: "Doorbuiging [mm]",

    PDFsystemProfileSelectionHeader: "Montagerail keuze",
    PDFagent: "Behandeld door:",
    PDFobject: "Object:",
    PDFadvice: "Advies:",

    PDFnodes: "Steunpunt:",
    PDFprofileRailtotalLength: "Montagerail: Totale lengte:",
    PDFprojectInformation: "Ontwerp gegevens",

    PDFload: "Belasting",
    PDFvalue: "Waarde",
    PDFpositionA: "Positie A [L1]",
    PDFpositionB: "Positie A [L2]",
    PDFnetWeight: "Netto gewicht",

    PDFprofil: "Doorsnede montagerails",

    PDFloadContinous: "Gelijkmatige belasting",
    PDFloadConcentrated: "Enkele belasting",
    PDFloadMoment: "Buigmoment",

    PDFtensionAndBendingProfileRailHeader:
      "Spanning en doorbuiging van de montagerail",
    PDFcondition: "Voorwaarde",
    PDFmaximum: "Maximaal",
    PDFallowed: "Toegestaan",
    PDFutilisation: "Gebruik [%]",
    PDFstatus: "Status",

    PDFstress: "Spanning",
    PDFdeflections: "Doorbuiging [mm]",

    PDFconditionNotFulfilled: "Voldoet niet aan voorwaarden",
    PDFconditionFulfilled: "Voldoet aan voorwaarden",

    PDFbendingMomentChartAndBendingChartHeader:
      "Grafiek van de buigmoment en doorbuiging:",

    PDFloadPlan: "Schematische belastingen:",

    PDFbendingMomentChart: "Buigmoment grafiek (kNm)",
    PDFbendingChart: "Doorbuiging grafiek (mm)",

    PDFnodeReactiveForce: "Węzeł",
    PDFreaction: "Reactie [kN]",

    PDFadvice2: "Advies:",
    PDFsafetyFactorInfo: "Veiligheidsfactor berekening ",
    PDFsafetyFactorInfoEnd: " ",

    PDFsummary:
      "Alle gegevens in dit programma hebben betrekking op de producten van MEFA. De resultaten van het rapport zijn gebaseerd op gegevens die zijn ingevoerd door de gebruiker. De gebruiker heeft de verantwoordelijkheid voor de juistheid en de echtheid van de gegevens, voor de verificatie van de resultaten met betrekking tot de naleving van voorschriften en normen. De geldigheid van dit document is exclusief voor MEFA-materiaal. Het programma is slechts een hulp voor statische berekeningen met MEFA-elementen. Programma-updates die worden aangeboden door MEFA moeten direct worden verwerkt.",

    companyName: "Huyg bv",
    streetName: "Damsluisweg 44a",
    cityName: "1332 ED Almere",
    websiteName: "www.huygbv.nl",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "gemaakt met MEFA StatiCAL online",
  },
  lv: {
    copyPaste: "Kopēt-Ielīmēt",
    removeLoad: "Noņemt slodzi",
    supportWarning:
      "Ja nomainīsiet mezglu, tas izraisīs sistēmas nestabilitāti",
    save: "Saglabāt",
    fsv: "fsv - karsti cinkots (S235JR)",
    fbv: "fbv - cinkots (S250GD-Z275-N-A)",
    programInformation: "Informācija par programmu",
    writerH: "Autors:",
    objectH: "Objekts",
    adviceH: "Ieteikums:",
    generatePDFButton: "Izveidot atskaiti",
    profilHeaderSummaryPanel: "Profils",
    DispRotHeader: "Novirze/rotācija",
    SectionHeader: "Sadaļa",
    LoadHeader: "Slodze",
    changeName: "Jauns nosaukums",
    profilesHeader: "Profili",
    materialsHeader: "Materiāli",
    materialName: "Materiāls",
    shearAreasA: "A [cm2]",
    centroidDistanceEo: "eo [cm]",
    centroidDistanceEu: "eu [cm]",
    centroidDistanceEz: "ez [cm]",
    radiOfGyrationIy: "iy [cm]",
    radiOfGyrationIz: "iz [cm]",
    momentsOfInteriaJy: "Jy [cm4]",
    momentsOfInteriaJz: "Jz [cm4]",
    sectionModuliWyo: "Wyo [cm3]",
    sectionModuliWyu: "Wyu [cm3]",
    sectionModuliWz: "Wz [cm3]",

    shearAreasAInfo: "Šķērsgriezuma laukums",
    centroidDistanceEoInfo: "Smaguma centra attālums",
    centroidDistanceEuInfo: "Smaguma centra attālums",
    centroidDistanceEzInfo: "Smaguma centra attālums",
    radiOfGyrationIyInfo: "Inerces rādiuss",
    radiOfGyrationIzInfo: "Inerces rādiuss",
    momentsOfInteriaJyInfo: "Inerces moments",
    momentsOfInteriaJzInfo: "Inerces moments",
    sectionModuliWyoInfo: "Sekcijas modulis,Wy/Wz",
    sectionModuliWyuInfo: "Sekcijas modulis,Wy/Wz",
    sectionModuliWzInfo: "Sekcijas modulis,Wy/Wz",

    moduleOfElastic: "E[GPa]",
    yieldStrengthFyk: "fy[MPa]",

    moduleOfElasticInfo: "Elastības modulis",
    yieldStrengthFykInfo: "Elastības robeža",

    projects: "Projekti",
    nodes: "Atbalsta punkti",
    loads: "Slodzes",
    supports: "Atbalsti",
    elements: "Elementi",
    nodeChild: "atbalsta punkts",
    loadChild: "slodze",
    supportChild: "atbalsts",
    elementChild: "elements",
    modelChild: "modelis",

    geometry: "Ģeometrija",
    addSection: "Pievienot sekciju",
    deleteSection: "Dzēst sekciju",
    load: "Slodze",
    singleLoad: "Koncentrēta slodze",
    lineLoad: "Izkliedēta slodze",
    singleMoment: "Spēka moments",
    deleteLoad: "Dzēst slodzi",
    support: "Atbalsts",
    nodeL: "Atbalsta punkts",
    nodeR: "Atbalsta punkts",
    fixNodeL: "Nekustīgs atbalsta punkts",
    fixNodeR: "Nekustīgs atbalsta punkts",
    noneNodeL: "Bez atbalsta punkta",
    noneNodeR: "Bez atbalsta punkta",
    schemas: "Shēmas",
    schemaLoadCase1: "Slodzesveids 1",
    schemaLoadCase2: "Slodzesveids 2",
    schemaLoadCase3: "Slodzesveids 3",
    schemaLoadCase4: "Slodzesveids 4",
    schemaLoadCase5: "Slodzesveids 5",
    schemaLoadCase6: "Slodzesveids 6",
    schemaLoadCase7: "Slodzesveids 7",

    profile: "Profils",
    momentSummary: "M [kNm]:",
    deflectionSummary: "u [mm]:",
    maximalSummary: "Maksimālais:",
    admissibleSummary: "Pieļaujamais:",

    bendingMomentChartHeader: "Liekšanas moments [kNm]",
    deflectionChartHeader: "Izliekums [mm]",

    PDFsystemProfileSelectionHeader: "Montāžas profila izvēle",
    PDFagent: "Pārstāvis:",
    PDFobject: "Objekts:",
    PDFadvice: "Ieteikums:",

    PDFnodes: "Atbalsta punkts",
    PDFprofileRailtotalLength: "Montāžas profils: kopējais garums",
    PDFprojectInformation: "Informācija par projektu:",

    PDFload: "Slodze",
    PDFvalue: "Vērtība",
    PDFpositionA: "Pozīcija A [L1]",
    PDFpositionB: "Pozīcija B [L2]",
    PDFnetWeight: "Pašmasa",

    PDFprofil: "Šķērsgriezuma profils",

    PDFloadContinous: "Izkliedēta slodze",
    PDFloadConcentrated: "koncentrēta slodze",
    PDFloadMoment: "Lieces moments",

    PDFtensionAndBendingProfileRailHeader:
      "Montāžas profila izturība un deformācija:",
    PDFcondition: "Stāvoklis",
    PDFmaximum: "Maksimālais",
    PDFallowed: "Pieļaujamais:",
    PDFutilisation: "Noslodze [%]",
    PDFstatus: "Stāvoklis",

    PDFstress: "Spriedze",
    PDFdeflections: "Izliece [mm]",

    PDFconditionNotFulfilled: "Nosacījumi nav izpildīti",
    PDFconditionFulfilled: "Nosacījumi izpildīti",

    PDFbendingMomentChartAndBendingChartHeader:
      "Liekšanas momentu diagramma un lieces diagramma:",

    PDFloadPlan: "Slodzes shēma",

    PDFbendingMomentChart: "Liekšanas momentu diagramma [kNm]",
    PDFbendingChart: "Izlieces diagramma [mm]",

    PDFnodeReactiveForce: "Atbalsta punkta pretspēks [kN]",
    PDFreaction: "Pretspēks",

    PDFadvice2: "Ieteikumi:",
    PDFsafetyFactorInfo: "Aprēķinos veikti ar drošības koeficientu",
    PDFsafetyFactorInfoEnd: " ",

    PDFsummary:
      "Visi programmā ietvertie dati attiecas uz uzņēmuma MEFA precēm. Rezultāti tiek ģenerēti aprēķinu pārskatam, pamatojoties uz lietotāja ievadītajiem datiem. Lietotājs ir pilnībā atbildīgs par ievadīto datu pareizību un ticamību, par pārbaudi aprēķina pārskata rezultātu salīdzinājumā ar piemērojamiem standartiem un noteikumiem. Dokuments tiek nodrošināts tikai dokumentā minētajiem MEFA elementiem atbilstošos būvniecības apstākļos. Programmatūra kalpo tikai kā palīglīdzeklis, aprēķinot objekta statisko nestspēju. Bez jebkādām garantijām, ka kļūdu nebūs. Atcerieties regulāri veikt MEFA piedāvātos programmatūras atjauninājumus ",

    companyName: "SIA BM Solutions ",
    streetName: "Gaujas iela 5",
    cityName: "Rīga, Latvija ",
    websiteName: "www.bmsolutions.lv",

    mefaStaticalheader: "MEFA StatiCAL-Report",
    mefaStaticalCreated: "izveidots ar MEFA StatiCAL tiešsaistē",
  },
};

export default languages;
