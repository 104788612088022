import React from "react";
import "./BendingMomentPanel.css";
import * as THREE from "three";
import mefaActions from "../../../app/mefa-app/duck/actions";
import { connect } from "react-redux";
import fontHelvetiker from "../../fonts/helvetiker_typeface.json";

import LineMaterial from "../../../lib/LineMaterial";
import LineGeometry from "../../../lib/LineGeometry";

import Line2 from "../../../lib/Line2";
const loader = new THREE.FontLoader();
const font = loader.parse(fontHelvetiker);


var raycaster = new THREE.Raycaster();
raycaster.linePrecision = 0.003;
var mouse = new THREE.Vector2();

const textSize = 0.1;
const textHeight = 0.0008;
let w = 475;
let h = 90;
let globalScale = 4.5
let frustumSize = 1.1;
let init=0;
let textMaterialNode = new THREE.MeshBasicMaterial({ color: "blue" });
var matLine2 = new LineMaterial( {
  color: 'blue',
  linewidth: 1.5, 
} );

let materialBM = new THREE.LineBasicMaterial({
  color: 'blue',
  linewidth: 1,
  linecap: 'butt',
  linejoin:  'miter' 
});

class BendingMomentPanel extends React.Component {
  state={
    momentScale: globalScale,
    sumOfElement: 1
  };
  
 
abc=()=>{
  this.setState({momentScale: this.state.momentScale +0.1})
}

setMouseMove=(event)=>{
  event.preventDefault();
  mouse.x = (event.offsetX / this.mount.clientWidth) * 2 - 1;
  mouse.y = -(event.offsetY / 90) * 2 + 1;
}

resize=()=>{
    
  if(this.mount && this.mount.clientWidth !== null){
    let width = this.mount.clientWidth-1;
    let height = 90
   
    let sum=0;
    this.props.beamElements.forEach(element => {
      sum=sum+element.lengthL;
    });

    globalScale =width/110;
    this.setState({
      momentScale: globalScale*(1/sum)
    })
  
    let aspect = width / height;
    this.renderer.setSize(width, height);
    this.camera.left = ((frustumSize * aspect) / -2) + globalScale/2;
    this.camera.right = ((frustumSize * aspect) / 2) + globalScale/2;
    this.camera.top = frustumSize / 2;
    this.camera.bottom = frustumSize / -2;
    
    this.camera.updateProjectionMatrix();
  }
}
calculateOffset(index, sectionElementOffset){
  if(index > 0){
    sectionElementOffset = sectionElementOffset + this.props.beamElements[index-1].lengthL;
  }
return sectionElementOffset
}
drawElements=(element,offsetSectionElement)=>{
  let positions = [];
  
  var matLine = new LineMaterial( {
    color: 'black',
    linewidth: 2.5
  } );

  matLine.resolution.set(w,h);

  positions.push(offsetSectionElement*this.state.momentScale, 0, 0 );
  positions.push((offsetSectionElement+element.lengthL)*this.state.momentScale , 0, 0 );

  var geometry = new LineGeometry();

  geometry.setPositions( positions );

  var line = new Line2( geometry, matLine );
  this.scene.add( line );
}

drawPoints=()=>{

  if(this.props.mainCharPointsMoment.length > 0){
  let positions = [];
  let positionsBM = [];

  matLine2.resolution.set(w,h);
  

 let offsetSectionElement=0;

    for(let j = 0; j < this.props.mainCharPointsMoment.length; j++){

         let pointsArray =(this.props.mainCharPointsMoment[j]);
         let points=[];
         let sectionPoints=[];

         pointsArray.forEach(point=>{
           points.push(point[0]);
           sectionPoints.push(point[1]);
         })
         

         let elementLength =this.props.beamElements[j].lengthL;
         offsetSectionElement = this.calculateOffset(j, offsetSectionElement);
         let scaleY= Math.abs(this.props.globalMaxM) > Math.abs(this.props.globalMinM) ? Math.abs(this.props.globalMaxM):Math.abs(this.props.globalMinM);

         for(let i = 0; i < points.length; i++){
           // eslint-disable-next-line
            if((points[i] === this.props.globalMaxM || points[i] === this.props.globalMinM ) && points[i].toFixed(2) != 0)
            {
              let textGeometry = new THREE.TextGeometry(Number(points[i]).toFixed(2),
              {font: font, size: textSize, height: textHeight,});
              let textMesh = new THREE.Mesh(textGeometry, textMaterialNode);
              textMesh.name="tr"

              points[i] > 0 
              ? textMesh.position.set(((((i/(points.length-1)*elementLength)+offsetSectionElement)-0.1/this.state.momentScale))*this.state.momentScale,-points[i]/(scaleY*3) -0.15, 0.01)
              : textMesh.position.set(((((i/(points.length-1)*elementLength)+offsetSectionElement)-0.1/this.state.momentScale))*this.state.momentScale, -points[i]/(scaleY*3) +0.05, 0.01)

              this.scene.add(textMesh);
            }
             

          //   let geometryP = new THREE.SphereGeometry(0.015, 9, 9);
          //   let material = new THREE.MeshBasicMaterial({ color: "blue" });
          //   // material.name="tr"
            
          //   let sphere = new THREE.Mesh(geometryP, material);
          //   sphere.name="tr";
          //   // sphere.geometry.name=parseFloat(przeslo[i]*elementLength);
          //   // console.log(przeslo[i]*elementLength)
          // //  console.log(parseFloat(przeslo[i]*elementLength))
          //   // if(i === 0 ){sphere.position.set(((((i/(przeslo.length-1)*elementLength)+offsetSectionElement)/this.state.momentScale))*this.state.momentScale, 0.1, 0);}
           
             
            //this.scene.add(sphere);

            
            if(this.props.bendingMomentValueMode){

              if(i === 0 ){positionsBM.push(new THREE.Vector3(offsetSectionElement*this.state.momentScale, 0, 0.1))}

              positionsBM.push(new THREE.Vector3(((i/(points.length-1)*elementLength)+offsetSectionElement)*this.state.momentScale, -points[i]/(scaleY*3), 0.1));

              if(i === (points.length-1)){positionsBM.push(new THREE.Vector3((offsetSectionElement+elementLength)*this.state.momentScale, 0, 0.1))}

            }else{

              if(i === 0 ){positions.push(offsetSectionElement*this.state.momentScale, 0, 0.1);}

              positions.push(((i/(points.length-1)*elementLength)+offsetSectionElement)*this.state.momentScale, -points[i]/(scaleY*3), 0.1);

              if(i === (points.length-1) ){positions.push((offsetSectionElement+elementLength)*this.state.momentScale, 0, 0.1);}

            }


            if(this.props.bendingMomentValueMode){
              
              let geometryP = new THREE.SphereGeometry(0.34, 9, 9);
              let material = new THREE.MeshBasicMaterial({ color: "white" });
             
              let sphere = new THREE.Mesh(geometryP, material);
              sphere.name="tr";
              sphere.geometry.name=parseFloat(sectionPoints[i]*elementLength);
           
              sphere.position.set(((((i/(sectionPoints.length-1)*elementLength)+offsetSectionElement)-0.0005/this.state.momentScale))*this.state.momentScale, -0.0, -1);
               
              this.scene.add(sphere);
          
          }
        }
    }

  
    if(this.props.bendingMomentValueMode){

      let geometryBM = new THREE.BufferGeometry().setFromPoints( positionsBM );

      let lineBM = new THREE.Line( geometryBM, materialBM );
      this.scene.add( lineBM );
      lineBM.name="tr";
  

    }else{

       let geometry = new LineGeometry();
       geometry.setPositions( positions );

       let line = new Line2( geometry, matLine2 );
       line.name="tr"

       this.scene.add( line );
    }
    
}
}

 
drawNodes=(sectionElementOffset)=>{
  var geometryP = new THREE.SphereGeometry(0.045, 22, 22);
  var material = new THREE.MeshBasicMaterial({ color: "black" });
  var sphere = new THREE.Mesh(geometryP, material);
  sphere.position.set((sectionElementOffset)*this.state.momentScale, 0, 0);
   
  this.scene.add(sphere);
}
  componentDidMount() {
    let width = this.mount.clientWidth;
    let height = h;

    this.scene = new THREE.Scene();

   
    let aspect = width / height;

    this.camera = new THREE.OrthographicCamera(
      ((frustumSize * aspect) / -2)+2.25,
      ((frustumSize * aspect) / 2)+2.25,
      frustumSize / 2,
      frustumSize / -2,
      0.01,
      100
    );

    this.scene.add(this.camera);
    this.camera.position.copy(new THREE.Vector3(0, 0, 1));

    this.camera.lookAt(new THREE.Vector3(0, 0, -1));
    this.renderer = new THREE.WebGLRenderer({ antialias: true ,preserveDrawingBuffer: true });
    this.renderer.setClearColor("#FFFFFF");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    this.mount.addEventListener('mousemove', this.setMouseMove);

    window.addEventListener("resize",this.resize );


    this.renderScene();
    this.resize();
  }

  renderScene(){
    var animate = () => {
      requestAnimationFrame(animate);

      if(this.props.bendingMomentValueMode){

        raycaster.setFromCamera(mouse, this.camera);
        let intersects = raycaster.intersectObjects(this.scene.children,true);

        for (let i = 0; i < intersects.length; i++) {
          this.props.loadBendingMomentValue(Number(intersects[0].object.geometry.name).toFixed(2));
        }

        mouse.x=10;
        mouse.y=10;
    }
    
      this.renderer.render(this.scene, this.camera);
    };
    animate();
  }

  componentDidUpdate(prevProps,stateprev) {
    if(init === 0){
   
      this.resize();
      init=1;
      }
    if( this.props.currentStateBMomentChart !== prevProps.currentStateBMomentChart ||
     this.state.momentScale !== stateprev.momentScale )
    {
      let sum=0;
      this.props.currentStateModel.elements.forEach(element => {
        sum=sum+element.lengthL;
      });

    if( this.state.sumOfElement !== sum )
    {
      

       this.setState({
         sumOfElement: sum,
          momentScale: (globalScale*(1/(sum)))
       })
      
    }


    if(!this.props.GLOBAL_MODE){
      this.scene.remove.apply(this.scene, this.scene.children);

    }else {
      // eslint-disable-next-line array-callback-return
      let tab=this.scene.children.filter(ele=>{
        if(ele.name === "tr"){
          return ele;
        
        } });
      this.scene.remove.apply(this.scene, tab);

      }

   

    if(!this.props.GLOBAL_MODE){
      
    let sectionElementOffset = 0;
   
    this.props.beamElements.forEach((element, index) => {
      sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
          this.drawElements(element,sectionElementOffset)
      });

      sectionElementOffset = 0;

      this.props.beamNodes.forEach((node, index) => {
        sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
          this.drawNodes(sectionElementOffset)
       });
      }
  
  
       this.drawPoints();
  
       this.renderer.render(this.scene, this.camera);
      }
      
  }

  render() {
    return (
      <>
        <div className="bendingMomentChart" ref={mount => {this.mount = mount;}}
        />
      </>
    );
  }
}
//style={ {overflow: "scroll"}}
const mapDispatchToProps = dispatch => ({
  changeModelElement: value => dispatch(mefaActions.changeModelElement(value)),
  updateLoadL1Element: (index, value) =>dispatch(mefaActions.updateLoadL1Element(index, value))
});

const mapStateToProps = state => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  beamElements:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].elements,
  beamLoads:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].loads,
  beamSupports:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].supports,
  beamNodes: state.mefa.present.models[state.mefa.present.selectedModelIndex].nodes,
  mainCharPointsMoment: state.mefa.present.charPointsMomentTemp,
  globalMaxM: state.mefa.present.globalMaxM,
  globalMinM: state.mefa.present.globalMinM,
  currentStateModel: state.mefa.present.models[state.mefa.present.selectedModelIndex],
  currentStateBMomentChart: state.mefa.present.charPointsMomentTemp,
  GLOBAL_MODE: state.mefa.present.GLOBAL_MODE,
  bendingMomentValueMode: state.mefa.present.BENDING_MOMENT_VALUE_MODE
});

export default connect(mapStateToProps, mapDispatchToProps)(BendingMomentPanel);