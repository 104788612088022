class BeamModel {
  constructor(
    id,
    name,
    rotation,
    displacement,
    profileId,
    loads,
    supports,
    elements,
    nodes
  ) {
    this.id = id;
    this.rotation = rotation;
    this.displacement = displacement;
    this.name = name;
    this.profileId = profileId;

    this.loads = loads;
    this.supports = supports;
    this.elements = elements;
    this.nodes = nodes;

    this.classType="BeamModel";
    
    this.elementNodeParent=null;
    this.nodeNodeParent=null;
    this.supportNodeParent=null;
    this.loadNodeParent=null;
    
  }
}

export default BeamModel;
