import types from "./types";

const changeLanguage = item => ({
  type: types.CHANGE_LANGUAGE,
  item
});

const changeSelectedModelId = item => ({
  type: types.CHANGE_SELECTED_MODEL_ID,
  item
});

const changeProfile = item => ({
  type: types.CHANGE_PROFILE,
  item
});

const changeModelElement = item => ({
  type: types.CHANGE_MODEL_ELEMENT,
  item
});

const changeModel = item => ({
  type: types.CHANGE_MODEL,
  item
});

const changeDisplacement = item => ({
  type: types.CHANGE_DISPLACEMENT,
  item
});

const changeRotation = item => ({
  type: types.CHANGE_ROTATION,
  item
});

const updateSectionElement = (index, value) => ({
  type: types.UPDATE_SECTION_ELEMENT,
  item: { index: index, value: value }
});

const updateLoadL1Element = (index, value) => ({
  type: types.UPDATE_LOAD_L1_ELEMENT,
  item: { index: index, value: value }
});

const updateLoadL1AndParentIdElement = (index, value, parentID) => ({
  type: types.UPDATE_LOAD_L1_AND_PARENT_ID__ELEMENT,
  item: { index: index, parentID: parentID, value: value }
});

const updateLoadL2Element = (index, value) => ({
  type: types.UPDATE_LOAD_L2_ELEMENT,
  item: { index: index, value: value }
});

const updateLoadElementName = (index, value) => ({
  type: types.UPDATE_LOAD_ELEMENT_NAME,
  item: { index: index, value: value }
});

const updateSectionElementName = (index, value) => ({
  type: types.UPDATE_SECTION_ELEMENT_NAME,
  item: { index: index, value: value }
});

const updateSupportElementName = (index, value) => ({
  type: types.UPDATE_SUPPORT_ELEMENT_NAME,
  item: { index: index, value: value }
});

const updateSupportElement = () => ({
  type: types.UPDATE_SUPPORT_ELEMENT
});

const updateNodeElementName = (index, value) => ({
  type: types.UPDATE_NODE_ELEMENT_NAME,
  item: { index: index, value: value }
});

const updateLoadForceElement = (index, value) => ({
  type: types.UPDATE_LOAD_FORCE_ELEMENT,
  item: { index: index, value: value }
});


const changeModelElementByID = (index, value) => ({
  type: types.CHANGE_MODEL_ELEMENT_BY_ID,
  item: { index: index, value: value }
});

const addSectionElement = item => ({
  type: types.ADD_SECTION_ELEMENT,
  item
});

const deleteSectionElement = item =>({
  type: types.DELETE_SECTION_ELEMENT,
  item
});

const addSupportElement = item => ({
  type: types.ADD_SUPPORT_ELEMENT,
  item
});

const addNodeElement = item => ({
  type: types.ADD_NODE_ELEMENT,
  item
});

const updateModel = item => ({
  type: types.UPDATE_MODEL,
  item
});

const addLoadElement = item => ({
  type: types.ADD_LOAD_ELEMENT,
  item
});

const updateSupportElementLeft = item => ({
  type: types.UPDATE_SUPPORT_ELEMENT_LEFT,
  item
});

const updateSupportElementRight = item => ({
  type: types.UPDATE_SUPPORT_ELEMENT_RIGHT,
  item
});

const deleteLoadElement = item => ({
  type: types.DELETE_LOAD_ELEMENT,
  item
});

const addModel = item =>({
type: types.ADD_MODEL,
item
});

const addInitialModel = item =>({
  type: types.ADD_INITIAL_MODEL,
  item
  });
  

const deleteModel = item =>({
  type: types.DELETE_MODEL,
  item
  });
  const updateModelName = (index, value) => ({
    type: types.UPDATE_MODEL_NAME,
    item: { index: index, value: value }
  });

const loadProjectFromFile = item =>({
  type: types.LOAD_PROJECT_FROM_FILE,
  item
});

const updateSectionNodeName = item =>({
  type: types.UPDATE_SECTION_NODE_NAME,
  item
});

const updateLoadNodeName = item =>({
  type: types.UPDATE_LOAD_NODE_NAME,
  item
});

const updateSupportNodeName = item =>({
  type: types.UPDATE_SUPPORT_NODE_NAME,
  item
});

const updateNodeNodeName = item =>({
  type: types.UPDATE_NODE_NODE_NAME,
  item
});

const updateSupportReactions = item =>({
type: types.UPDATE_SUPPORT_REACTIONS,
item
});

const changeChartPointsMomentTemp = item => ({
  type: types.CHANGE_CHAR_POINTS_MOMENT_TEMP,
  item
})
const changeChartPointsDeflectionTemp = item => ({
  type: types.CHANGE_CHAR_POINTS_DEFLECTION_TEMP,
  item
})

const changeMV = (minM, maxM, minV, maxV) =>({
  type: types.CHANGE_M_V,
  item: { minM: minM, maxM: maxM,minV: minV, maxV: maxV }
})

const updatePdfData = (pdfNmax,pdfNdop, pdfNpr, pdfVmax,pdfVdop,pdfVpr) =>({
  type: types.UPDATE_PDF_DATA,
  item: {pdfNmax: pdfNmax, pdfNdop: pdfNdop, pdfNpr: pdfNpr,
     pdfVmax:  pdfVmax, pdfVdop: pdfVdop, pdfVpr: pdfVpr }
})

const changeSaftlyFactor = item =>({
  type: types.CHANGE_SAFTLY_FACTOR,
  item
  });

  const changeDeflectionArrow = item =>({
    type: types.CHANGE_DEFLECTION_ARROW,
    item
    });

const changeGlobalOffset = item =>({
    type: types.CHANGE_GLOBAL_OFFSET,
    item
  });

const updateAllGlobalData=(supportReactions,charPointsMoment,charPointsDeflection,minM,maxM, minV, maxV,pdfNmax, pdfNdop, pdfNpr, pdfVmax, pdfVdop, pdfVpr)=>({
    type: types.UPDATE_ALL_GLOBAL_DATA,
    item: {supportReactions: supportReactions, charPointsMoment: charPointsMoment, charPointsDeflection: charPointsDeflection, minM: minM, maxM: maxM,minV: minV, maxV: maxV,pdfNmax: pdfNmax, pdfNdop: pdfNdop, pdfNpr: pdfNpr,
      pdfVmax:  pdfVmax, pdfVdop: pdfVdop, pdfVpr: pdfVpr }
  })

const changeGlobalMode=(item)=>({
    type: types.CHANGE_GLOBAL_MODE,
    item
})

const changeGlobalMassLoad=item=>({
  type: types.CHANGE_GLOBAL_MASS_LOAD,
  item
})

const changeBendingMomentValueMode=item=>({
  type: types.CHANGE_BENDING_MOMENT_VALUE_MODE,
  item
})

export default {
  changeLanguage,
  changeProfile,
  changeModelElement,
  changeModel,
  changeSelectedModelId,

  updateSectionElement,
  updateLoadL1Element,
  updateLoadL1AndParentIdElement,
  updateLoadL2Element,
  updateLoadForceElement,

  updateSupportElementName,
  updateNodeElementName,
  updateSectionElementName,
  updateLoadElementName,

  updateSupportReactions,
  updateSupportElement,

  changeDisplacement,
  changeRotation,
  changeModelElementByID,

  addSectionElement,
  addSupportElement,
  addNodeElement,
  addLoadElement,
  addModel,
  addInitialModel,
  
  updateModel,
  updateModelName,

  updateSupportElementLeft,
  updateSupportElementRight,

  updateSectionNodeName,
  updateLoadNodeName,
  updateNodeNodeName,
  updateSupportNodeName,

  deleteLoadElement,
  deleteModel,
  deleteSectionElement,

  loadProjectFromFile,

  changeChartPointsDeflectionTemp,
  changeChartPointsMomentTemp,

  changeMV,

  updatePdfData,
  changeSaftlyFactor,
  changeDeflectionArrow,

  changeGlobalOffset,
  updateAllGlobalData,
  changeGlobalMode,
  changeGlobalMassLoad,
  changeBendingMomentValueMode

};
