const profiles = [
  {
    id: 1,
    name: "22/16/2,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 0.78,
    centroidDistanceEo: 0.41,
    centroidDistanceEu: 1.19,
    centroidDistanceEz: 1.1,
    radiOfGyrationIy: 0.38,
    radiOfGyrationIz: 0.84,
    momentsOfInteriaJy: 0.11,
    momentsOfInteriaJz: 0.55,
    sectionModuliWyo: 0.1,
    sectionModuliWyu: 0.1,
    sectionModuliWz: 0.5,
    weight: 0.008,
  },
  {
    id: 2,
    name: "27/18/1,25 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 0.68,
    centroidDistanceEo: 0.85,
    centroidDistanceEu: 0.95,
    centroidDistanceEz: 1.35,
    radiOfGyrationIy: 0.65,
    radiOfGyrationIz: 1.15,
    momentsOfInteriaJy: 0.29,
    momentsOfInteriaJz: 0.9,
    sectionModuliWyo: 0.31,
    sectionModuliWyu: 0.31,
    sectionModuliWz: 0.67,
    weight: 0.0062,
  },
  {
    id: 3,
    name: "35/20/0,8 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 0.74,
    centroidDistanceEo: 0.97,
    centroidDistanceEu: 1.03,
    centroidDistanceEz: 1.75,
    radiOfGyrationIy: 0.74,
    radiOfGyrationIz: 1.44,
    momentsOfInteriaJy: 0.4,
    momentsOfInteriaJz: 1.53,
    sectionModuliWyo: 0.39,
    sectionModuliWyu: 0.39,
    sectionModuliWz: 0.87,
    weight: 0.0068,
  },
  {
    id: 4,
    name: "35/21/2,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 1.4,
    centroidDistanceEo: 0.96,
    centroidDistanceEu: 1.14,
    centroidDistanceEz: 1.75,
    radiOfGyrationIy: 0.76,
    radiOfGyrationIz: 1.42,
    momentsOfInteriaJy: 0.8,
    momentsOfInteriaJz: 2.83,
    sectionModuliWyo: 0.71,
    sectionModuliWyu: 0.71,
    sectionModuliWz: 1.61,
    weight: 0.012,
  },
  {
    id: 5,
    name: "35/35/1,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 1.03,
    centroidDistanceEo: 1.72,
    centroidDistanceEu: 1.78,
    centroidDistanceEz: 1.75,
    radiOfGyrationIy: 1.34,
    radiOfGyrationIz: 1.46,
    momentsOfInteriaJy: 1.86,
    momentsOfInteriaJz: 2.21,
    sectionModuliWyo: 1.04,
    sectionModuliWyu: 1.04,
    sectionModuliWz: 1.26,
    weight: 0.0093,
  },
  {
    id: 6,
    name: "35/42/1,5 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 1.99,
    centroidDistanceEo: 1.97,
    centroidDistanceEu: 2.23,
    centroidDistanceEz: 1.75,
    radiOfGyrationIy: 1.44,
    radiOfGyrationIz: 1.43,
    momentsOfInteriaJy: 4.17,
    momentsOfInteriaJz: 4.05,
    sectionModuliWyo: 1.87,
    sectionModuliWyu: 1.87,
    sectionModuliWz: 2.31,
    weight: 0.0159,
  },
  {
    id: 7,
    name: "45/26/1,5 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 1.49,
    centroidDistanceEo: 1.34,
    centroidDistanceEu: 1.26,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 0.98,
    radiOfGyrationIz: 1.86,
    momentsOfInteriaJy: 1.42,
    momentsOfInteriaJz: 5.15,
    sectionModuliWyo: 1.06,
    sectionModuliWyu: 1.06,
    sectionModuliWz: 2.29,
    weight: 0.0135,
  },
  {
    id: 8,
    name: "45/26/1,5 (fsv)",
    materialName: "S235JR",
    shearAreasA: 1.49,
    centroidDistanceEo: 1.34,
    centroidDistanceEu: 1.26,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 0.98,
    radiOfGyrationIz: 1.86,
    momentsOfInteriaJy: 1.42,
    momentsOfInteriaJz: 5.15,
    sectionModuliWyo: 1.06,
    sectionModuliWyu: 1.06,
    sectionModuliWz: 2.29,
    weight: 0.0144,
  },
  {
    id: 9,
    name: "45/45/1,5 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 2.29,
    centroidDistanceEo: 2.23,
    centroidDistanceEu: 2.27,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.64,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 6.13,
    momentsOfInteriaJz: 8.35,
    sectionModuliWyo: 2.71,
    sectionModuliWyu: 2.71,
    sectionModuliWz: 3.71,
    weight: 0.0189,
  },
  {
    id: 10,
    name: "45/45/1,5 (fsv)",
    materialName: "S235JR",
    shearAreasA: 2.29,
    centroidDistanceEo: 2.23,
    centroidDistanceEu: 2.27,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.64,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 6.13,
    momentsOfInteriaJz: 8.35,
    sectionModuliWyo: 2.71,
    sectionModuliWyu: 2.71,
    sectionModuliWz: 3.71,
    weight: 0.02011,
  },
  {
    id: 11,
    name: "45/45/2,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 2.98,
    centroidDistanceEo: 2.24,
    centroidDistanceEu: 2.26,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.62,
    radiOfGyrationIz: 1.89,
    momentsOfInteriaJy: 7.8,
    momentsOfInteriaJz: 10.66,
    sectionModuliWyo: 3.45,
    sectionModuliWyu: 3.45,
    sectionModuliWz: 4.74,
    weight: 0.0245,
  },
  {
    id: 12,
    name: "45/45/2,5 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 3.53,
    centroidDistanceEo: 2.19,
    centroidDistanceEu: 2.31,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.58,
    radiOfGyrationIz: 1.9,
    momentsOfInteriaJy: 8.81,
    momentsOfInteriaJz: 12.69,
    sectionModuliWyo: 3.8,
    sectionModuliWyu: 3.8,
    sectionModuliWz: 5.64,
    weight: 0.0296,
  },
  {
    id: 13,
    name: "45/45/2,5 (fsv)",
    materialName: "S235JR",
    shearAreasA: 3.53,
    centroidDistanceEo: 2.19,
    centroidDistanceEu: 2.31,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.58,
    radiOfGyrationIz: 1.9,
    momentsOfInteriaJy: 8.81,
    momentsOfInteriaJz: 12.69,
    sectionModuliWyo: 3.8,
    sectionModuliWyu: 3.8,
    sectionModuliWz: 5.64,
    weight: 0.0321,
  },
  {
    id: 14,
    name: "45/52/1,5D (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 2.99,
    centroidDistanceEo: 2.6,
    centroidDistanceEu: 2.6,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.66,
    radiOfGyrationIz: 1.86,
    momentsOfInteriaJy: 8.19,
    momentsOfInteriaJz: 10.3,
    sectionModuliWyo: 3.15,
    sectionModuliWyu: 3.15,
    sectionModuliWz: 4.58,
    weight: 0.0269,
  },
  {
    id: 15,
    name: "45/60/3,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 4.52,
    centroidDistanceEo: 3.02,
    centroidDistanceEu: 2.98,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.03,
    radiOfGyrationIz: 1.95,
    momentsOfInteriaJy: 18.67,
    momentsOfInteriaJz: 17.15,
    sectionModuliWyo: 6.18,
    sectionModuliWyu: 6.18,
    sectionModuliWz: 7.62,
    weight: 0.0406, //wychodzi 0.26 zamiast 0.25?
  },
  {
    id: 16,
    name: "45/60/3,0 (fsv)",
    materialName: "S235JR",
    shearAreasA: 4.52,
    centroidDistanceEo: 3.02,
    centroidDistanceEu: 2.98,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.03,
    radiOfGyrationIz: 1.95,
    momentsOfInteriaJy: 18.67,
    momentsOfInteriaJz: 17.15,
    sectionModuliWyo: 6.18,
    sectionModuliWyu: 6.18,
    sectionModuliWz: 7.62,
    weight: 0.0435,
  },
  {
    id: 17,
    name: "45/75/3,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 5.66,
    centroidDistanceEo: 3.71,
    centroidDistanceEu: 3.79,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.5,
    radiOfGyrationIz: 1.96,
    momentsOfInteriaJy: 35.36,
    momentsOfInteriaJz: 21.9,
    sectionModuliWyo: 9.34,
    sectionModuliWyu: 9.34,
    sectionModuliWz: 9.74,
    weight: 0.0482,
  },
  {
    id: 18,
    name: "45/75/3,0 (fsv)",
    materialName: "S235JR",
    shearAreasA: 5.66,
    centroidDistanceEo: 3.71,
    centroidDistanceEu: 3.79,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.5,
    radiOfGyrationIz: 1.96,
    momentsOfInteriaJy: 35.36,
    momentsOfInteriaJz: 21.9,
    sectionModuliWyo: 9.34,
    sectionModuliWyu: 9.34,
    sectionModuliWz: 9.74,
    weight: 0.0515,
  },
  {
    id: 19,
    name: "45/90/1.5D (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 4.59,
    centroidDistanceEo: 4.5,
    centroidDistanceEu: 4.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.8,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 35.91,
    momentsOfInteriaJz: 16.74,
    sectionModuliWyo: 7.98,
    sectionModuliWyu: 7.98,
    sectionModuliWz: 7.44,
    weight: 0.0378,
  },
  {
    id: 20,
    name: "45/90/2,0D (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 5.96,
    centroidDistanceEo: 4.5,
    centroidDistanceEu: 4.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.78,
    radiOfGyrationIz: 1.89,
    momentsOfInteriaJy: 46.13,
    momentsOfInteriaJz: 21.32,
    sectionModuliWyo: 10.25,
    sectionModuliWyu: 10.25,
    sectionModuliWz: 9.47,
    weight: 0.049,
  },
  {
    id: 21,
    name: "45/90/2,5D (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 7.05,
    centroidDistanceEo: 4.5,
    centroidDistanceEu: 4.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.81,
    radiOfGyrationIz: 1.9,
    momentsOfInteriaJy: 55.62,
    momentsOfInteriaJz: 25.38,
    sectionModuliWyo: 12.35,
    sectionModuliWyu: 12.35,
    sectionModuliWz: 11.28,
    weight: 0.0592,
  },
  {
    id: 22,
    name: "45/90/2,5D (fsv)",
    materialName: "S235JR",
    shearAreasA: 7.05,
    centroidDistanceEo: 4.5,
    centroidDistanceEu: 4.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.81,
    radiOfGyrationIz: 1.9,
    momentsOfInteriaJy: 55.62,
    momentsOfInteriaJz: 25.38,
    sectionModuliWyo: 12.35,
    sectionModuliWyu: 12.35,
    sectionModuliWz: 11.28,
    weight: 0.0635,
  },
  {
    id: 23,
    name: "45/120/3,0D (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 9.04,
    centroidDistanceEo: 6.0,
    centroidDistanceEu: 6.0,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 3.64,
    radiOfGyrationIz: 1.95,
    momentsOfInteriaJy: 119.76,
    momentsOfInteriaJz: 34.31,
    sectionModuliWyo: 19.96,
    sectionModuliWyu: 19.96,
    sectionModuliWz: 15.25,
    weight: 0.0812,
  },
  {
    id: 24,
    name: "45/120/3,0D (fsv)",
    materialName: "S235JR",
    shearAreasA: 9.04,
    centroidDistanceEo: 6.0,
    centroidDistanceEu: 6.0,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 3.64,
    radiOfGyrationIz: 1.95,
    momentsOfInteriaJy: 119.76,
    momentsOfInteriaJz: 34.31,
    sectionModuliWyo: 19.96,
    sectionModuliWyu: 19.96,
    sectionModuliWz: 15.25,
    weight: 0.0863,
  },
  {
    id: 25,
    name: "45/150/3,0D (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 11.33,
    centroidDistanceEo: 7.5,
    centroidDistanceEu: 7.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 4.53,
    radiOfGyrationIz: 3.85,
    momentsOfInteriaJy: 233.12,
    momentsOfInteriaJz: 43.81,
    sectionModuliWyo: 31.1,
    sectionModuliWyu: 31.1,
    sectionModuliWz: 19.47,
    weight: 0.0946,
  },
  {
    id: 26,
    name: "45/150/3,0D (fsv)",
    materialName: "S235JR",
    shearAreasA: 11.33,
    centroidDistanceEo: 7.5,
    centroidDistanceEu: 7.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 4.53,
    radiOfGyrationIz: 3.85,
    momentsOfInteriaJy: 233.12,
    momentsOfInteriaJz: 43.81,
    sectionModuliWyo: 31.1,
    sectionModuliWyu: 31.1,
    sectionModuliWz: 19.47,
    weight: 0.1024,
  },
  {
    id: 27,
    name: "27/18/1,25 (V2A)",
    materialName: "V2A",
    shearAreasA: 0.67,
    centroidDistanceEo: 0.85,
    centroidDistanceEu: 0.95,
    centroidDistanceEz: 1.35,
    radiOfGyrationIy: 0.66,
    radiOfGyrationIz: 1.15,
    momentsOfInteriaJy: 0.29,
    momentsOfInteriaJz: 0.89,
    sectionModuliWyo: 0.31,
    sectionModuliWyu: 0.31,
    sectionModuliWz: 0.66,
    weight: 0.006,
  },
  {
    id: 28,
    name: "27/18/1,25 (V4A)",
    materialName: "V4A",
    shearAreasA: 0.67,
    centroidDistanceEo: 0.85,
    centroidDistanceEu: 0.95,
    centroidDistanceEz: 1.35,
    radiOfGyrationIy: 0.66,
    radiOfGyrationIz: 1.15,
    momentsOfInteriaJy: 0.29,
    momentsOfInteriaJz: 0.89,
    sectionModuliWyo: 0.31,
    sectionModuliWyu: 0.31,
    sectionModuliWz: 0.66,
    weight: 0.006,
  },
  {
    id: 29,
    name: "35/21/2,0 (V2A)",
    materialName: "V2A",
    shearAreasA: 1.44,
    centroidDistanceEo: 0.97,
    centroidDistanceEu: 1.13,
    centroidDistanceEz: 1.75,
    radiOfGyrationIy: 0.76,
    radiOfGyrationIz: 1.43,
    momentsOfInteriaJy: 0.84,
    momentsOfInteriaJz: 2.94,
    sectionModuliWyo: 0.74,
    sectionModuliWyu: 0.74,
    sectionModuliWz: 1.68,
    weight: 0.012,
  },
  {
    id: 30,
    name: "35/21/2,0 (V4A)",
    materialName: "V4A",
    shearAreasA: 1.44,
    centroidDistanceEo: 0.97,
    centroidDistanceEu: 1.13,
    centroidDistanceEz: 1.75,
    radiOfGyrationIy: 0.76,
    radiOfGyrationIz: 1.43,
    momentsOfInteriaJy: 0.84,
    momentsOfInteriaJz: 2.94,
    sectionModuliWyo: 0.74,
    sectionModuliWyu: 0.74,
    sectionModuliWz: 1.68,
    weight: 0.012,
  },
  {
    id: 31,
    name: "45/45/2,0 (V2A)",
    materialName: "V2A",
    shearAreasA: 2.86,
    centroidDistanceEo: 2.274,
    centroidDistanceEu: 2.226,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.62,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 10.38,
    momentsOfInteriaJz: 7.46,
    sectionModuliWyo: 3.28,
    sectionModuliWyu: 3.28,
    sectionModuliWz: 4.61,
    weight: 0.024525,
  },
  {
    id: 32,
    name: "45/45/2,0 (V4A)",
    materialName: "V4A",
    shearAreasA: 2.86,
    centroidDistanceEo: 2.274,
    centroidDistanceEu: 2.226,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 1.62,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 10.38,
    momentsOfInteriaJz: 7.46,
    sectionModuliWyo: 3.28,
    sectionModuliWyu: 3.28,
    sectionModuliWz: 4.61,
    weight: 0.024525,
  },
  {
    id: 33,
    name: "45/90/2,0 (V2A)",
    materialName: "V2A",
    shearAreasA: 5.7,
    centroidDistanceEo: 4.5,
    centroidDistanceEu: 4.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.75,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 43.27,
    momentsOfInteriaJz: 20.77,
    sectionModuliWyo: 9.61,
    sectionModuliWyu: 9.61,
    sectionModuliWz: 9.23,
    weight: 0.04905,
  },
  {
    id: 34,
    name: "45/90/2,0 (V4A)",
    materialName: "V4A",
    shearAreasA: 5.7,
    centroidDistanceEo: 4.5,
    centroidDistanceEu: 4.5,
    centroidDistanceEz: 2.25,
    radiOfGyrationIy: 2.75,
    radiOfGyrationIz: 1.91,
    momentsOfInteriaJy: 43.27,
    momentsOfInteriaJz: 20.77,
    sectionModuliWyo: 9.61,
    sectionModuliWyu: 9.61,
    sectionModuliWz: 9.23,
    weight: 0.04905,
  },
  {
    id: 35,
    name: "CENTUM XL 80 (S275J2H)",
    materialName: "S275J2H",
    shearAreasA: 6.19,
    centroidDistanceEo: 4.0,
    centroidDistanceEu: 4.0,
    centroidDistanceEz: 4.0,
    radiOfGyrationIy: 3.23,
    radiOfGyrationIz: 3.23,
    momentsOfInteriaJy: 64.51,
    momentsOfInteriaJz: 64.51,
    sectionModuliWyo: 16.13,
    sectionModuliWyu: 16.13,
    sectionModuliWz: 16.13,
    weight: 0.0551,
  },
  {
    id: 36,
    name: "CENTUM XL 100 (S275J2H)",
    materialName: "S275J2H",
    shearAreasA: 9.73,
    centroidDistanceEo: 50.0,
    centroidDistanceEu: 50.0,
    centroidDistanceEz: 50.0,
    radiOfGyrationIy: 4.02,
    radiOfGyrationIz: 4.02,
    momentsOfInteriaJy: 157.14,
    momentsOfInteriaJz: 157.14,
    sectionModuliWyo: 31.43,
    sectionModuliWyu: 31.43,
    sectionModuliWz: 31.43,
    weight: 0.0846,
  },
  {
    id: 37,
    name: "CENTUM XL 120 (S275J2H)",
    materialName: "S275J2H",
    shearAreasA: 14.45,
    centroidDistanceEo: 60.0,
    centroidDistanceEu: 60.0,
    centroidDistanceEz: 50.0,
    radiOfGyrationIy: 4.46,
    radiOfGyrationIz: 4.05,
    momentsOfInteriaJy: 310.55,
    momentsOfInteriaJz: 237.23,
    sectionModuliWyo: 51.76,
    sectionModuliWyu: 51.76,
    sectionModuliWz: 47.44,
    weight: 0.122,
  },
  {
    id: 38,
    name: "CENTUM XL 120s (S275J2H)",
    materialName: "S275J2H",
    shearAreasA: 17.55,
    centroidDistanceEo: 60.0,
    centroidDistanceEu: 60.0,
    centroidDistanceEz: 50.0,
    radiOfGyrationIy: 4.61,
    radiOfGyrationIz: 4.03,
    momentsOfInteriaJy: 372.76,
    momentsOfInteriaJz: 284.42,
    sectionModuliWyo: 62.12,
    sectionModuliWyu: 62.12,
    sectionModuliWz: 56.88,
    weight: 0.1483,
  },
  {
    id: 39,
    name: "CENTUM XL 200 (S235)",
    materialName: "S235",
    shearAreasA: 24.15,
    centroidDistanceEo: 100.0,
    centroidDistanceEu: 100.0,
    centroidDistanceEz: 50.0,
    radiOfGyrationIy: 7.21,
    radiOfGyrationIz: 4.24,
    momentsOfInteriaJy: 1255.68,
    momentsOfInteriaJz: 433.47,
    sectionModuliWyo: 125.56,
    sectionModuliWyu: 125.56,
    sectionModuliWz: 86.69,
    weight: 0.2046,
  },
  {
    id: 40,
    name: "SIGNUM LP 50 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 1.29,
    centroidDistanceEo: 1.54,
    centroidDistanceEu: 1.54,
    centroidDistanceEz: 2.5,
    radiOfGyrationIy: 1.37,
    radiOfGyrationIz: 1.97,
    momentsOfInteriaJy: 1.26,
    momentsOfInteriaJz: 5.01,
    sectionModuliWyo: 0.87,
    sectionModuliWyu: 0.87,
    sectionModuliWz: 2.01,
    weight: 0.01069,
  },
];

export default profiles;
