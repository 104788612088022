import React from "react"
import translateText from "../../LanguageTranslator"
import { connect } from "react-redux"
import ConcentratedLoadElement from "../../../app/classes/load/ConcentratedLoadElement"
import MomentLoadElement from "../../../app/classes/load/MomentLoadElement"
import DistributedLoadElement from "../../../app/classes/load/DistributedLoadElement"
import materials from "../../data/materials"
import mefaActions from "../../../app/mefa-app/duck/actions"
import FixedSupportElement from "../../../app/classes/support/FixedSupportElement"
import NoneSupportElement from "../../../app/classes/support/NoneSupportElement"
import Button from '@material-ui/core/Button';

class SummaryPanel extends React.Component {
  state = {
    maxMoment: 0,
    maxDeflection: 0,
    admMoment: 0,
    admDeflection: 0,
    currentGlobalMaxM: 0,
    currentGlobalMinM: 0,
    currentGlobalMaxV: 0,
    currentGlobalMinV: 0,

    pdfNmax: 0,
    pdfNdop: 0,
    pdfVmax: 0,
    pdfVdop: 0,
    pdfNpr: 0,
    pdfVpr: 0,

    currentReaction: [],
    currentCharPointsMoment: null,
    currentCharPointsDeflection: null
  }

  translate = (text) => {
    return translateText(text, this.props.selectedLanguage)
  }

  compareNumbers(a, b) {
    return a - b
  }
  changeModelElementForNull = ()=>{
    if(this.props.changeModelElement !== null){
    this.props.changeModelElement(null)
    }
  }
  render() {
    return (
      <><div onMouseMove={()=>this.changeModelElementForNull()} style={{marginLeft: "5px", marginRight: "5px", fontSize: "12px"}}>
  <label style={{fontWeight: "bold",margin:0,marginTop: "3px"}}>{this.translate("profilHeaderSummaryPanel") +": "+this.props.selectedProfile.name}</label>
        <table className="table table-bordered table-sm" style={{ lineHeight: "1" , margin: 0,background: "whitesmoke", borderCollapse: "unset"}}>
    <thead>
      <tr>
        <th></th>
        <th scope="col">{this.translate("momentSummary")}</th>
        <th scope="col">{this.translate("deflectionSummary")}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">{this.translate("maximalSummary")}</th>
        <td>{Number(this.state.maxMoment).toFixed(2)}</td>
        <td> { Math.abs(Number(this.state.maxDeflection).toFixed(1))}</td>
      </tr>
      <tr>
        <th scope="row">{this.translate("admissibleSummary")}</th>
        <td>{Number(this.state.admMoment).toFixed(2)}</td>
        <td>{Number(this.state.admDeflection).toFixed(1)}</td>
      </tr>
    
    </tbody>
  </table>
  <div style={{ marginLeft: "2%", marginTop: "1%"}}>
  <label style={{display:"block",margin:0}}>{this.translate("fsv")}</label>
  <label style={{display:"block",margin:0}}>{this.translate("fbv")}</label>
  </div>
  <div className="summaryPanelButton" style={{position: "relative",    textAlign: "center", marginBottom:"5px"}}>
        {this.props.numberOfModel !== 0 ? <Button style={{boxShadow:"1px 1px 1px #036198",background: "#337ab7",color: "rgb(245 245 245)", letterSpacing: "1px", fontWeight: 400,fontSize: "11px", padding:"6px 16px"}} variant="contained" 
        color="default" size="small" onClick={()=>this.props.loadCanvases()}>{this.translate("generatePDFButton")}</Button>:false}
        </div>
        </div>
      </>
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentStateModel !== prevProps.currentStateModel || this.props.bendingMomentValueMode !== prevProps.bendingMomentValueMode) {
      let material = materials.filter(
        (material) => material.name === this.props.selectedProfile.materialName
      )
      this.calculate(material)
    }
  }
  componentDidMount() {


    let material = materials.filter(
      (material) => material.name === this.props.selectedProfile.materialName
    )
    this.calculate(material)
  }
  solveSysOfLinEqs(A, b) {
    let N = b.length

    for (let p = 0; p < N; p++) {
      let max = p
      for (let j = p + 1; j < N; j++) {
        if (Math.abs(A[j][p]) > Math.abs(A[max][p])) {
          max = j
        }
      }

      let temp = []
      temp = A[p]
      A[p] = A[max]
      A[max] = temp

      let t = b[p]
      b[p] = b[max]
      b[max] = t

      if (Math.abs(A[p][p]) >= 1e-10) {
        for (let k = p + 1; k < N; k++) {
          let alpha = A[k][p] / A[p][p]
          b[k] = b[k] - alpha * b[p]
          for (let m = p; m < N; m++) {
            A[k][m] = A[k][m] - alpha * A[p][m]
          }
        }
      }
    }
    let x = []
    for (let i = N - 1; i >= 0; i--) {
      let sum = 0.0
      for (let j = i + 1; j < N; j++) {
        sum += A[i][j] * x[j]
      }
      x[i] = (b[i] - sum) / A[i][i]
    }

    return x
  }
  calcSpanV_A(ord, pos, Pp, L, Ee, jj) {
    let Mxa, Max, MM1, MM2, MM3

    if (Math.abs(ord) < 1e-6 || Math.abs(ord - 1) < 1e-6) {
     // alert("spanA return 0")
      return 0
    }

    let P = Pp //N
    let a = pos * L //
    let x = ord * L //M
    let E = Ee //N
    let J = jj //m

    //real
    let MAa = -((P * a * Math.pow(L - a, 2)) / Math.pow(L, 2))
    let RAa = ((P * Math.pow(L - a, 2)) / Math.pow(L, 3)) * (2 * a + L)
    let Ma = RAa * a + MAa
    let MCa = -((P * Math.pow(a, 2) * (L - a)) / Math.pow(L, 2))

    if (x >= a) {
      Mxa = RAa * x + MAa - P * (x - a)
    } else {
      Mxa = RAa * x + MAa
    }
    //imag
    let MAx = -((x * Math.pow(L - x, 2)) / Math.pow(L, 2))
    let RAx = (Math.pow(L - x, 2) / Math.pow(L, 3)) * (2 * x + L)

    if (x >= a) {
      Max = RAx * a + MAx
    } else {
      Max = RAx * a + MAx - 1.0 * (a - x)
    }
    let Mx = RAx * x + MAx
    let MCx = -((Math.pow(x, 2) * (L - x)) / Math.pow(L, 2))

    if (x >= a) {
      //1
      if (Math.abs(a) > 1e-6) {
        let a1 = (Ma - MAa) / (a - 0.0)
        let a2 = (Max - MAx) / (a - 0.0)
        let b1 = (MAa * a - Ma * 0.0) / (a - 0.0)
        let b2 = (MAx * a - Max * 0.0) / (a - 0.0)

        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(a, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(a, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(a, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (a - 0.0)
      } else {
        MM1 = 0.0
      }
      //2
      if (Math.abs(x - a) > 1e-6) {
        let a1 = (Mxa - Ma) / (x - a)
        let a2 = (Mx - Max) / (x - a)
        let b1 = (Ma * x - Mxa * a) / (x - a)
        let b2 = (Max * x - Mx * a) / (x - a)
        MM2 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(x, 3) - Math.pow(a, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(x, 2) - Math.pow(a, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(x, 2) - Math.pow(a, 2)) +
          b1 * b2 * (x - a)
      } else {
        MM2 = 0.0
      }
      //3
      if (Math.abs(L - x) > 1e-6) {
        let a1 = (MCa - Mxa) / (L - x)
        let a2 = (MCx - Mx) / (L - x)
        let b1 = (Mxa * L - MCa * x) / (L - x)
        let b2 = (Mx * L - MCx * x) / (L - x)
        MM3 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(x, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(x, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(x, 2)) +
          b1 * b2 * (L - x)
      } else {
        MM3 = 0.0
      }
    } else {
      //1
      if (Math.abs(x) > 1e-6) {
        let a1 = (Mxa - MAa) / (x - 0.0)
        let a2 = (Mx - MAx) / (x - 0.0)
        let b1 = (MAa * x - Ma * 0.0) / (x - 0.0)
        let b2 = (MAx * x - Max * 0.0) / (x - 0.0)
        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(x, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(x, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(x, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (x - 0.0)
      } else {
        MM1 = 0.0
      }
      //2
      if (Math.abs(x - a) > 1e-6) {
        let a1 = (Ma - Mxa) / (a - x)
        let a2 = (Max - Mx) / (a - x)
        let b1 = (Mxa * a - Ma * x) / (a - x)
        let b2 = (Mx * a - Max * x) / (a - x)
        MM2 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(a, 3) - Math.pow(x, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(a, 2) - Math.pow(x, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(a, 2) - Math.pow(x, 2)) +
          b1 * b2 * (a - x)
      } else {
        MM2 = 0.0
      }
      //3
      if (Math.abs(L - a) > 1e-6) {
        let a1 = (MCa - Ma) / (L - a)
        let a2 = (MCx - Max) / (L - a)
        let b1 = (Ma * L - MCa * a) / (L - a)
        let b2 = (Max * L - MCx * a) / (L - a)
        MM3 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(a, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(a, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(a, 2)) +
          b1 * b2 * (L - a)
      } else {
        MM3 = 0.0
      }
    }
    let v = (MM1 + MM2 + MM3) / (E * J)

    return v
  }
  calcSpanV_E(ord, pos, Pp, L, Ee, jj) {
    if (Math.abs(ord) < 1e-6 || Math.abs(ord - 1) < 1e-6) {
      //alert("spanE return 0")
      return 0
    }

    let P = Pp
    let a = pos * L
    let x = ord * L
    let E = Ee
    let J = jj

    let MAa = -(
      (P / Math.pow(L, 2)) *
      (Math.pow(L, 2) - 4.0 * L * a + 3.0 * Math.pow(a, 2))
    )
    let RAa = -(((6.0 * P * a) / Math.pow(L, 3)) * (L - a))
    let Ma1 = RAa * a + MAa
    let Ma2 = RAa * a + MAa + P
    let MCa = -(((P * a) / Math.pow(L, 2)) * (2.0 * L - 3.0 * a))
    let Mxa
    if (x >= a) {
      Mxa = RAa * x + MAa + P
    } else {
      Mxa = RAa * x + MAa
    }
    //imag
    let MAx = -((x * Math.pow(L - x, 2)) / Math.pow(L, 2))
    let RAx = (Math.pow(L - x, 2) / Math.pow(L, 3)) * (2 * x + L)
    let Max
    if (x >= a) {
      Max = RAx * a + MAx
    } else {
      Max = RAx * a + MAx - 1.0 * (a - x)
    }
    let Mx = RAx * x + MAx
    let MCx = -((Math.pow(x, 2) * (L - x)) / Math.pow(L, 2))

    //multip
    let MM1
    let MM2
    let MM3
    if (x >= a) {
      //1
      if (Math.abs(a) > 1e-6) {
        let a1 = (Ma1 - MAa) / (a - 0.0)
        let a2 = (Max - MAx) / (a - 0.0)
        let b1 = (MAa * a - Ma1 * 0.0) / (a - 0.0)
        let b2 = (MAx * a - Max * 0.0) / (a - 0.0)
        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(a, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(a, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(a, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (a - 0.0)
      } else {
        MM1 = 0
      }
      //2
      if (Math.abs(x - a) > 1e-6) {
        let a1 = (Mxa - Ma2) / (x - a)
        let a2 = (Mx - Max) / (x - a)
        let b1 = (Ma2 * x - Mxa * a) / (x - a)
        let b2 = (Max * x - Mx * a) / (x - a)
        MM2 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(x, 3) - Math.pow(a, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(x, 2) - Math.pow(a, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(x, 2) - Math.pow(a, 2)) +
          b1 * b2 * (x - a)
      } else {
        MM2 = 0
      }
      //3
      if (Math.abs(L - x) > 1e-6) {
        let a1 = (MCa - Mxa) / (L - x)
        let a2 = (MCx - Mx) / (L - x)
        let b1 = (Mxa * L - MCa * x) / (L - x)
        let b2 = (Mx * L - MCx * x) / (L - x)
        MM3 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(x, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(x, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(x, 2)) +
          b1 * b2 * (L - x)
      } else {
        MM3 = 0
      }
    } else {
      //1
      if (Math.abs(x) > 1e-6) {
        let a1 = (Mxa - MAa) / (x - 0.0)
        let a2 = (Mx - MAx) / (x - 0.0)
        let b1 = (MAa * x - Mxa * 0.0) / (x - 0.0)
        let b2 = (MAx * x - Max * 0.0) / (x - 0.0)
        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(x, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(x, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(x, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (x - 0.0)
      } else {
        MM1 = 0
      }
      //2
      if (Math.abs(x - a) > 1e-6) {
        let a1 = (Ma1 - Mxa) / (a - x)
        let a2 = (Max - Mx) / (a - x)
        let b1 = (Mxa * a - Ma1 * x) / (a - x)
        let b2 = (Mx * a - Max * x) / (a - x)
        MM2 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(a, 3) - Math.pow(x, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(a, 2) - Math.pow(x, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(a, 2) - Math.pow(x, 2)) +
          b1 * b2 * (a - x)
      } else {
        MM2 = 0
      }
      //3
      if (Math.abs(L - a) > 1e-6) {
        let a1 = (MCa - Ma2) / (L - a)
        let a2 = (MCx - Max) / (L - a)
        let b1 = (Ma2 * L - MCa * a) / (L - a)
        let b2 = (Max * L - MCx * a) / (L - a)
        MM3 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(a, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(a, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(a, 2)) +
          b1 * b2 * (L - a)
      } else {
        MM3 = 0
      }
    }
    let v = (MM1 + MM2 + MM3) / (E * J)
    return v
  }
  calcSpanV_B(ord, pos1, pos2, p11, p22, L, Ee, Jj) {
    if (Math.abs(ord) < 1e-6 || Math.abs(ord - 1) < 1e-6) {
     // alert("spanA return 0")
      return 0
    }

    let p1 = p11 //N
    let p2 = p22 //N
    let apo = pos1 * L //m
    let ako = pos2 * L //m
    let x = ord * L //m
    let E = Ee //N
    let J = Jj //m
    let angle = Math.PI / 2

    let r = 0.5 * (p1 + p2) * (ako - apo)
    let ao
    let bo
    if (Math.abs(ako - apo) > 1e-6 && Math.abs(r) > 1e-6) {
      ao = ((p2 - p1) * Math.sin(angle)) / (ako - apo)
      bo = ((p1 * ako - p2 * apo) * Math.sin(angle)) / (ako - apo)
    } else {
      ao = 0
      bo = 0
    }
    let P = r * Math.sin(angle)

    //real
    let MAa =
      -(
        ((L * ao) / 5.0) * (Math.pow(ako, 5) - Math.pow(apo, 5)) -
        ((ao * Math.pow(L, 2)) / 2.0) * (Math.pow(ako, 4) - Math.pow(apo, 4)) +
        ((ao * Math.pow(L, 3)) / 3.0) * (Math.pow(ako, 3) - Math.pow(apo, 3)) +
        ((L * bo) / 4.0) * (Math.pow(ako, 4) - Math.pow(apo, 4)) -
        ((2.0 * bo * Math.pow(L, 2)) / 3.0) *
          (Math.pow(ako, 3) - Math.pow(apo, 3)) +
        ((bo * Math.pow(L, 3)) / 2.0) * (Math.pow(ako, 2) - Math.pow(apo, 2))
      ) / Math.pow(L, 3)
    let RAa =
      (((2.0 * ao) / 5.0) * (Math.pow(ako, 5) - Math.pow(apo, 5)) -
        ((3.0 * ao * L) / 4.0) * (Math.pow(ako, 4) - Math.pow(apo, 4)) +
        ((ao * Math.pow(L, 3)) / 2.0) * (Math.pow(ako, 2) - Math.pow(apo, 2)) +
        (bo / 2.0) * (Math.pow(ako, 4) - Math.pow(apo, 4)) -
        bo * L * (Math.pow(ako, 3) - Math.pow(apo, 3)) +
        bo * Math.pow(L, 3) * (ako - apo)) /
      Math.pow(L, 3)
    let RBa = P - RAa
    let MCa =
      (((L * ao) / 5.0) * (Math.pow(ako, 5) - Math.pow(apo, 5)) +
        ((L * bo) / 4.0) * (Math.pow(ako, 4) - Math.pow(apo, 4)) -
        ((Math.pow(L, 2) * ao) / 4.0) * (Math.pow(ako, 4) - Math.pow(apo, 4)) -
        ((Math.pow(L, 2) * bo) / 3.0) * (Math.pow(ako, 3) - Math.pow(apo, 3))) /
      Math.pow(L, 3)
    //imag
    let MAx = -((x * Math.pow(L - x, 2)) / Math.pow(L, 2))
    let RAx = (Math.pow(L - x, 2) / Math.pow(L, 3)) * (2 * x + L)
    let RBx = 1.0 - RAx
    let Mx = RAx * x + MAx
    let MCx = -((Math.pow(x, 2) * (L - x)) / Math.pow(L, 2))

    //multip
    let MM1
    let MM2
    let MM3
    let MM4
    if (x >= ako) {
      //1
      if (Math.abs(apo) > 1e-6) {
        let Mapox = MAx + RAx * apo
        let Mapoa = MAa + RAa * apo
        let a1 = (Mapoa - MAa) / (apo - 0.0)
        let a2 = (Mapox - MAx) / (apo - 0.0)
        let b1 = (MAa * apo - Mapoa * 0.0) / (apo - 0.0)
        let b2 = (MAx * apo - Mapox * 0.0) / (apo - 0.0)
        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(apo, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(apo, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(apo, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (apo - 0.0)
      } else {
        MM1 = 0
      }
      //2
      if (Math.abs(ako - apo) > 1e-6) {
        let Mapox = MAx + RAx * apo
        let Makox = MAx + RAx * ako

        let a1 = ((p2 - p1) * Math.sin(angle)) / (ako - apo)
        let a2 = (Makox - Mapox) / (ako - apo)
        let b2 = (Mapox * ako - Makox * apo) / (ako - apo) + a2 * apo

        let gg = ako - apo
        let gd = 0
        let c1 =
          (MAa * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * apo * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * a2 * (Math.pow(gg, 3) - Math.pow(gd, 3))) / 3.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            a2 *
            (Math.pow(gg, 4) - Math.pow(gd, 4))) /
            4.0 -
          ((1.0 / 6.0) * a1 * a2 * (Math.pow(gg, 5) - Math.pow(gd, 5))) / 5.0
        let c2 =
          MAa * b2 * (gg - gd) +
          RAa * apo * b2 * (gg - gd) +
          (RAa * b2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            b2 *
            (Math.pow(gg, 3) - Math.pow(gd, 3))) /
            3.0 -
          ((1.0 / 6.0) * a1 * b2 * (Math.pow(gg, 4) - Math.pow(gd, 4))) / 4.0
        MM2 = c1 + c2
      } else {
        MM2 = 0
      }
      //3
      if (Math.abs(x - ako) > 1e-6) {
        let Mxa = MCa + RBa * (L - x)
        let Makox = MAx + RAx * ako
        let Makoa = MCa + RBa * (L - ako)

        let a1 = (Mxa - Makoa) / (x - ako)
        let a2 = (Mx - Makox) / (x - ako)
        let b1 = (Makoa * x - Mxa * ako) / (x - ako)
        let b2 = (Makox * x - Mx * ako) / (x - ako)

        MM3 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(x, 3) - Math.pow(ako, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(x, 2) - Math.pow(ako, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(x, 2) - Math.pow(ako, 2)) +
          b1 * b2 * (x - ako)
      } else {
        MM3 = 0
      }
      //4
      if (Math.abs(L - x) > 1e-6) {
        let Mxa = MCa + RBa * (L - x)

        let a1 = (MCa - Mxa) / (L - x)
        let a2 = (MCx - Mx) / (L - x)
        let b1 = (Mxa * L - MCa * x) / (L - x)
        let b2 = (Mx * L - MCx * x) / (L - x)

        MM4 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(x, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(x, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(x, 2)) +
          b1 * b2 * (L - x)
      } else {
        MM4 = 0
      }
    } else if (x >= apo && x <= ako) {
      //1
      if (Math.abs(apo) > 1e-6) {
        let Mapox = MAx + RAx * apo
        let Mapoa = MAa + RAa * apo

        let a1 = (Mapoa - MAa) / (apo - 0.0)
        let a2 = (Mapox - MAx) / (apo - 0.0)
        let b1 = (MAa * apo - Mapoa * 0.0) / (apo - 0.0)
        let b2 = (MAx * apo - Mapox * 0.0) / (apo - 0.0)

        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(apo, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(apo, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(apo, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (apo - 0.0)
      } else {
        MM1 = 0
      }
      //2
      if (Math.abs(ako - apo) > 1e-6 && Math.abs(x - apo) > 1e-6) {
        let Mapox = MAx + RAx * apo
        let a1 = ((p2 - p1) * Math.sin(angle)) / (ako - apo)
        let a2 = (Mx - Mapox) / (x - apo)
        let b2 = (Mapox * x - Mx * apo) / (x - apo) + a2 * apo
        let gg = x - apo
        let gd = 0
        let c1 =
          (MAa * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * apo * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * a2 * (Math.pow(gg, 3) - Math.pow(gd, 3))) / 3.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            a2 *
            (Math.pow(gg, 4) - Math.pow(gd, 4))) /
            4.0 -
          ((1.0 / 6.0) * a1 * a2 * (Math.pow(gg, 5) - Math.pow(gd, 5))) / 5.0
        let c2 =
          MAa * b2 * (gg - gd) +
          RAa * apo * b2 * (gg - gd) +
          (RAa * b2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            b2 *
            (Math.pow(gg, 3) - Math.pow(gd, 3))) /
            3.0 -
          ((1.0 / 6.0) * a1 * b2 * (Math.pow(gg, 4) - Math.pow(gd, 4))) / 4.0
        MM2 = c1 + c2
      } else {
        MM2 = 0
      }
      //3
      if (Math.abs(x - ako) > 1e-6 && Math.abs(ako - apo) > 1e-6) {
        let Makox = MCx + RBx * (L - ako)
        let a1 = ((p2 - p1) * Math.sin(angle)) / (ako - apo)
        let a2 = (Makox - Mx) / (ako - x)
        let b2 = (Mx * ako - Makox * x) / (ako - x) + a2 * apo
        let gg = ako - apo
        let gd = x - apo
        let c1 =
          (MAa * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * apo * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * a2 * (Math.pow(gg, 3) - Math.pow(gd, 3))) / 3.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            a2 *
            (Math.pow(gg, 4) - Math.pow(gd, 4))) /
            4.0 -
          ((1.0 / 6.0) * a1 * a2 * (Math.pow(gg, 5) - Math.pow(gd, 5))) / 5.0
        let c2 =
          MAa * b2 * (gg - gd) +
          RAa * apo * b2 * (gg - gd) +
          (RAa * b2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            b2 *
            (Math.pow(gg, 3) - Math.pow(gd, 3))) /
            3.0 -
          ((1.0 / 6.0) * a1 * b2 * (Math.pow(gg, 4) - Math.pow(gd, 4))) / 4.0
        MM3 = c1 + c2
      } else {
        MM3 = 0
      }
      //4
      if (Math.abs(L - ako) > 1e-6) {
        let Makox = MCx + RBx * (L - ako)
        let Makoa = MCa + RBa * (L - ako)
        let a1 = (MCa - Makoa) / (L - ako)
        let a2 = (MCx - Makox) / (L - ako)
        let b1 = (Makoa * L - MCa * ako) / (L - ako)
        let b2 = (Makox * L - MCx * ako) / (L - ako)
        MM4 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(ako, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(ako, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(ako, 2)) +
          b1 * b2 * (L - ako)
      } else {
        MM4 = 0
      }
    } else {
      //1
      if (Math.abs(x) > 1e-6) {
        let Mxa = MAa + RAa * x
        let a1 = (Mxa - MAa) / (x - 0.0)
        let a2 = (Mx - MAx) / (x - 0.0)
        let b1 = (MAa * x - Mxa * 0.0) / (x - 0.0)
        let b2 = (MAx * x - Mx * 0.0) / (x - 0.0)
        MM1 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(x, 3) - Math.pow(0.0, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(x, 2) - Math.pow(0.0, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(x, 2) - Math.pow(0.0, 2)) +
          b1 * b2 * (x - 0.0)
      } else {
        MM1 = 0
      }
      //2
      if (Math.abs(x) > 1e-6 && apo - x > 1e-6) {
        let Mxa = MAa + RAa * x
        let Mapox = MAx + RAx * apo - 1.0 * (apo - x)

        let a1 = (Mxa - MAa) / (x - 0.0)
        let a2 = (Mapox - Mx) / (apo - x)
        let b1 = (MAa * x - Mxa * 0.0) / (x - 0.0)
        let b2 = (Mx * apo - Mapox * x) / (apo - x)

        MM2 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(apo, 3) - Math.pow(x, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(apo, 2) - Math.pow(x, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(apo, 2) - Math.pow(x, 2)) +
          b1 * b2 * (apo - x)
      } else {
        MM2 = 0
      }
      //3
      if (Math.abs(ako - apo) > 1e-6) {
        let Makox = MCx + RBx * (L - ako)
        let Mapox = MAx + RAx * apo - 1.0 * (apo - x)
        let a1 = ((p2 - p1) * Math.sin(angle)) / (ako - apo)
        let a2 = (Makox - Mapox) / (ako - apo)
        let b2 = (Mapox * ako - Makox * apo) / (ako - apo) + a2 * apo
        let gg = ako - apo
        let gd = 0
        let c1 =
          (MAa * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * apo * a2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 +
          (RAa * a2 * (Math.pow(gg, 3) - Math.pow(gd, 3))) / 3.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            a2 *
            (Math.pow(gg, 4) - Math.pow(gd, 4))) /
            4.0 -
          ((1.0 / 6.0) * a1 * a2 * (Math.pow(gg, 5) - Math.pow(gd, 5))) / 5.0
        let c2 =
          MAa * b2 * (gg - gd) +
          RAa * apo * b2 * (gg - gd) +
          (RAa * b2 * (Math.pow(gg, 2) - Math.pow(gd, 2))) / 2.0 -
          (0.5 *
            p1 *
            Math.sin(angle) *
            b2 *
            (Math.pow(gg, 3) - Math.pow(gd, 3))) /
            3.0 -
          ((1.0 / 6.0) * a1 * b2 * (Math.pow(gg, 4) - Math.pow(gd, 4))) / 4.0
        MM3 = c1 + c2
      } else {
        MM3 = 0
      }
      //4
      if (Math.abs(L - ako) > 1e-6) {
        let Makox = MCx + RBx * (L - ako)
        let Makoa = MCa + RBa * (L - ako)
        let a1 = (MCa - Makoa) / (L - ako)
        let a2 = (MCx - Makox) / (L - ako)
        let b1 = (Makoa * L - MCa * ako) / (L - ako)
        let b2 = (Makox * L - MCx * ako) / (L - ako)
        MM4 =
          (1.0 / 3.0) * a1 * a2 * (Math.pow(L, 3) - Math.pow(ako, 3)) +
          (1.0 / 2.0) * a1 * b2 * (Math.pow(L, 2) - Math.pow(ako, 2)) +
          (1.0 / 2.0) * b1 * a2 * (Math.pow(L, 2) - Math.pow(ako, 2)) +
          b1 * b2 * (L - ako)
      } else {
        MM4 = 0
      }
    }
    let v = (MM1 + MM2 + MM3 + MM4) / (E * J)
    return v
  }
  multiply(a, b) {
    let aRows = a.length
    let aColumns = a[0].length
    let bRows = b.length
    let bColumns = b[0].length

    if (aColumns !== bRows) {
     // console.log("IllegalcostamWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWw")
    }

    let resultant = []

    for (let i = 0; i < aRows; i++) {
      resultant[i] = []
      for (let j = 0; j < bColumns; j++) {
        resultant[i][j] = 0
      }
    }
    for (let i = 0; i < aRows; i++) {
      for (let j = 0; j < bColumns; j++) {
        for (let k = 0; k < aColumns; k++) {
          resultant[i][j] += a[i][k] * b[k][j]
        }
      }
    }

    return resultant
  }

  calculate = (material) => {
    let beamNodesTab = [...this.props.beamNodes]
    let beamElementsTab = [...this.props.beamElements]
    let beamLoadTab = [...this.props.beamLoads]
    let beamSupportsTab = [...this.props.beamSupports]

    let E = material[0].moduleOfElastic;
    let fy = material[0].yieldStrengthFyk;

    let J=0;
    if(this.props.selectedOptionRotation === 0 || this.props.selectedOptionRotation === 180)
    {
      J = this.props.selectedProfile.momentsOfInteriaJy;
    }else if(this.props.selectedOptionRotation === 90 || this.props.selectedOptionRotation === 270){
      J = this.props.selectedProfile.momentsOfInteriaJz;
    }
    

    let saftlyFactor =this.props.SAFTLY_FACTOR;;

    let startSupport = beamSupportsTab[0]
    let endSupport = beamSupportsTab[beamSupportsTab.length - 1]

    let pertFactor = 0.001
    let momentFlag = []

    let massLoad = this.props.selectedProfile.weight

    for (let i = 0; i < beamElementsTab.length; i++) {
      let e = beamElementsTab[i]
      let l = new DistributedLoadElement(
        4 + i,
        "loadWeight",
        massLoad,
        0,
        1,
        e.id
      )
      beamLoadTab.push(l)
    }

    let DOFsNumber = beamNodesTab.length * 2
    let GSM = []

    for (let i = 0; i < DOFsNumber; i++) {
      GSM[i] = []
      for (let j = 0; j < DOFsNumber; j++) {
        GSM[i][j] = 0
      }
    }

    let ESMs = []

    for (let i = 0; i < beamElementsTab.length; i++) {
      ESMs[i] = []
      for (let j = 0; j < 2; j++) {
        ESMs[i][j] = []
        for (let k = 0; k < 2; k++) {
          ESMs[i][j][k] = 0
        }
      }
    }

    let GLV = []
    GLV.length = DOFsNumber

    let esCharPoints = []
    let Ls = []

    for (let i = 0; i < GLV.length; i++) {
      GLV[i] = 0
    }

    let nSLFs = []

    for (let i = 0; i < beamElementsTab.length; i++) {
      nSLFs[i] = []
      for (let j = 0; j < 2; j++) {
        nSLFs[i][j] = 0
      }
    }

    let elemOrder = []

    for (let i = 0; i < beamElementsTab.length; i++) {
      let eCharPoints = []
      esCharPoints.push(eCharPoints)
      nSLFs[i] = [0.0, 0.0, 0.0, 0.0]

      let e = beamElementsTab[i]
      momentFlag[i] = false
      let L = e.lengthL
      elemOrder[i] = e.id
      Ls[i] = L

      let ESM = []

      ESM = [
        [
          (12.0 * E * J) / Math.pow(L, 3),
          (6.0 * E * J) / Math.pow(L, 2),
          (-12.0 * E * J) / Math.pow(L, 3),
          (6.0 * E * J) / Math.pow(L, 2)
        ],
        [
          (6.0 * E * J) / Math.pow(L, 2),
          (4.0 * E * J) / L,
          (-6.0 * E * J) / Math.pow(L, 2),
          (2.0 * E * J) / L
        ],
        [
          (-12.0 * E * J) / Math.pow(L, 3),
          (-6.0 * E * J) / Math.pow(L, 2),
          (12.0 * E * J) / Math.pow(L, 3),
          (-6.0 * E * J) / Math.pow(L, 2)
        ],
        [
          (6.0 * E * J) / Math.pow(L, 2),
          (2.0 * E * J) / L,
          (-6.0 * E * J) / Math.pow(L, 2),
          (4.0 * E * J) / L
        ]
      ]

      ESMs[i] = ESM

      for (let j = 0; j < ESM.length; j++) {
        for (let k = 0; k < ESM[j].length; k++) {
          GSM[i * 2 + j][i * 2 + k] += ESM[j][k]
        }
      }

      for (let elc = 0; elc < beamLoadTab.length; elc++) {
        let l = beamLoadTab[elc]
        if (l.parentID === e.id) {
          let t1
          let t2
          let m1
          let m2

          if (l instanceof ConcentratedLoadElement) {
            let v = l.force
            let pos = l.lengthL1

            t1 =
              ((v * Math.pow(L - pos * L, 2)) / Math.pow(L, 3)) *
              (2 * pos * L + L)
            t2 =
              ((v * Math.pow(pos * L, 2)) / Math.pow(L, 3)) *
              (3.0 * L - 2.0 * pos * L)

            m1 = +((v * pos * L * Math.pow(L - pos * L, 2)) / Math.pow(L, 2))
            m2 = -((v * Math.pow(pos * L, 2) * (L - pos * L)) / Math.pow(L, 2))

            eCharPoints.push(pos)
          } else if (l instanceof MomentLoadElement) {
            let v = l.force
            let pos = l.lengthL1

            t1 = -(((6.0 * v * pos * L) / Math.pow(L, 3)) * (L - pos * L))
            t2 = +(((6.0 * v * pos * L) / Math.pow(L, 3)) * (L - pos * L))

            m1 = +(
              (v / Math.pow(L, 2)) *
              (Math.pow(L, 2) - 4 * L * pos * L + 3 * Math.pow(pos * L, 2))
            )
            m2 = -(((v * pos * L) / Math.pow(L, 2)) * (2 * L - 3 * pos * L))

            eCharPoints.push(pos - pertFactor)
            eCharPoints.push(pos)
            eCharPoints.push(pos + pertFactor)

            momentFlag[i] = true
          } else if (l instanceof DistributedLoadElement) {
            let v1 = l.force
            let v2 = l.force
            let pos1 = l.lengthL1
            let pos2 = l.lengthL2
            let angle = Math.PI / 2

            let x1 = pos1 * L
            let x2 = pos2 * L

            let a
            let b
            let resultant
            if (Math.abs(x2 - x1) > 1e-6) {
              a = (v2 * Math.sin(angle) - v1 * Math.sin(angle)) / (x2 - x1)
              b =
                (v1 * Math.sin(angle) * x2 - v2 * Math.sin(angle) * x1) /
                (x2 - x1)
              resultant = ((v1 + v2) / 2) * (x2 - x1)
            } else {
              a = 0
              b = 0
              resultant = 0
            }

            t1 =
              (((2.0 * a) / 5.0) * (Math.pow(x2, 5) - Math.pow(x1, 5)) -
                ((3.0 * a * L) / 4.0) * (Math.pow(x2, 4) - Math.pow(x1, 4)) +
                ((a * Math.pow(L, 3)) / 2.0) *
                  (Math.pow(x2, 2) - Math.pow(x1, 2)) +
                (b / 2.0) * (Math.pow(x2, 4) - Math.pow(x1, 4)) -
                b * L * (Math.pow(x2, 3) - Math.pow(x1, 3)) +
                b * Math.pow(L, 3) * (x2 - x1)) /
              Math.pow(L, 3)
            t2 =
              resultant * Math.sin(angle) -
              (((2.0 * a) / 5.0) * (Math.pow(x2, 5) - Math.pow(x1, 5)) -
                ((3.0 * a * L) / 4.0) * (Math.pow(x2, 4) - Math.pow(x1, 4)) +
                ((a * Math.pow(L, 3)) / 2.0) *
                  (Math.pow(x2, 2) - Math.pow(x1, 2)) +
                (b / 2.0) * (Math.pow(x2, 4) - Math.pow(x1, 4)) -
                b * L * (Math.pow(x2, 3) - Math.pow(x1, 3)) +
                b * Math.pow(L, 3) * (x2 - x1)) /
                Math.pow(L, 3)

            m1 = +(
              (((L * a) / 5.0) * (Math.pow(x2, 5) - Math.pow(x1, 5)) -
                ((a * Math.pow(L, 2)) / 2.0) *
                  (Math.pow(x2, 4) - Math.pow(x1, 4)) +
                ((a * Math.pow(L, 3)) / 3.0) *
                  (Math.pow(x2, 3) - Math.pow(x1, 3)) +
                ((L * b) / 4.0) * (Math.pow(x2, 4) - Math.pow(x1, 4)) -
                ((2.0 * b * Math.pow(L, 2)) / 3.0) *
                  (Math.pow(x2, 3) - Math.pow(x1, 3)) +
                ((b * Math.pow(L, 3)) / 2.0) *
                  (Math.pow(x2, 2) - Math.pow(x1, 2))) /
              Math.pow(L, 3)
            )
            m2 = +(
              (((L * a) / 5.0) * (Math.pow(x2, 5) - Math.pow(x1, 5)) +
                ((L * b) / 4.0) * (Math.pow(x2, 4) - Math.pow(x1, 4)) -
                ((Math.pow(L, 2) * a) / 4.0) *
                  (Math.pow(x2, 4) - Math.pow(x1, 4)) -
                ((Math.pow(L, 2) * b) / 3.0) *
                  (Math.pow(x2, 3) - Math.pow(x1, 3))) /
              Math.pow(L, 3)
            )

            eCharPoints.push(pos1)
            eCharPoints.push(pos2)
          } else {
            t1 = 0.0
            t2 = 0.0
            m1 = 0.0
            m2 = 0.0
          }

          GLV[i * 2 + 0] += t1
          GLV[i * 2 + 1] += m1
          GLV[i * 2 + 2] += t2
          GLV[i * 2 + 3] += m2
          nSLFs[i][0] += t1
          nSLFs[i][1] += m1
          nSLFs[i][2] += t2
          nSLFs[i][3] += m2
        }
      }
    }

    for (let nId = 0; nId < beamNodesTab.length; nId++) {
      let setBC = true

      if (beamSupportsTab[nId] instanceof NoneSupportElement) {
        setBC = false
      }

      if (setBC) {
        for (let i = 0; i < GSM.length; i++) {
          GSM[nId * 2][i] = 0
          GSM[i][nId * 2] = 0
        }
        GLV[nId * 2] = 0
        GSM[nId * 2][nId * 2] = 1
      }
    }

    if (startSupport instanceof FixedSupportElement) {
      for (let i = 0; i < GSM.length; i++) {
        GSM[1][i] = 0
        GSM[i][1] = 0
      }
      GLV[1] = 0
      GSM[1][1] = 1
    }

    if (endSupport instanceof FixedSupportElement) {
      for (let i = 0; i < GSM.length; i++) {
        GSM[GLV.length - 1][i] = 0
        GSM[i][GLV.length - 1] = 0
      }
      GLV[GLV.length - 1] = 0
      GSM[GLV.length - 1][GLV.length - 1] = 1
    }

    for (let i = 0; i < GSM.length; i++) {
      if (Math.abs(GSM[i][i]) < 1e-14) {
        GSM[i][i] = 1
      }
    }

    let x = this.solveSysOfLinEqs(GSM, GLV)

    let eMs = []
    let eFIs = []
    let eUs = []
    let eTs = []
    for (let i = 0; i < beamElementsTab.length; i++) {
      let f0 = x[i * 2 + 0]
      let f1 = x[i * 2 + 1]
      let f2 = x[i * 2 + 2]
      let f3 = x[i * 2 + 3]

      let SF = this.multiply(ESMs[i], [[f0], [f1], [f2], [f3]])

      eUs[i] = [f0, f2]
      eFIs[i] = [f1, f3]
      eTs[i] = [SF[0][0] - nSLFs[i][0], SF[2][0] - nSLFs[i][2]]
      eMs[i] = [SF[1][0] - nSLFs[i][1], SF[3][0] - nSLFs[i][3]]
    }

    let charPoints = []

    let res = 0

    for (let i = 0; i < beamElementsTab.length; i++) {
      res = 16
     
      if(this.props.bendingMomentValueMode){
        res = 64*beamElementsTab[i].lengthL;
      }
      if (momentFlag[i]) {
        res = 64
      }
      let ords = []
      for (let j = 0; j < res + 1; j++) {
        let ord = j / res
        ords.push(ord)
      }

      let lords = [...esCharPoints[i]]
      let aords = [...ords]
      let allords = [...aords, ...lords]

      let uniq = [...new Set(allords)]
      uniq.sort(this.compareNumbers)
      charPoints[i] = uniq
    }

    let globalMaxM = -9e9
    let globalMinM = 9e9
    let globalExtM = 0.0
    let elemMaxM = []
    let elemMinM = []

    let globalMaxV = -9e9
    let globalMinV = 9e9
    let globalExtV = 0.0
    let elemMaxV = []
    let elemMinV = []

    let eVs = []
    let eSFMs = []

    for (let i = 0; i < beamElementsTab.length; i++) {
      elemMaxM[i] = -9e9
      elemMinM[i] = 9e9
      elemMaxV[i] = -9e9
      elemMinV[i] = 9e9

      let e = beamElementsTab[i]
      let stm = eMs[i][0]
      let enm = eMs[i][1]

      let q2 = eUs[i][0]
      let q5 = eUs[i][1]
      let q3 = eFIs[i][0]
      let q6 = eFIs[i][1]

      let L = Ls[i]
      let eSFM = []
      let eV = []

      for (let j = 0; j < charPoints[i].length; j++) {
        let ord = charPoints[i][j]

        let M = stm + ((-enm - stm) / L) * ord * L
        let N2 = 1.0 - 3.0 * Math.pow(ord, 2.0) + 2.0 * Math.pow(ord, 3.0)
        let N3 = ord * L * (1.0 - 2.0 * ord + Math.pow(ord, 2.0))
        let N5 = 3.0 * Math.pow(ord, 2.0) - 2.0 * Math.pow(ord, 3.0)
        let N6 = ord * L * (-ord + Math.pow(ord, 2.0))
        let V = q2 * N2 + q3 * N3 + q5 * N5 + q6 * N6

        for (let elc = 0; elc < beamLoadTab.length; elc++) {
          let l = beamLoadTab[elc]
          if (e.id === l.parentID) {
            if (l instanceof ConcentratedLoadElement) {
              let v = l.force
              let pos = l.lengthL1

              if (ord <= pos) {
                let v1 = v * (1 - pos)
                M += v1 * ord * L
              } else {
                let v2 = v * pos
                M += v2 * (1 - ord) * L
              }
              V += this.calcSpanV_A(ord, pos, v, L, E, J)
            } else if (l instanceof MomentLoadElement) {
              let v = l.force
              let pos = l.lengthL1

              let v1 = -v * (1.0 / L)
              let v2 = -v1
              if (ord <= pos) {
                M += v1 * ord * L
              } else {
                M += v2 * (1 - ord) * L
              }
              V += this.calcSpanV_E(ord, pos, v, L, E, J)
            } else if (l instanceof DistributedLoadElement) {
              let v1 = l.force
              let v2 = l.force
              let pos1 = l.lengthL1
              let pos2 = l.lengthL2
              let angle = Math.PI / 2
              let x1 = pos1 * L
              let x2 = pos2 * L
              let cx = ord * L

              let r = 0.5 * (v1 + v2) * (x2 - x1)
              let rx
              if (Math.abs(x2 - x1) > 1e-6 && Math.abs(r) > 1e-6) {
                rx =
                  (v1 * (x2 - x1) * (x1 + 0.5 * (x2 - x1)) +
                    0.5 *
                      (x2 - x1) *
                      (v2 - v1) *
                      (x1 + (2.0 / 3.0) * (x2 - x1))) /
                  r
              } else {
                rx = (x1 + x2) / 2.0
              }
              let r1 = r * (1.0 - rx / L)
              let r2 = r - r1

              if (ord <= pos1) {
                M += r1 * ord * L
              } else if (ord <= pos2) {
                M +=
                  r1 * x1 +
                  r1 * (cx - x1) -
                  (1.0 / 2.0) * v1 * Math.sin(angle) * Math.pow(cx - x1, 2) -
                  (((1.0 / 6.0) * (v2 - v1) * Math.sin(angle)) / (x2 - x1)) *
                    Math.pow(cx - x1, 3)
              } else {
                M += r2 * (1 - ord) * L
              }
              V += this.calcSpanV_B(ord, pos1, pos2, v1, v2, L, E, J)
            }
          }
        }
        eSFM[j] = [M,ord]

        if (M > globalMaxM) {
          globalMaxM = M
        }
        if (M < globalMinM) {
          globalMinM = M
        }
        if (Math.abs(M) > Math.abs(globalExtM)) {
          globalExtM = M
        }
        if (M > elemMaxM[i]) {
          elemMaxM[i] = M
        }
        if (M < elemMinM[i]) {
          elemMinM[i] = M
        }
        V = V * 100000
        eV[j] = V
        if (V > globalMaxV) {
          globalMaxV = V
        }
        if (V < globalMinV) {
          globalMinV = V
        }
        if (Math.abs(V) > Math.abs(globalExtV)) {
          globalExtV = V
        }
        if (V > elemMaxV[i]) {
          elemMaxV[i] = V
        }
        if (V < elemMinV[i]) {
          elemMinV[i] = V
        }
      }
      eSFMs[i] = eSFM
      eVs[i] = eV
    }
    let nodeSize = beamNodesTab.length
    let nReactions = []
    for (let i = 0; i < nodeSize; i++) {
      nReactions[i] = 0
    }

    for (let i3 = 0; i3 < beamElementsTab.length; i3++) {
      let stt = eTs[i3][0]
      let ent = eTs[i3][1]
      let arrayOfDouble = nReactions
      let i4 = i3
      arrayOfDouble[i4] = arrayOfDouble[i4] + -stt

      arrayOfDouble = nReactions
      i4 = i3 + 1
      arrayOfDouble[i4] = arrayOfDouble[i4] + -ent
    }

    
        
    let dProWx, dExtM
    let dMomMin = Math.abs(globalMaxM) < Math.abs(globalMinM) ? globalMaxM : globalMinM;;
    let dMomMax = Math.abs(globalMaxM) > Math.abs(globalMinM) ? globalMaxM : globalMinM;
    let dMomMinWx = this.getProfileWx(dMomMin);
    let dMomMaxWx = this.getProfileWx(dMomMax);
    if (Math.abs(dMomMin) / dMomMinWx > Math.abs(dMomMax) / dMomMaxWx) {
      dProWx = dMomMinWx
      dExtM = dMomMin
    } else {
      dProWx = dMomMaxWx
      dExtM = dMomMax
    }
    let dSigExt = dExtM / dProWx
    let dSigAll = fy / saftlyFactor
    let sigRatioD = Math.abs(dSigExt / dSigAll)

   
    let extt=Number(dSigExt*1000).toFixed(2);
    let dsigall =Number(dSigAll).toFixed(2);
    let dsigaration =Number(sigRatioD*100000).toFixed(2);

   //alert(extt + " " + dsigall + " " + dsigaration )
 
    
    let disp = []
    disp = this.getMaxDispRatio(elemOrder, elemMaxV, elemMinV)
   let ohuj=Number(disp[1]/disp[2]*100).toFixed(3)
    

    let W = this.getProfileWx(Math.abs(globalMaxM) > Math.abs(globalMinM) ? globalMaxM : globalMinM)
    let maxMm = (fy * W) / saftlyFactor;
    
    let pdfVmaxx= Math.abs(globalMaxV) > Math.abs(globalMinV) ? Math.abs(globalMaxV) : Math.abs(globalMinV)

    if (!(JSON.stringify({ a: this.state.currentReaction }) === JSON.stringify({ a: nReactions })) ||
    !(JSON.stringify({ a: this.state.currentCharPointsDeflection }) === JSON.stringify({ a: eVs })) ||
    !(JSON.stringify({ a: this.state.currentCharPointsMoment }) === JSON.stringify({ a: eSFMs }))||
    !(this.state.admDeflection === disp)

    ) {
     this.props.updateAllGlobalData(nReactions,eSFMs,eVs,globalMinM,globalMaxM, globalMinV,globalMaxV,extt , dsigall, dsigaration, pdfVmaxx, disp[2], ohuj)
    }

   
    this.setState({
      currentCharPointsMoment: eSFMs,
      currentCharPointsDeflection: eVs,
      currentReaction: nReactions,
      currentGlobalMaxM:Math.abs(globalMaxM) > Math.abs(globalMinM) ? globalMaxM : globalMinM,
      currentGlobalMinM: globalMinM,
      currentGlobalMaxV:Math.abs(globalMaxV) > Math.abs(globalMinV) ? globalMaxV : globalMinV,
      currentGlobalMinV: globalMinV,

      maxMoment:Math.abs(globalMaxM) > Math.abs(globalMinM) ? globalMaxM : globalMinM,
      admMoment: maxMm * 0.001,
      maxDeflection:Math.abs(globalMaxV) > Math.abs(globalMinV) ? globalMaxV : globalMinV,
      admDeflection: disp[2],

      pdfNmax: extt,
      pdfNdop: dsigall,
      pdfNpr: dsigaration,

      pdfVmax: Math.abs(globalMaxV) > Math.abs(globalMinV) ? Math.abs(globalMaxV) : Math.abs(globalMinV),
      pdfVdop: disp[2],
      pdfVpr: ohuj,
  
    })
  }

  getMaxDispRatio(ids, maxVs, minVs) {
    let maxDispRatio = 0.0
    let maxDisp = 0.0
    let maxAllowed = 0.0

    for (let i = 0; i < ids.length; i++) {
      let L = this.props.beamElements[i].lengthL
      let displ= this.getDispl();
      let allowed = L / displ
      let maxV = Math.max(Math.abs(maxVs[i]), Math.abs(minVs[i]))
      if (maxV > maxDisp) {
        maxDisp = maxV
        maxAllowed = allowed
      }
      let cr = maxV / L
      if (cr > maxDispRatio) maxDispRatio = cr
    }
    let tab = []
    tab.push(maxDispRatio)
    tab.push(maxDisp)
    tab.push(maxAllowed * 1000)
    // alert(tab)
    return tab
  }

  getDispl(){ 
    let dis=150.0;

      if(this.props.selectedOptionDisplacement.value === "1-200"){
        dis=200.0;
      }else{
        dis=150.0;
      }

      console.log(this.props.selectedOptionDisplacement)

    return Number(this.props.selectedOptionDisplacement);
  }
  getProfileWx(M) {
    let W;

    let r=0;
    if(this.props.selectedOptionRotation === 0){
      r=0;
    }else if(this.props.selectedOptionRotation === 90){
      r=1.5707963267948966;
    }else if(this.props.selectedOptionRotation === 180){
      r=Math.PI;
    }else if(this.props.selectedOptionRotation === 270){
      r=1.5707963267948966;
    }

    if (Math.abs(r) < 0.000001) {
      if (M > 0) {
        W = this.props.selectedProfile.sectionModuliWyu;
      } else {
        W = this.props.selectedProfile.sectionModuliWyo;
      }
    } else if (Math.abs(r - Math.PI) < 0.000001) {
      if (M > 0) {
        W = this.props.selectedProfile.sectionModuliWyo;
      } else {
        W = this.props.selectedProfile.sectionModuliWyu;
      }
    } else {
      
      W = this.props.selectedProfile.sectionModuliWz
    }
    
    return W 
  }
}
const mapDispatchToProps = (dispatch) => ({
  changeModelElement: value => dispatch(mefaActions.changeModelElement(value)),

  updateSupportReaction: (supportReactions) =>dispatch(mefaActions.updateSupportReactions(supportReactions)),
  updateChartPointsMomentTemp: (charPointsMoment) =>dispatch(mefaActions.changeChartPointsMomentTemp(charPointsMoment)),
  updateChartPointsDeflectionTemp: (charPointsDeflection) =>dispatch(mefaActions.changeChartPointsDeflectionTemp(charPointsDeflection)),
  changeMV: (minM, maxM, minV, maxV) =>dispatch(mefaActions.changeMV(minM, maxM, minV, maxV)),
  updatePdfData:(pdfNmax, pdfNdop, pdfNpr, pdfVmax, pdfVdop, pdfVpr)=> dispatch(mefaActions.updatePdfData(pdfNmax, pdfNdop, pdfNpr, pdfVmax, pdfVdop, pdfVpr)),
  updateAllGlobalData: (supportReactions,charPointsMoment,charPointsDeflection,minM,maxM, minV, maxV,pdfNmax, pdfNdop, pdfNpr, pdfVmax, pdfVdop, pdfVpr) => 
     dispatch(mefaActions.updateAllGlobalData(supportReactions,charPointsMoment,charPointsDeflection,minM, maxM, minV, maxV,pdfNmax, pdfNdop, pdfNpr, pdfVmax, pdfVdop, pdfVpr))
})

const mapStateToProps = (state) => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  selectedLanguage: state.mefa.present.selectedLanguage,
  beamElements:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].elements,
  beamLoads:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].loads,
  beamSupports:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].supports,
  beamNodes:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].nodes,
  //currentState: state.mefa.present.models[state.mefa.present.selectedModelIndex],
  selectedProfile:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].profileId,
  currentStateModel:
    state.mefa.present.models[state.mefa.present.selectedModelIndex],
    selectedOptionDisplacement:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].displacement,
  selectedOptionRotation:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].rotation,
    SAFTLY_FACTOR: state.mefa.present.SAFTLY_FACTOR,
    bendingMomentValueMode: state.mefa.present.BENDING_MOMENT_VALUE_MODE
})

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPanel)
