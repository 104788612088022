import React from "react";
import './ElementsTreeView.css';
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
 import SvgIcon from '@material-ui/core/SvgIcon';
import translateText from "../../LanguageTranslator";
import { connect } from "react-redux";
import ReactModal from 'react-modal-resizable-draggable';
import CloseIcon from '@material-ui/icons/Close';


function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

class ElementsTreeView extends React.Component {
  state = {
    selectedElementId: false,
    selectedElementInfo: false,
    open: false,
    openIDs: []
  };

  translate = text => {
    return translateText(text, this.props.selectedLanguage);
  };

  showTreeViewElements = () => {
    return (
      <TreeView
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}>
          <TreeItem
            nodeId="elements"
            label={`${this.translate(this.props.elementsName)} (${
              this.props.elements.length
            })`}
          >
          {this.props.elements.map(element => (
            <TreeItem
              key={element.id}
              nodeId={element.name}
              label={element.name}
              onDoubleClick={this.onOpenModal.bind(this, element.id)}
            />
          ))}
        </TreeItem>
      </TreeView>
    );
  };

  showModal = () => {
   let element = this.props.elements[this.state.selectedElementId];
   let bodyWidth = document.querySelector('body').getBoundingClientRect().width;
   let bodyHeight = document.querySelector('body').getBoundingClientRect().height;
    
    return (
      <div key={element}>
        {this.state.openIDs.map(element => (element !== undefined 
        ?
          <ReactModal
            key={element}
            isOpen={true}
            className={"my-modal-custom-class"}
            width={500} 
            height={400} 
            top={(bodyWidth > 840) && (bodyHeight > 500) ? "calc(50% - 200px)" : 0}
            left={(bodyWidth > 840) && (bodyHeight > 500) ? "calc(50% - 250px)" : 0}
            

          >
          {this.showElement(this.props.elements[element])}
          <button className="elementsTreeViewButtonCancel" onClick={()=>this.onCloseModal(element)}><CloseIcon/></button>
          </ReactModal>
        :
        false)
        )}
      </div>
    );
  };

  onOpenModal = id => {
    let openIdRe=this.state.openIDs.map((element) => element === id ? undefined : element)
    this.setState({
      open: true,
      openIDs: [...openIdRe,id]
    //  selectedElementId: id
    });
  };



  onCloseModal = (id) => {
    this.setState({
      open: false,
      openIDs: this.state.openIDs.map((element) => element === id ? undefined : element)
      //selectedElementId: false,
     // selectedElementInfo: false
    });
  };
  showElementInfo = (element,key) => {
    this.setState({
      selectedElementInfo: this.translate(key + "Info")
    });
  };

  showElement = element => {
    const elementData = Object.keys(element).map(key =>
      (key !== "id") && (key !== "name") && (key !== "weight") ? (
        <tr key={key} >
          <th scope="col" >{this.translate(key)} </th>
          <td >{element[key]}</td>
          <td>{this.translate(key + "Info")}</td>
        </tr>
      ) : (
        false
      )
    );

    return (
      <div className="treeViewTable">
        <label className="elementsTreeViewLabelInformationName">{element.name}</label>
        <table className="table table-hover">
          <tbody >{elementData}</tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.showTreeViewElements()}
        {this.showModal()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedLanguage: state.mefa.present.selectedLanguage
});

export default connect(mapStateToProps, {})(ElementsTreeView);
