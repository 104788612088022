import React from 'react'
import { connect } from "react-redux";
import UndoRedo from '../../../UndoRedo';
import LanguageSelector from '../../../app/mefa-app/components/LanguageSelector';
import mefaActions from "../../../app/mefa-app/duck/actions";
import translateText from '../../LanguageTranslator';
import SectionElement from '../../../app/classes/element/SectionElement'
import NodeElement from '../../../app/classes/node/NodeElement'
import SupportElement from '../../../app/classes/support/SupportElement'
import ConcentratedLoadElement from '../../../app/classes/load/ConcentratedLoadElement';
import BeamModel from '../../../app/classes/model/BeamModel'
import SimpleReactFileUploader from './SimpleReactFileUploader';
import ScrollableTabsButtonAuto from './ModelTab'
import SaveIcon from '@material-ui/icons/Save';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';
import Modal from "react-responsive-modal";
import { saveAs } from 'file-saver';

let counter = 2;
let profile = {
  id: 1,
  name: "22/16/2,0 (fbv)",
  materialName: "S250GD-Z275-N-A",
  shearAreasA: 0.78,
  centroidDistanceEo: 0.41,
  centroidDistanceEu: 1.19,
  centroidDistanceEz: 1.1,
  radiOfGyrationIy: 0.38,
  radiOfGyrationIz: 0.84,
  momentsOfInteriaJy: 0.11,
  momentsOfInteriaJz: 0.55,
  sectionModuliWyo: 0.28,
  sectionModuliWyu: 0.1,
  sectionModuliWz: 0.5,
  weight: 0.008
}
const getCurrentDate = (separator = "_") => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let hour = newDate.getHours();
  let minutes = newDate.getMinutes();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
    }${separator}${date}_${hour}_${minutes < 10 ? `0${minutes}` : `${minutes}`}`;
};

class MenuTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: Number(this.props.SAFTLY_FACTOR).toFixed(2).replace(".", ","),
      fileSaveName: "Project_",
      openNameModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  projectNameInput = React.createRef();

  translate = (text) => {
    return (translateText(text, this.props.selectedLanguage));
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let dotToComma = (this.state.value).replace(",", ".");

    let mov = Number(dotToComma).toFixed(2)

    this.props.changeSaftlyFactor(mov);
  }

  create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line no-mixed-operators
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  addNewModel = () => {
    let parentID = this.create_UUID();
    let model = new BeamModel(this.create_UUID(), this.translate("modelChild") + ' ' + (counter++), 0, 200, profile,
      [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") + ' ' + 1, 1.0, 0.5, parentID)],
      [new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + 1, 0), new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + 2, 0)],
      [new SectionElement(parentID, this.translate("elementChild") + ' ' + 1, 1)],
      [new NodeElement(this.create_UUID(), this.translate("nodeChild") + ' ' + 1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") + ' ' + 2, 1)])

    this.props.addModel(model);
    this.props.changeModel(model.id);

  }

  onOpenModal = () => {
    this.setState({
      openNameModal: true,

    });
  };



  onCloseModal = () => {
    this.setState({
      openNameModal: false,
    });
  };

  showModal = () => {
    return (<>

<Modal open={this.state.openNameModal} onClose={this.onCloseModal} center>
        
        <div className="modalInformationWrapper">
              <label className="programInformation">
              {this.translate("save")}
              </label>
    
              <div className="fieldsWrapper">
                <div className="elementField">
                  <label>
                  {this.translate("save")}
                  </label>
                  <input
                  type="text"
                  defaultValue={this.state.fileSaveName + getCurrentDate()}
                  ref={projectNameInput =>
                    (this.projectNameInput = projectNameInput)
                  }
                />
                </div>
                <div className="buttonsWrapper">
                  <button className="buttonConfirm" onClick={() => this.handleSubmitName()}><span>OK</span></button>
                  <button className="buttonCancel" onClick={() => this.onCloseModal()}><CloseIcon/></button>
                </div>
              </div>
            </div>
        </Modal>
    </>
    );
  };

  handleSubmitName = event => {
    this.saveProject(this.projectNameInput.value)

    this.onCloseModal();
  };
  saveProject = (projectName) => {
    var json = JSON.stringify(this.props.stateC);
    var toDownload = new Blob([json], { type: 'octet/stream' });
    saveAs(toDownload, projectName + ".mefa");
  }

  render() {
    return (
      <>
        <div>
          <div >
            <LanguageSelector style={{ float: "right", padding: "5px" }} />
          </div>

          <div style={{ display: "flex", padding: "5px" }}>
          <button style={{ marginLeft:"3px",borderRadius:"4px",border:"2px solid gray", backgroundColor:"whitesmoke",    padding: "1px 5px"}}>
              <NoteAddIcon style={{fontSize:"1.8rem",color: "rgb(0 0 0 / 74%)"}} onClick={() => this.addNewModel()}>{"AddNewModel"}</NoteAddIcon>
            </button>

            <SimpleReactFileUploader />
            <button style={{marginLeft:"3px",borderRadius:"4px",border:"2px solid gray", backgroundColor:"whitesmoke",    padding: "1px 5px"}}>
              <SaveIcon style={{fontSize:"1.8rem",    color: "rgb(0 0 0 / 74%)"}} onClick={() => this.onOpenModal()}>{"saveProject"}</SaveIcon>
            </button>
            <UndoRedo style={{ marginLeft: "30px" }} />

            {/* <div>
               <form onSubmit={this.handleSubmit}>
                <label style={{fontWeight: "bold"}}>
                FoS:
                <input  style={{marginRight: "1px",width: "50px", borderRadius: "4px"}}type="double" value={this.state.value} onChange={this.handleChange} />
                </label>
                <button style={{height: "26px",borderRadius:"4px",background:"#9db3c9b5"}} type="submit" value="Wyślij" ><CheckIcon fontSize="small"/></button>
                
            </form>
               </div> */}


          </div>
          <div style={{ padding: "5px" }}>
            <ScrollableTabsButtonAuto />
          </div>
          {this.showModal()}
        </div>
      </>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  addModel: (value) => dispatch(mefaActions.addModel(value)),
  changeModel: model => dispatch(mefaActions.changeModel(model)),
  changeSaftlyFactor: saftlyFactor => dispatch(mefaActions.changeSaftlyFactor(saftlyFactor))
})

const mapStateToProps = state => ({
  selectedLanguage: state.mefa.present.selectedLanguage,
  stateC: state.mefa.present,
  SAFTLY_FACTOR: state.mefa.present.SAFTLY_FACTOR
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuTools)