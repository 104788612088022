/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { connect } from "react-redux";
import { BlobProvider } from "@react-pdf/renderer";
import materials from "../data/materials"
import translateText from '../LanguageTranslator';
import fotoLogoMefa from "./../resources/MEFALogoRaport_1.jpg";
import DistributedLoadElement from "../../app/classes/load/DistributedLoadElement";
import ConcentratedLoadElement from "../../app/classes/load/ConcentratedLoadElement";
import MomentLoadElement from "../../app/classes/load/MomentLoadElement";
import Modal from "react-responsive-modal";
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import fontRoboto from './../fonts/TimesRoman.ttf'
import fontRobotoBold from './../fonts/timesRomanBold.ttf'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";



Font.register({
  family: 'Roboto',
  fonts: [{ src: fontRoboto, },]
})
Font.register({
  family: 'RobotoBold',
  fonts: [{ src: fontRobotoBold, },]
})
const translate = (text, language) => {
  return (translateText(text, language));
}

const getCurrentDate = (separator = "/") => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let hour = newDate.getHours();
  let minutes = newDate.getMinutes();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
    }${separator}${date} ${hour}:${minutes < 10 ? `0${minutes}` : `${minutes}`}`;
};



const getProfileWx = (M, profil, rotation) => {
  let W;
  let r = 0;
  if (rotation === 0) {
    r = 0;
  } else if (rotation === 90) {
    r = 1.5707963267948966;
  } else if (rotation === 180) {
    r = Math.PI;
  } else if (rotation === 270) {
    r = 1.5707963267948966;
  }

  if (Math.abs(r) < 0.000001) {
    if (M > 0) {
      W = profil.sectionModuliWyu;
    } else {
      W = profil.sectionModuliWyo;
    }
  } else if (Math.abs(r - Math.PI) < 0.000001) {
    if (M > 0) {
      W = profil.sectionModuliWyo;
    } else {
      W = profil.sectionModuliWyu;
    }
  } else {
    W = profil.sectionModuliWz
  }

  return W
}
const getDispl = (selectedOptionDisplacement) => {
  let dis = "L/";

  if (selectedOptionDisplacement.value === "1-200") {
   // dis = "L/200";
  } else {
   // dis = "L/150";
  }

  return (dis + Number(selectedOptionDisplacement));
}

const getProfileIx = (profil, rotation) => {
  let J = 0;

  if (rotation === 0 || rotation === 180) {
    J = profil.momentsOfInteriaJy;
  } else if (rotation === 90 || rotation === 270) {
    J = profil.momentsOfInteriaJz;
  }
  return J
}

const getMaterial = (profil) => {
  let material = materials.filter(
    (material) => material.name === profil.materialName
  )

  return material[0].moduleOfElastic;
}

const getTotalBeamLength = (elements) => {
  let beamLength = 0;

  elements.forEach(element => {
    beamLength = beamLength + element.lengthL;
  })

  return Number(beamLength).toFixed(2);
}

const getAllNodes = (elements) => {
  let beamNodes = [];
  let beamLength = 0;

  beamNodes.push("0.00")
  elements.forEach((element, index) => {
    beamLength = beamLength + element.lengthL;
    beamNodes.push(Number(parseFloat(beamLength)).toFixed(2))
  })

  return beamNodes;
}

class PdfRaport extends React.Component {
  state = {
    openModal: false
  }

  downloaded() {
    this.props.downloaded();
  }

  onCloseModal = () => {
    this.setState({
      openModal: false,
    });
    this.props.downloaded();
  };

  pdfDownloadLink = () => {
    return (
      <BlobProvider document={<PdfDocument
        bmc={this.props.bmc}
        bc={this.props.bc}
        dc={this.props.dc}
        lg={this.props.selectedLanguage}
        loads={this.props.beamLoads}
        supportsTab={this.props.supportReactions}
        selectedProfile={this.props.selectedProfile}
        rotation={this.props.rotation}
        selectedOptionDisplacement={this.props.selectedOptionDisplacement}
        nodes={getAllNodes(this.props.beamElements)}

        agent={this.props.agent}
        object={this.props.object}
        advice={this.props.advice}

        totalLength={getTotalBeamLength(this.props.beamElements)}
        pdfNmax={this.props.pdfNmax}
        pdfNdop={this.props.pdfNdop}
        pdfNpr={this.props.pdfNpr}

        pdfVmax={this.props.pdfVmax}
        pdfVdop={this.props.pdfVdop}
        pdfVpr={this.props.pdfVpr}

        SAFTLY_FACTOR={this.props.SAFTLY_FACTOR}

        material={getMaterial(this.props.selectedProfile)}

        globalMaxM={this.props.globalMaxM}
      />}>
        {({ url }) => (
          url !== null ?
            <Modal onClose={this.onCloseModal} open={this.props.bmc != null} center>
              


              <div className="modalInformationWrapper">
              <label style={{padding: "7px 11px"}} className="programInformation">
              <GetAppRoundedIcon style={{width:"1.1em", height: "1.1em"}} />
              </label>
    
              <div className="fieldsWrapper" >
                <div className="elementField" style={{width:"100%", marginTop: "26px", justifyContent:"center", flexDirection:"row"}}>
                  <label type="text">
                    <a onClick={() => this.downloaded()} href={url} target="_blank" >
                      <button style={{ borderRadius: "4px", backgroundColor: "#e10f18", 
                      color: "white", padding:"3px 15px", letterSpacing:"1px", 
                      boxShadow:"1px 1px 1px 1px #b73333" }}>
                        < DescriptionIcon style={{
                        width: "1.6em",
                        height: "1.6em", color: "white"
                      }} /><span>PDF</span></button>
                    </a>
                  </label>
                </div>
                <div className="buttonsWrapper" style={{justifyContent:"flex-end"}}>
                <button className="buttonCancel" onClick={()=>this.downloaded()}><CloseIcon/></button>
                </div>
              </div>
            </div>

            </Modal>
            : false
        )

        }
      </BlobProvider>
    );
  };


  render() {
    return <>{this.pdfDownloadLink()}

    </>;
  }
}

const mapStateToProps = state => ({
  selectedLanguage: state.mefa.present.selectedLanguage,

  beamElements: state.mefa.present.models[state.mefa.present.selectedModelIndex].elements,
  beamLoads: state.mefa.present.models[state.mefa.present.selectedModelIndex].loads,
  beamSupports: state.mefa.present.models[state.mefa.present.selectedModelIndex].supports,
  selectedProfile: state.mefa.present.models[state.mefa.present.selectedModelIndex].profileId,

  globalMaxM: state.mefa.present.globalMaxM,
  globalMinM: state.mefa.present.globalMinM,
  globalMaxV: state.mefa.present.globalMaxV,
  globalMinV: state.mefa.present.globalMinV,

  pdfNmax: state.mefa.present.pdfNmax,
  pdfNdop: state.mefa.present.pdfNdop,
  pdfNpr: state.mefa.present.pdfNpr,

  pdfVmax: state.mefa.present.pdfVmax,
  pdfVdop: state.mefa.present.pdfVdop,
  pdfVpr: state.mefa.present.pdfVpr,

  SAFTLY_FACTOR: state.mefa.present.SAFTLY_FACTOR,

  supportReactions: state.mefa.present.supportReactions,

  rotation: state.mefa.present.models[state.mefa.present.selectedModelIndex].rotation,
  selectedOptionDisplacement: state.mefa.present.models[state.mefa.present.selectedModelIndex].displacement,
});

export default connect(mapStateToProps, null)(PdfRaport);

const styles = StyleSheet.create({


  image: {
    textAlign: "center",
    margin: "auto",
    height: "auto",
    width: "51%",

    transform: "scaleX(1.55)"
  },
  imageLogoMefa: {
    width: "26.5%",
    textAlign: "center",
    marginLeft: "350px",
    marginBottom: "3px"

  },
  addressPLView: {},
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",

    borderRightWidth: 0,
    borderBottomWidth: 1
  },
  tableRow: { flexDirection: "row", fontFamily: 'Roboto' },
  tableRow2: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    flexDirection: "row",
    fontFamily: 'Roboto'
  },
  tableRow3: { flexDirection: "row", fontFamily: 'Roboto' },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    fontFamily: 'Roboto',

    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol2: {
    width: "20%",
    borderStyle: "solid",
    fontFamily: 'Roboto',

    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol3: {
    width: "50%",
    borderStyle: "solid",
    fontFamily: 'Roboto',

    borderLeftWidth: 0,
    borderTopWidth: 0
  },

  tableCell2: { marginTop: 5, fontSize: 10, fontFamily: 'Roboto' },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10, fontFamily: 'Roboto' },

  textStyleFont: {
    fontFamily: 'Roboto'
  }
});

class PdfDocument extends React.Component {
  getClass(load) {
    let loadClass;
    if (load instanceof ConcentratedLoadElement) {
      loadClass = translate("PDFloadConcentrated", this.props.lg)
    } else if (load instanceof MomentLoadElement) {
      loadClass = translate("PDFloadMoment", this.props.lg)
    } else if (load instanceof DistributedLoadElement) {
      loadClass = translate("PDFloadContinous", this.props.lg)
    }
    return loadClass;
  }
  getUnit(load) {
    let loadClass;
    if (load instanceof ConcentratedLoadElement) {
      loadClass = "kN";
    } else if (load instanceof MomentLoadElement) {
      loadClass = "kNm";
    } else if (load instanceof DistributedLoadElement) {
      loadClass = "kN/m";
    }
    return loadClass;
  }

  render() {

    return (
      <Document >
        <Page size="A4">
          <View
            style={{
              marginLeft: "55px",
              marginRight: "55px",
              marginTop: "55px"
            }}
          >
            <Image style={styles.imageLogoMefa} src={fotoLogoMefa}></Image>
            {

          <View style={{ display: "table", width: "auto", marginBottom: "40px"}}>
              <View style={{flexDirection: "row"}}>
              <View style={{width:"60%"}}>
                <Text style={{ fontSize: 14, padding: 1,fontWeight: 900,fontFamily: 'RobotoBold' }}>
                 {translate("mefaStaticalheader",this.props.lg)}
                </Text>
                <Text style={{ fontSize: 12, padding: 1, fontFamily: 'Roboto' }}>
                  {translate("mefaStaticalCreated",this.props.lg)}
                </Text>
                </View>
                <View style={{ width:"30%",marginLeft:"58px"}}>
                <Text style={{ fontSize: 11, padding: 1, fontWeight: 900,fontFamily: 'RobotoBold' }}>
                 {translate("companyName",this.props.lg)}
               </Text>
               <Text style={{fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                 {translate("streetName",this.props.lg)}
               </Text>
               <Text style={{fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                 {translate("cityName",this.props.lg)}
               </Text>
               <Text style={{ fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                {translate("websiteName",this.props.lg)}
               </Text>

               <Text style={{fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                 {this.props.lg === "pl" && translate("phoneNumber",this.props.lg)}
               </Text>
               <Text style={{ fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                {this.props.lg === "pl" && translate("companyEmail",this.props.lg)}
               </Text>
                </View>
                
              </View>
            </View>

              
            
             }
             
            <Text
              style={{ fontSize: 11, textAlign: "center", marginLeft: "370px" }}
            >
              {getCurrentDate()}
            </Text>
            <Text

              style={{
                fontFamily: 'Roboto',
                fontSize: 13,
                marginBottom: "20px",
                marginTop: "20px",
                textAlign: "center"
              }}
            >
              {translate("PDFsystemProfileSelectionHeader", this.props.lg)}
            </Text>
          </View>

          <View style={{ marginLeft: "55px", marginRight: "55px" }}>
            <Text style={{ fontSize: 10, paddingBottom: 3, fontFamily: 'Roboto' }}>{translate("PDFagent", this.props.lg) + " " + this.props.agent}</Text>
            <Text style={{ fontSize: 10, paddingBottom: 3, fontFamily: 'Roboto' }}>{translate("PDFobject", this.props.lg) + " " + this.props.object}</Text>
            <Text style={{ fontSize: 10, paddingBottom: 3, fontFamily: 'Roboto' }}>{translate("PDFadvice", this.props.lg) + " " + this.props.advice}</Text>
            <Image style={{ width: "72%", height: "auto", marginLeft: "60px", textAlign: "center", marginBottom: "15px" }} src={this.props.bc} />

            <Text style={{ fontSize: 10, paddingBottom: 3, fontFamily: 'Roboto' }}>
              {translate("PDFnodes", this.props.lg)}

              {this.props.nodes.map((node, index) => {
                return (
                  <Text key={index} style={{ paddingRight: "2px" }}>{"x" + index + "=" + node + "m"} </Text>
                )

              })}
            </Text>
            <Text style={{ fontSize: 10, paddingBottom: "5px", fontFamily: 'Roboto' }}>
              {translate("PDFprofileRailtotalLength", this.props.lg) + " L=" + this.props.totalLength + "m"}
            </Text>

            <Text style={{ fontSize: 10, paddingBottom: 3, fontFamily: 'Roboto' }}>
              {translate("PDFprojectInformation", this.props.lg)}
            </Text>
            <View style={styles.table}>
              <View style={styles.tableRow2}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell2}>{translate("PDFload", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{translate("PDFvalue", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{translate("PDFpositionA", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{translate("PDFpositionB", this.props.lg)}</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell2}>{translate("PDFnetWeight", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{Number((this.props.selectedProfile.weight * 9.81 * 100)).toFixed(2) + "N/m"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>-</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>-</Text>
                </View>
              </View>

              {this.props.loads.map((load, index) => {
                return (
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {this.getClass(load)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {Number(parseFloat(load.force)).toFixed(2) +
                          " " +
                          this.getUnit(load)}{" "}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {Number(load.lengthL1).toFixed(2)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {load.lengthL2 === undefined
                          ? "-"
                          : Number(load.lengthL2).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <Text style={{ fontSize: 10, marginTop: "5px", fontFamily: 'Roboto' }}></Text>
            <View style={styles.table}>
              <View style={styles.tableRow2}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell2}>{translate("PDFprofil", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>Iy [cm4]</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>Wy [cm3]</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>m [N/m]</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>E [N/mm2]</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell2}>
                    {this.props.selectedProfile.name}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {Number(getProfileIx(this.props.selectedProfile, this.props.rotation)).toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {Number(getProfileWx(this.props.globalMaxM, this.props.selectedProfile, this.props.rotation)).toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number((this.props.selectedProfile.weight * 9.81 * 100)).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number((this.props.material * 1000)).toFixed(2)}</Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                paddingBottom: 11,
                marginTop: "15px"
              }}
            >
              {translate("PDFtensionAndBendingProfileRailHeader", this.props.lg)}
            </Text>

            <View style={styles.table}>
              <View style={styles.tableRow2}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell2}> {translate("PDFcondition", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}> {translate("PDFmaximum", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}> {translate("PDFallowed", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}> {translate("PDFutilisation", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}> {translate("PDFstatus", this.props.lg)}</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell2}>{translate("PDFstress", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number(this.props.pdfNmax).toFixed(2) + "N/mm2"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number(this.props.pdfNdop).toFixed(2) + "N/mm2"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number(this.props.pdfNpr).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{this.props.pdfNpr > 100 ? translate("PDFconditionNotFulfilled", this.props.lg) : translate("PDFconditionFulfilled", this.props.lg)}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell2}>{translate("PDFdeflections", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number(this.props.pdfVmax).toFixed(2) + "mm"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number(this.props.pdfVdop).toFixed(2) + "mm " + getDispl(this.props.selectedOptionDisplacement)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{Number(this.props.pdfVpr).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{this.props.pdfVpr > 100 ? translate("PDFconditionNotFulfilled", this.props.lg) : translate("PDFconditionFulfilled", this.props.lg)}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>

        <Page size="A4">
          <View
            style={{
              marginLeft: "55px",
              marginRight: "55px",
              marginTop: "55px"
            }}
          >
            <Image style={styles.imageLogoMefa} src={fotoLogoMefa}></Image>
            <View style={{ display: "table", width: "auto", marginBottom: "20px"}}>
              <View style={{flexDirection: "row"}}>
              <View style={{width:"60%"}}>
                <Text style={{ fontSize: 14, padding: 1,fontWeight: 900,fontFamily: 'RobotoBold' }}>
                 {translate("mefaStaticalheader",this.props.lg)}
                </Text>
                <Text style={{ fontSize: 12, padding: 1, fontFamily: 'Roboto' }}>
                  {translate("mefaStaticalCreated",this.props.lg)}
                </Text>
                </View>
                <View style={{ width:"30%",marginLeft:"58px"}}>
                <Text style={{ fontSize: 11, padding: 1, fontWeight: 900,fontFamily: 'RobotoBold' }}>
                 {translate("companyName",this.props.lg)}
               </Text>
               <Text style={{fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                 {translate("streetName",this.props.lg)}
               </Text>
               <Text style={{fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                 {translate("cityName",this.props.lg)}
               </Text>
               <Text style={{ fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                {translate("websiteName",this.props.lg)}
               </Text>

               <Text style={{fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                 {this.props.lg === "pl" && translate("phoneNumber",this.props.lg)}
               </Text>
               <Text style={{ fontSize: 10, padding: 1, fontFamily: 'Roboto' }}>
                {this.props.lg === "pl" && translate("companyEmail",this.props.lg)}
               </Text>

                </View>
                
              </View>
            </View>
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                paddingBottom: 11,
                marginTop: "15px"
              }}
            >
              {translate("PDFbendingMomentChartAndBendingChartHeader", this.props.lg)}
            </Text>

            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                paddingBottom: 13,
                marginTop: "23px",
                textAlign: "center"
              }}
            >
              {translate("PDFloadPlan", this.props.lg)}
            </Text>
            <Image style={{ width: "72%", height: "auto", marginLeft: "65px", textAlign: "center", }} src={this.props.bc} />

            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                paddingBottom: 13,
                marginTop: "23px",
                textAlign: "center"
              }}
            >
              {translate("PDFbendingMomentChart", this.props.lg)}
            </Text>
            <Image style={styles.image} src={this.props.bmc} />
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                paddingBottom: 13,
                marginTop: "23px",
                textAlign: "center"
              }}
            >
              {translate("PDFbendingChart", this.props.lg)}
            </Text>
            <Image style={styles.image} src={this.props.dc} />

            <Text style={{ fontSize: 10, marginTop: "5px", fontFamily: 'Roboto' }}></Text>

            <View style={styles.table}>
              <View style={styles.tableRow2}>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell2}> {translate("PDFnodeReactiveForce", this.props.lg)}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell2}> {translate("PDFreaction", this.props.lg)}</Text>
                </View>
              </View>

              {this.props.supportsTab.map((support, index) => {
                return (
                  <View key={index} style={styles.tableRow3}>
                    <View style={styles.tableCol3}>
                      <Text style={styles.tableCell2}>
                        {index}
                      </Text>
                    </View>
                    <View style={styles.tableCol3}>
                      <Text style={styles.tableCell2}>
                        {Number(parseFloat(support)).toFixed(2) + "kN"}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>

           { this.props.lg !== "it" &&
            <View style={{ marginTop: "15px", marginRight: "40px" }}>
              <Text style={{ fontSize: 12, marginTop: "5px", fontFamily: 'Roboto' }}>{translate("PDFadvice2", this.props.lg)}</Text>
              <Text style={{ fontSize: 10, marginTop: "5px", fontFamily: 'Roboto' }}>
                {translate("PDFsafetyFactorInfo", this.props.lg) + this.props.SAFTLY_FACTOR + translate("PDFsafetyFactorInfoEnd", this.props.lg)}
              </Text>

              <Text style={{ fontSize: 10, marginTop: "5px", fontFamily: 'Roboto' }}>
                {translate("PDFsummary", this.props.lg)}
              </Text>
             
            </View>
          }
          </View>
        </Page>
        { this.props.lg === "de" &&
          <Page size="A4">
            <View
              style={{
                marginLeft: "55px",
                marginRight: "55px",
                marginTop: "55px",
              }}
            >
            
          <Text style={{ fontSize: 10, marginTop: "15px", fontFamily: 'Roboto' }}>
                  {this.props.lg === "de" && translate("PDFgarantieHeader", this.props.lg)}
                </Text>

                <Text style={{ fontSize: 10, marginTop: "15px", fontFamily: 'Roboto' }}>
                  {this.props.lg === "de" && translate("PDFkeineGarantieHeader", this.props.lg)}
                </Text>

                <Text style={{ fontSize: 10, marginTop: "5px", fontFamily: 'Roboto' }}>
                  {this.props.lg === "de" && translate("PDFkeineGarantieText", this.props.lg)}
                </Text>

                <Text style={{ fontSize: 10, marginTop: "15px", fontFamily: 'Roboto' }}>
                  {this.props.lg === "de" && translate("PDFkeineHauftungHeader", this.props.lg)}
                </Text>

                <Text style={{ fontSize: 10, marginTop: "5px", fontFamily: 'Roboto' }}>
                  {this.props.lg === "de" && translate("PDFkeineHauftungText", this.props.lg)}
                </Text>
              </View>
          </Page>
        }
        { this.props.lg === "it" &&
          <Page size="A4">
            <View
              style={{
                marginLeft: "55px",
                marginRight: "55px",
                marginTop: "55px",
                lineHeight: 1.55,
                fontSize: 11,
                fontFamily: 'Roboto'
              }}
            >
            <Text style={{  fontSize:15, marginTop: "5px"}}>{translate("PDFadvice2", this.props.lg)}</Text>
            <Text style={{  marginTop: "5px" }}>
                {translate("PDFsafetyFactorInfo", this.props.lg) }
              </Text>

              <Text style={{  marginTop: "7px", marginLeft: "0"}}>
                {translate("PDFsummary", this.props.lg)}
              </Text>
              <View style={{marginLeft: "15px", marginTop: "7px"}}>
              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{ marginLeft: "10px"}}>
                {translate("PDFsummaryp1", this.props.lg)}
                </Text>
              </View>

              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{  marginLeft: "10px"}}>
                {translate("PDFsummaryp2", this.props.lg)}
                </Text>
              </View>

              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{  marginLeft: "10px"}}>
                {translate("PDFsummaryp3", this.props.lg)}
                </Text>
              </View>

              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{  marginLeft: "10px"}}>
                {translate("PDFsummaryp4", this.props.lg)}
                </Text>
              </View>

              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{  marginLeft: "10px"}}>
                {translate("PDFsummaryp5", this.props.lg)}
                </Text>
              </View>

              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{  marginLeft: "10px"}}>
                {translate("PDFsummaryp6", this.props.lg)}
                </Text>
              </View>

              <View style={{flexDirection: "row", marginTop: "7px"}}>
                <Text style={{ marginRight: "5px"}}>{"-"}</Text>
                <Text style={{  marginLeft: "10px"}}>
                {translate("PDFsummaryp7", this.props.lg)}
                </Text>
              </View>

              


              </View>

              <Text style={{  marginTop: "25px" }}>
                {translate("PDFsafetyFactorInfoEnd", this.props.lg) }
              </Text>

              </View>
          </Page>
        }
      </Document>
    );
  }
}
