import React from "react";
import './Beam.css';
import * as THREE from "three";
import mefaActions from "../../../app/mefa-app/duck/actions";
import { connect } from "react-redux";
import fontHelvetikerBold from "../../fonts/helvetiker_bold_typeface.json";
import fontHelvetiker from "../../fonts/helvetiker_typeface.json";
import translateText from '../../LanguageTranslator';

import SectionElement from "../../../app/classes/element/SectionElement";
import FixedSupportElement from "../../../app/classes/support/FixedSupportElement";
import SupportElement from "../../../app/classes/support/SupportElement";
import NoneSupportElement from "../../../app/classes/support/NoneSupportElement";
import NodeElement from "../../../app/classes/node/NodeElement";
import LoadElement from "../../../app/classes/load/LoadElement";
import ConcentratedLoadElement from "../../../app/classes/load/ConcentratedLoadElement";
import MomentLoadElement from "../../../app/classes/load/MomentLoadElement";
import DistributedLoadElement from "../../../app/classes/load/DistributedLoadElement";

var raycaster = new THREE.Raycaster();
raycaster.linePrecision = 0.05;

var mouse = new THREE.Vector2();
let mouseBlock=false;
let globalBeamObject=[];

const loader = new THREE.FontLoader();
const font = loader.parse(fontHelvetiker);
const fontBold = loader.parse(fontHelvetikerBold);
let height = 190
let tabb=[]
let setMouseUpFlag=true;
let globalMode= false;
const textPrecision = 2;
const textSize = 0.071;
const textHeight = 0.0006;
let globalScale = 5.6
let frustumSize = 1.5;
let init=0;

function getPrecision(elementLength, pointsAmount){

  if((elementLength <= 0.1) && pointsAmount > 1){
    return 3;
  }
  else{
    return 2;
  }

}

class Beam extends React.Component {


  constructor (props) {
    super(props);
    let map = {};
    map[this.props.currentStateModel.id] = [];

    this.state = {    beamScale: globalScale,
      sumOfElement: 777,
      elementGlComponents: map,
      currentSupportReactions: [],
      currentBeamElements: [],
      currentBeamLoads: [],
      currentBeamSupports: [],
      currentBeamNodes: [],
      currentProfileID: 666
    };
  }




  compareNumbers(a, b)
  { return a - b;}

  compareNumbers2(a, b)
  { return b - a;}


  
  setMouseVector=(event)=> {
    if(!mouseBlock){
    event.preventDefault();

    if(setMouseUpFlag){

    if(this.props.selectedModelElement !== null){
    this.props.changeModelElement(null);
    }
    
  }
  setMouseUpFlag=true;
    mouse.x = (event.offsetX / this.mount.clientWidth) * 2 - 1;
    mouse.y = -(event.offsetY / 180) * 2 + 1;
  
}
  }

  setMouseDown=(e)=>{
    if(!this.props.globalMassLoad){
      this.setMouseVector(e)
    }
    
    if(!mouseBlock){
    e.preventDefault();
    clearTimeout(this.downTimer);
    this.downTimer = setTimeout(()=> {
      if(this.props.selectedModelElement instanceof LoadElement){
        globalMode=true;
        setMouseUpFlag=false;
        this.props.changeGlobalMode(true);
      }  
    }, 120);
   
    }
  
  }

  setMouseUp=(e)=>{
   if(!mouseBlock){
    e.preventDefault();
    globalMode=false;
    
    if(this.props.GLOBAL_MODE){
     this.props.changeGlobalMode(false);
   }

    clearTimeout(this.downTimer);
   }
  
  }



  setMouseMove=(event)=>{
    if(globalMode){
    event.preventDefault();
    mouse.x = (event.offsetX / this.mount.clientWidth) * 2 - 1;
    mouse.y = -(event.offsetY / 180) * 2 + 1;
    }
  
  }

  setTouchMove=(event)=>{
    if(globalMode){
      event.preventDefault();
      let rect = event.target.getBoundingClientRect();
      let x = event.targetTouches[0].pageX - rect.left;
      let y = event.targetTouches[0].pageY - rect.top;
      mouse.x = (x/ this.mount.clientWidth) * 2 - 1;
      mouse.y = -(y / 180) * 2 + 1;
      }

      if(this.props.GLOBAL_MODE !== true){
        clearTimeout(this.downTimer);
        this.downTimer = setTimeout(()=> {
          if(this.props.selectedModelElement instanceof LoadElement){
            globalMode=true;
            setMouseUpFlag=false;
            this.props.changeGlobalMode(true);
          }  
        }, 50);
      }
  
  }

  setTouchEnd=(e)=>{
    if(!mouseBlock){
      //e.preventDefault();
      globalMode=false;
      
      if(this.props.GLOBAL_MODE){
       this.props.changeGlobalMode(false);
     }
  
      clearTimeout(this.downTimer);
     }

  }
  translate = (text) => {
    return (translateText(text, this.props.selectedLanguage));
}
  create_UUID=()=>{
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        // eslint-disable-next-line no-mixed-operators
        return (c ==='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}
calLoadsPos(sectionElementId){
  
  let loadLengthPosition = [];
  let allLoads = this.props.beamLoads.filter(load => load.parentID === sectionElementId)
 // loadLengthPosition.push(0)
 // loadLengthPosition.push(sectionElement.lengthL)
  allLoads.forEach(load => {
      loadLengthPosition.push(load.lengthL1)
  })
  let uniqLoadPoints = [...new Set(loadLengthPosition)];
  uniqLoadPoints.sort(this.compareNumbers2);

  let posOn=Number(0.5);
  uniqLoadPoints.forEach(load => {
    if(load === posOn){
      posOn=(Number(posOn).toFixed(2))/2;
    }
  })

return posOn;
}
  copyLoad=()=>{
    let pos= this.calLoadsPos(this.props.selectedModelElement.parentID)
    
    if(this.props.selectedModelElement instanceof ConcentratedLoadElement){

      let concentratedLoadElement = new ConcentratedLoadElement(this.create_UUID(), 
      this.translate("loadChild")+" "+ (this.props.beamLoads.length + 1), this.props.selectedModelElement.force, pos,this.props.selectedModelElement.parentID)
  
      this.props.addLoadElement(concentratedLoadElement)
  }
  else if(this.props.selectedModelElement instanceof MomentLoadElement){
            
    let momentLoadElement = new MomentLoadElement(this.create_UUID(),
    this.translate("loadChild")+" "+ (this.props.beamLoads.length + 1), this.props.selectedModelElement.force, pos,this.props.selectedModelElement.parentID)

    this.props.addLoadElement(momentLoadElement)
}

  else if(this.props.selectedModelElement instanceof DistributedLoadElement){
            
        let distributedLoadElement = new DistributedLoadElement(this.create_UUID(),
        this.translate("loadChild")+" "+ (this.props.beamLoads.length + 1), this.props.selectedModelElement.force, 0,1,this.props.selectedModelElement.parentID)
    
        this.props.addLoadElement(distributedLoadElement)
    }
  }

  removeLoad=()=>{
    if(this.props.selectedModelElement instanceof LoadElement){
      this.props.deleteLoadElement(this.props.selectedModelElement)
  }
  }

  changeDirectionLeft=()=>{
    const rightButton = document.getElementsByClassName('RightButton');
    const leftButton = document.getElementsByClassName('LeftButton');

    rightButton[0].style.background="white";
    leftButton[0].style.background="rgb(255, 225, 136)";
  }
  changeDirectionRight=()=>{
    const rightButton = document.getElementsByClassName('RightButton');
    const leftButton = document.getElementsByClassName('LeftButton');

    rightButton[0].style.background="rgb(255, 225, 136)";
    leftButton[0].style.background="white";
  }
  mouseBlockTrue=()=>{
    mouseBlock=true;
  }

  mouseBlockFalse=()=>{
    mouseBlock=false;
  }

  resize=()=>{
    
    if(this.mount && this.mount.clientWidth !== null){
      let width = this.mount.clientWidth-1;
     
   
      let sum=0;
      this.props.beamElements.forEach(element => {
        sum=sum+element.lengthL;
      });

      globalScale =width/150;
      this.setState({
        beamScale: globalScale*(1/sum)
      })
    
      let aspect = width / height;
      this.renderer.setSize(width, height);
      this.camera.left = ((frustumSize * aspect) / -2) + globalScale/2;
      this.camera.right = ((frustumSize * aspect) / 2) + globalScale/2;
      this.camera.top = frustumSize / 2;
      this.camera.bottom = frustumSize / -2;
      
      this.camera.updateProjectionMatrix();
    }
  }

  countBeamLength=()=>{
    let beamLength=0;

    this.props.beamElements.forEach(element => beamLength = beamLength + element.lengthL);

    return beamLength;
  }
drawSectionLine(sectionLengthGeometry,indexSection,indexNextSection,sectionLengthPositionY,sectionLengthFence){
    sectionLengthGeometry.vertices.push(
      new THREE.Vector3(indexSection, sectionLengthPositionY, 0),
      new THREE.Vector3(indexSection ,(sectionLengthPositionY + sectionLengthFence), 0),
      new THREE.Vector3(indexSection, sectionLengthPositionY, 0),
      new THREE.Vector3(indexSection,(sectionLengthPositionY - sectionLengthFence), 0),
  
      new THREE.Vector3(indexSection, sectionLengthPositionY, 0),
      new THREE.Vector3(indexNextSection, sectionLengthPositionY, 0),
    
      new THREE.Vector3(indexNextSection, sectionLengthPositionY, 0),
      new THREE.Vector3(indexNextSection ,(sectionLengthPositionY + sectionLengthFence), 0),
      new THREE.Vector3(indexNextSection, sectionLengthPositionY, 0),
      new THREE.Vector3(indexNextSection,(sectionLengthPositionY - sectionLengthFence), 0),
    );
}
drawLengthSectionLine(index,sectionLengthGeometry,indexSection,indexNextSection,sectionLengthPositionY,sectionLengthFence){
  
  if(indexSection === 0){
    sectionLengthGeometry.vertices.push(
    new THREE.Vector3(indexSection, sectionLengthPositionY, 0),
    new THREE.Vector3(indexSection ,(sectionLengthPositionY + sectionLengthFence), 0),
    new THREE.Vector3(indexSection, sectionLengthPositionY, 0),
    new THREE.Vector3(indexSection,(sectionLengthPositionY - sectionLengthFence), 0)
    )
  }

  sectionLengthGeometry.vertices.push(
    new THREE.Vector3(indexSection, sectionLengthPositionY, 0),
    new THREE.Vector3(indexNextSection, sectionLengthPositionY, 0),
  );

  if(index === (this.props.beamElements.length-1)){
    sectionLengthGeometry.vertices.push(
    new THREE.Vector3(indexNextSection, sectionLengthPositionY, 0),
    new THREE.Vector3(indexNextSection ,(sectionLengthPositionY + sectionLengthFence), 0),
    new THREE.Vector3(indexNextSection, sectionLengthPositionY, 0),
    new THREE.Vector3(indexNextSection,(sectionLengthPositionY - sectionLengthFence), 0)
    )
  }
  
  
}

drawElements(ind, element,index){

  let materialSection = new THREE.LineBasicMaterial({color: "black"});
  let materialSectionLoad = new THREE.LineBasicMaterial({ color: "black" });
  let materialSectionLength = new THREE.LineBasicMaterial({ color: "black" });
  let materialBeam = new THREE.MeshBasicMaterial({color: "red"});
 
  let textMaterial = new THREE.MeshBasicMaterial({ color: "black" });
  let sectionGeometry = new THREE.Geometry();

  const indexSection = index*this.state.beamScale;
  const indexNextSection = (index + element.lengthL)*this.state.beamScale;
  const LineSectionSize = 0.009;

  sectionGeometry.vertices.push(
    new THREE.Vector3(indexSection, 0, 0),
    new THREE.Vector3(indexNextSection, 0, 0),
    new THREE.Vector3(indexSection, LineSectionSize, 0),
    new THREE.Vector3(indexNextSection, LineSectionSize, 0)
  );


  let sectionLoadGeometry = new THREE.Geometry();

  const sectionLoadPositionY = -0.3;
  const sectionLoadFence = 0.05;

  this.drawSectionLine(sectionLoadGeometry,indexSection,indexNextSection,sectionLoadPositionY,sectionLoadFence);


  let sectionLengthGeometry = new THREE.Geometry();

  const sectionLengthPositionY = -0.5;
  const sectionLengthFence = 0.05;


  this.drawSectionLine(sectionLengthGeometry,indexSection,indexNextSection,sectionLengthPositionY,sectionLengthFence);

  
  let beamLengthGeometry = new THREE.Geometry();
  
  const beamLengthPositionY = -0.7;
  const beamLengthFence = 0.05;

  this.drawLengthSectionLine(ind,beamLengthGeometry,indexSection,indexNextSection,beamLengthPositionY,beamLengthFence);

  let textGeometry = new THREE.TextGeometry(
    Number(element.lengthL).toFixed(2),
    {
      font: font,
      size: textSize,
      height: textHeight
    }
  );

 let textMesh = new THREE.Mesh(textGeometry, textMaterial);
 textMesh.position.set((indexSection+indexNextSection)/2 -0.1, -0.46, 0);
 textMesh.geometry.name = element;




if((this.props.beamElements.length-1) === ind){

    let geometryBox = new THREE.BoxGeometry( 50, 2, -2 );
    let materialBox = new THREE.MeshBasicMaterial( {color: "white"} );
    let cubeBackground = new THREE.Mesh( geometryBox, materialBox );
    this.scene.add( cubeBackground );


    let textMaterialBeam = new THREE.MeshBasicMaterial({ color: "red" });
    let textGeometry2 = new THREE.TextGeometry(
      Number(this.countBeamLength()).toFixed(2),
      {
        font: font,
        size: textSize,
        height: textHeight,
        
      }
    );

    let textMesh2 = new THREE.Mesh(textGeometry2, textMaterialBeam);
    textMesh2.position.set(((this.countBeamLength()/2)-(0.1/this.state.beamScale))*this.state.beamScale, -0.66, 0);
    this.scene.add(textMesh2);
  }
 


  let beamLength = new THREE.Line(beamLengthGeometry, materialBeam);
  beamLength.geometry.name = element;

  let sectionLength = new THREE.Line(sectionLengthGeometry, materialSectionLength);
  sectionLength.geometry.name = element;

  let sectionLoad = new THREE.Line(sectionLoadGeometry, materialSectionLoad);
  sectionLoad.geometry.name = element;

  let section = new THREE.Line(sectionGeometry, materialSection);
  section.geometry.name = element;

  
  if(this.props.selectedModelElement && this.props.selectedModelElement.id === element.id){
    section.material.color.set("red");
    sectionLoad.material.color.set("red");
    sectionLength.material.color.set("red");
    textMesh.material.color.set("red");
  }

  this.scene.add(section);
  this.scene.add(sectionLoad);
  this.scene.add(sectionLength);
  this.scene.add(textMesh);

  this.scene.add(beamLength);

  globalBeamObject.push(section)
  globalBeamObject.push(sectionLoad)
  globalBeamObject.push(sectionLength)
  globalBeamObject.push(textMesh)
  
}



drawELPosition(points, tablica, element, index){

  const indexSection = index*this.state.beamScale;
 
  const sectionLoadPositionY = -0.3;
  let offsetPoint=0;
  let precision = getPrecision(element.lengthL, points.length);
  points.forEach((point,i )=> {

  let textGeometry = new THREE.TextGeometry(
    Number(point).toFixed(precision),
    {
      font: font,
      size: textSize,
      height: textHeight
    }
  );
  offsetPoint =((tablica[i]+tablica[i+1])*this.state.beamScale);
  let textMaterial1 = new THREE.MeshBasicMaterial({ color: "black" });
  let textMesh = new THREE.Mesh(textGeometry, textMaterial1);
  textMesh.name="textMeshELPosition"
  textMesh.position.set((((indexSection)+((offsetPoint)/2))) -0.1, sectionLoadPositionY+0.05, 0);
  
  textMesh.geometry.name = element;
  this.scene.add(textMesh);
  globalBeamObject.push(textMesh)
 }) 
}

drawLoads(load, index, element){
  if(element.id === load.parentID){
    let materialLoad = new THREE.MeshBasicMaterial({color: "blue"});
    let materialLoadSection = new THREE.MeshBasicMaterial({color: "black"});
    let textMaterialLoad = new THREE.MeshBasicMaterial({ color: "blue" });
    
    let textGeometry = new THREE.TextGeometry(
      Number(load.force).toFixed(textPrecision),
      {
        font: font,
        size: textSize,
        height: textHeight
      }
    );

   let textMesh = new THREE.Mesh(textGeometry, textMaterialLoad);
   textMesh.name=load.id+"loadTextMesh";
   textMesh.geometry.name = load;

   let loadGeometry = new THREE.Geometry();
   var sectionLoadGeometry = new THREE.Geometry();
   var sectionLoadGeometry2 = new THREE.Geometry();

   let indexLoadLength = ((load.lengthL1*element.lengthL)+index)*this.state.beamScale;

 
   if(load instanceof ConcentratedLoadElement) {
    loadGeometry.vertices.push(
      new THREE.Vector3(indexLoadLength, 0.25, 0),
      new THREE.Vector3(indexLoadLength, 0, 0),
      new THREE.Vector3(indexLoadLength+ 0.05, 0.1, 0),
      new THREE.Vector3(indexLoadLength, 0, 0),
      new THREE.Vector3(indexLoadLength- 0.05, 0.1, 0)
    );
    textMesh.position.set(indexLoadLength- 0.08, 0.28, 0);

  }else if(load instanceof MomentLoadElement) {
    loadGeometry.vertices.push(
      new THREE.Vector3(indexLoadLength + 0.07, 0.1, 0),
      new THREE.Vector3(indexLoadLength + 0.02, 0.07, 0),
      new THREE.Vector3(indexLoadLength + 0.07, 0.1, 0),
      new THREE.Vector3(indexLoadLength + 0.02, 0.13, 0),

      new THREE.Vector3(indexLoadLength + 0.07, 0.1, 0),
      new THREE.Vector3(indexLoadLength, 0.1, 0),

      new THREE.Vector3(indexLoadLength, 0.1, 0),
      new THREE.Vector3(indexLoadLength, -0.1, 0),

      new THREE.Vector3(indexLoadLength - 0.07, -0.1, 0),
      new THREE.Vector3(indexLoadLength - 0.02, -0.07, 0),

      new THREE.Vector3(indexLoadLength - 0.07, -0.1, 0),
      new THREE.Vector3(indexLoadLength - 0.02, -0.13, 0)
    );
    textMesh.position.set(indexLoadLength - 0.05, 0.15, 0);

  }else if(load instanceof DistributedLoadElement){
    let indexLoadLength2 = (index + (load.lengthL2*element.lengthL))*this.state.beamScale;
    loadGeometry.vertices.push(
      new THREE.Vector3(indexLoadLength, 0, 0),
      new THREE.Vector3(indexLoadLength, 0.12, 0),
      new THREE.Vector3(indexLoadLength2, 0.12, 0),
      new THREE.Vector3(indexLoadLength2, 0, 0)
    );
    textMesh.position.set(indexLoadLength - 0.05, 0.15, 0);

    sectionLoadGeometry2.vertices.push(
      new THREE.Vector3(indexLoadLength2, -0.30, 0),
      new THREE.Vector3(indexLoadLength2 ,-0.25, 0),
      new THREE.Vector3(indexLoadLength2, -0.30, 0),
      new THREE.Vector3(indexLoadLength2,-0.35, 0),
      );

      let sectionLoad2 = new THREE.Line(sectionLoadGeometry2, materialLoadSection);
      sectionLoad2.name=load.id+"sectionLoad2";
      sectionLoad2.geometry.name = element;
      this.scene.add(sectionLoad2);
      globalBeamObject.push(sectionLoad2)

      if(this.props.selectedModelElement && this.props.selectedModelElement.id === element.id){
        sectionLoad2.material.color.set("red");
      }
  }

      sectionLoadGeometry.vertices.push(
      new THREE.Vector3(indexLoadLength, -0.30, 0),
      new THREE.Vector3(indexLoadLength ,-0.25, 0),
      new THREE.Vector3(indexLoadLength, -0.30, 0),
      new THREE.Vector3(indexLoadLength,-0.35, 0),
      );

      let sectionLoad = new THREE.Line(sectionLoadGeometry, materialLoadSection);
      sectionLoad.name=load.id+"sectionLoad";
      sectionLoad.geometry.name = element;

      

      let geometryLoad = new THREE.Line(loadGeometry, materialLoad);
      geometryLoad.name=load.id+"load";
      geometryLoad.geometry.name = load;


      if(this.props.selectedModelElement && this.props.selectedModelElement.id === load.id){
        geometryLoad.material.color.set("red");
        textMesh.material.color.set("red");
      }
      if(this.props.selectedModelElement && this.props.selectedModelElement.id === element.id){
        sectionLoad.material.color.set("red");
      }

      this.scene.add(geometryLoad);
      this.scene.add(textMesh);
      this.scene.add(sectionLoad);
    

      globalBeamObject.push(geometryLoad)
      globalBeamObject.push(textMesh)
      globalBeamObject.push(sectionLoad)
      

  
  }

}

drawSupports=(element, index,indx)=>{
  
  
  let textMaterialSupport = new THREE.MeshBasicMaterial({ color: "red" });
  let redArrowMaterialSupport = new THREE.MeshBasicMaterial({ color: "red" });
  let redArrowGeometry = new THREE.Geometry();

  let indexSupport=index*this.state.beamScale;

if(!globalMode){
  let supportGeometry = new THREE.Geometry();
  let supportMaterial = new THREE.MeshBasicMaterial({color: "blue"});
  
  if(element instanceof FixedSupportElement){
    
    supportGeometry = new THREE.PlaneGeometry(0.12, 0.12, 0.05);

  }else if(element instanceof NoneSupportElement){
    supportGeometry = new THREE.PlaneGeometry(0.001, 0.001, 0.0);

  }else if(element instanceof SupportElement){
    supportGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
    supportGeometry.vertices.push(new THREE.Vector3(-0.09, -0.09, 0));
    supportGeometry.vertices.push(new THREE.Vector3(0.09, -0.09, 0));
    supportGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
    supportGeometry.faces.push(new THREE.Face3(0, 1, 2));
  }
  
    

  let supportMesh = new THREE.Mesh(supportGeometry, supportMaterial);
  supportMesh.name="saportsBl"
  supportMesh.geometry.name = element;

  supportMesh.position.set(indexSupport, 0, 0);

  if(this.props.selectedModelElement && this.props.selectedModelElement.id === element.id){
    supportMesh.material.color.set("red");
  }
  this.scene.add(supportMesh);

  globalBeamObject.push(supportMesh)

}

 

  

 let arrowPosition=0;
  
if(this.props.supportReactions[indx] < 0){
  redArrowGeometry.vertices.push(
    new THREE.Vector3(indexSupport, 0.14, 0),
    new THREE.Vector3(indexSupport, 0, 0),
    new THREE.Vector3(indexSupport- 0.03, 0.07, 0),
    new THREE.Vector3(indexSupport, 0, 0),
    new THREE.Vector3(indexSupport+ 0.03, 0.07, 0)
  );
  arrowPosition = -0.93;
}else{
 redArrowGeometry.vertices.push(
    new THREE.Vector3(indexSupport, -0.14, 0),
    new THREE.Vector3(indexSupport, 0, 0),
    new THREE.Vector3(indexSupport- 0.03, -0.07, 0),
    new THREE.Vector3(indexSupport, 0, 0),
    new THREE.Vector3(indexSupport+ 0.03, -0.07, 0)
  );
  arrowPosition = -0.8;
}
  
  let arrowMesh = new THREE.Line(redArrowGeometry, redArrowMaterialSupport);
  arrowMesh.name="saports"
  arrowMesh.position.set(0, arrowPosition, 0);
  
  this.scene.add(arrowMesh);

  let textGeometry = new THREE.TextGeometry(
    Number(this.props.supportReactions[indx]).toFixed(2),
    {
      font: font,
      size: textSize,
      height: textHeight
    }
  );

 let textMesh = new THREE.Mesh(textGeometry, textMaterialSupport);
 textMesh.name="saports"
 textMesh.position.set(indexSupport-0.06, -1.04, 0);
 this.scene.add(textMesh);

 
  
  
}

drawNodes=(index, element, offset)=>{
  let materialNode = new THREE.MeshBasicMaterial({ color: "black" });
  let textMaterialNode = new THREE.MeshBasicMaterial({ color: "black" });
  let geometryNode = new THREE.SphereGeometry(0.035, 22, 22);

    let nodeGeometry= new THREE.Mesh(geometryNode, materialNode);
    nodeGeometry.geometry.name = element;
    
    let indexNode= offset*this.state.beamScale;

    nodeGeometry.position.set(indexNode, 0, 0);
    this.scene.add(nodeGeometry);

    let textGeometry = new THREE.TextGeometry(
      Number(index).toFixed(0),
      {
        font: fontBold,
        size: textSize,
        height: textHeight,
      }
    );
  
   let textMesh = new THREE.Mesh(textGeometry, textMaterialNode);
   textMesh.geometry.name = element;
   textMesh.position.set(indexNode-0.03, -0.19, 0);

   if(this.props.selectedModelElement && this.props.selectedModelElement.id === element.id) {
    textMesh.material.color.set("red");
    nodeGeometry.material.color.set("red");
  }

   this.scene.add(textMesh);

   globalBeamObject.push(textMesh)
   globalBeamObject.push(nodeGeometry)
   

}
  componentDidMount(){
    let width = this.mount.clientWidth-1;
    

    this.scene = new THREE.Scene();

   
    let aspect = width / height;

    this.camera = new THREE.OrthographicCamera(
      ((frustumSize * aspect) / -2) + 2.8,
      ((frustumSize * aspect) / 2) + 2.8,
      frustumSize / 2,
      frustumSize / -2,
      0.01,
      100
    );

    this.scene.add(this.camera);
    this.camera.position.copy(new THREE.Vector3(0, -0.7, 1));

    this.camera.lookAt(new THREE.Vector3(0, 0, -0.5));
    this.renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true});

    this.renderer.setClearColor("#FFFFFF");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);
    this.mount.addEventListener("click", this.setMouseVector);
    this.mount.addEventListener('mousedown', this.setMouseDown);
    this.mount.addEventListener('mouseup', this.setMouseUp);
    this.mount.addEventListener('mousemove', this.setMouseMove);

    this.mount.addEventListener('touchmove', this.setTouchMove);
    this.mount.addEventListener('touchend', this.setTouchEnd);
 
    window.addEventListener("resize",this.resize);

    
    const menu = document.querySelector(".menu");

    let menuVisible = false;

    const toggleMenu = command => {
      menu.style.display = command === "show" ? "block" : "none";
      menuVisible = !menuVisible;
    };

    const setPosition = ({ top, left }) => {
      menu.style.left = `${left}px`;
      menu.style.top = `${top}px`;
      toggleMenu("show");
    };

    window.addEventListener("click", e => {
      if(!mouseBlock){
        if (menuVisible) toggleMenu("hide");
        }
    });

   

    this.mount.addEventListener("contextmenu", e => {
      if(!mouseBlock){
      e.preventDefault();
      if(this.props.selectedModelElement instanceof LoadElement){
    
      const origin = {
        left: e.pageX,
        top: e.pageY
      };
      setPosition(origin);
      return false;}
    }
      
    });

        
    this.renderScene()
    this.resize();
  }

  renderScene=()=>{
    
    let animate = () => {
     
      requestAnimationFrame(animate);
      if(!this.props.globalMassLoad){
    if(!globalMode){
      raycaster.setFromCamera(mouse, this.camera);
      let intersects = raycaster.intersectObjects(this.scene.children,true);
      for (let i = 0; i < intersects.length; i++) {
        if(this.props.selectedModelElement === null){
          this.props.changeModelElement(intersects[0].object.geometry.name);
        }
        break;
      }
    }else{
      if(this.props.selectedModelElement instanceof LoadElement && globalMode){
        raycaster.setFromCamera(mouse, this.camera);
        let intersects = raycaster.intersectObjects(this.scene.children,true);
      
       let endMov=0;
        for (let i = 0; i < intersects.length; i++) {
         endMov=Number(intersects[0].point.x/this.state.beamScale).toFixed(4)
        }
       
        if( endMov !== 0 ){
            this.tk(endMov)
        }
      }
    }}


    else{
      raycaster.setFromCamera(mouse, this.camera);
      let intersects = raycaster.intersectObjects(this.scene.children,true);
      for (let i = 0; i < intersects.length; i++) {
        if(intersects[0].object.geometry.name instanceof LoadElement){
          if(tabb.includes(intersects[0].object.geometry.name))
          // eslint-disable-next-line array-callback-return
          {let tabTemp = tabb.filter(el=>{
            if(el !== intersects[0].object.geometry.name)
            {return el;}
          })
          tabb.length=0;
          tabb=[...tabTemp]
        }
          else{
        //   if(this.props.selectedModelElement !== null){
        //     console.log(this.props.selectedModelElement)
        //  //=[...this.props.selectedModelElement]
        //   }
          tabb.push(intersects[0].object.geometry.name)
        }
         
          //console.log(tabb)
          this.props.changeModelElement(tabb);
        }
        break;
      }
    }
     mouse.x=10;
     mouse.y=10;
    this.renderer.render(this.scene, this.camera);
    
    };
    animate();
  }
  tk(mov){
    let beamLength=parseFloat(0);

    this.props.beamElements.forEach(element=> {
      beamLength = beamLength + element.lengthL;
    })

    let startBeam=parseFloat(0);
    let endBeam =parseFloat(beamLength);


    let beamLength2=parseFloat(0);

    let beamMapIds=[];


    this.props.beamElements.forEach((element,index)=> {
      let beamScope=[];
      let startElement=parseFloat(0);
      let endElement=parseFloat(0);
      
      if(index > 0){
        beamLength2 = beamLength2 + this.props.beamElements[index-1].lengthL
      }
       
      beamScope.push(element);

      if(index > 0){
        startElement=beamLength2;
        endElement=beamLength2+element.lengthL;
      }else{
        startElement=parseFloat(0);
        endElement=element.lengthL;
      }
      beamScope.push(startElement);
      beamScope.push(endElement);

      beamMapIds.push(beamScope);
     
    })

    //let start=parseFloat(0);
    let end=parseFloat(0);

    let startElementBeam=parseFloat(0);
    beamMapIds.forEach((map,index)=>{
     
      if(map[0].id === this.props.selectedModelElement.parentID){
        //start = map[1] +(this.props.selectedModelElement.lengthL1*map[0].lengthL);
        startElementBeam=map[1];
        end=mov
      }
    })


    if( end < startBeam ){
      end=parseFloat(0);
    }
    if( end > endBeam ){
      end=endBeam;
    }


    if((end >= startBeam) && (end <= endBeam)){
     let newParent;
     beamMapIds.forEach((map,index)=>{
     
      if((end >= map[1]) && (end <= map[2])){
        newParent=map[0];
      }
    })

    beamMapIds.forEach((map,index)=>{
      if(map[0].id === newParent.id){
        startElementBeam=map[1];
      }
    }
    )
    
   let newPosition= end-startElementBeam
   
 
    if(this.props.selectedModelElement.parentID === newParent.id ){
        this.props.updateLoadL1Element(this.props.selectedModelElement.id, (newPosition/newParent.lengthL));
        // console.log("1newPosition: " + newPosition+ "   end: " + end+ "   startElementBeam: " + startElementBeam)
    }
    else{
      beamMapIds.forEach((map,index)=>{
        if(map[0].id === newParent.id){
          startElementBeam=map[1];
        }
      })
   
    newPosition= end-startElementBeam
   
     this.props.updateLoadL1AndParentIdElement(this.props.selectedModelElement.id, (newPosition/newParent.lengthL),newParent.id);
    // console.log("2newPosition: " + newPosition+ "   end: " + end+ "   end: " + end)
    }

  
    if(this.props.selectedModelElement.parentID === newParent.id ){
      let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
        this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
        this.props.selectedModelElement.parentID)
        this.props.changeModelElement(modelElement);
        }
        else{
          newPosition= end-startElementBeam
          let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
            this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
            newParent.id)
            this.props.changeModelElement(modelElement);
        }

  
    }else{
     // console.log("poza zakresem")
    }
  }
  tk2(mov){
      let beamLength=parseFloat(0);

      this.props.beamElements.forEach(element=> {
          beamLength = beamLength + element.lengthL;
      })

      let startBeam=parseFloat(0);
      let endBeam =parseFloat(beamLength);
    
      let beamLength2=parseFloat(0);
      let beamMapIds=[];

      this.props.beamElements.forEach((element,index)=> {
        let beamScope=[];
        let startElement=parseFloat(0);
        let endElement=parseFloat(0);
          
        if(index > 0){
            beamLength2 = beamLength2 + this.props.beamElements[index-1].lengthL
        }
           
        beamScope.push(element);
    
        if(index > 0){
            startElement=beamLength2;
            endElement=beamLength2+element.lengthL;
        }else{
            startElement=parseFloat(0);
            endElement=element.lengthL;
        }
          beamScope.push(startElement);
          beamScope.push(endElement);
    
          beamMapIds.push(beamScope);
         
        })
        
        let start=parseFloat(0);
        let startElementBeam=parseFloat(0);
        let end=parseFloat(0);
        beamMapIds.forEach((map,index)=>{
         
          if(map[0].id === this.props.selectedModelElement.parentID){
            start = map[1] +this.props.selectedModelElement.lengthL1*map[0].lengthL;
            startElementBeam=map[1];
            end=Number(parseFloat(start)-parseFloat(mov)).toFixed(2)
          }
        })
    
        //console.log("start: " + start+ "   startElementBeam: " + startElementBeam+ "   end: " + end)
    
        if(end >= startBeam && end <= endBeam){
         let newParent;
         beamMapIds.forEach((map,index)=>{
         
          if(end >= map[1] && end < map[2]){
            newParent=map[0];
          }
        })
    
        beamMapIds.forEach((map,index)=>{
          if(map[0].id === newParent.id){
            startElementBeam=map[1];
          }
        })
        
       let newPosition= end-startElementBeam
       
     
        if(this.props.selectedModelElement.parentID === newParent.id ){
            this.props.updateLoadL1Element(this.props.selectedModelElement.id, (newPosition/newParent.lengthL));
          //  console.log("1newPosition: " + newPosition+ "   end: " + end+ "   end: " + end)
        }
        else{
          beamMapIds.forEach((map,index)=>{
            if(map[0].id === newParent.id){
              startElementBeam=map[1];
            }
          })
       
        newPosition= end-startElementBeam
       
         this.props.updateLoadL1AndParentIdElement(this.props.selectedModelElement.id, (newPosition/newParent.lengthL),newParent.id);
        // console.log("2newPosition: " + newPosition+ "   end: " + end+ "   end: " + end)
        }
    
        if(this.props.selectedModelElement.parentID === newParent.id ){
          let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
            this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
            this.props.selectedModelElement.parentID)
            this.props.changeModelElement(modelElement);
            }
            else{
              newPosition= end-startElementBeam
              let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
                this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
                newParent.id)
                this.props.changeModelElement(modelElement);
            }
    
      
        }else{
          
        }
        
    
      }
    
  calculateOffset(index, sectionElementOffset){
    if(index > 0){
      sectionElementOffset = sectionElementOffset + this.props.beamElements[index-1].lengthL;
    }
  return sectionElementOffset
  }

  calculateLoadsPosition(){
    let beamElementsLoadLengthPosition = [];

    this.props.beamElements.forEach((element, index) => {
      let loadLengthPosition = [];
      let allLoads = this.props.beamLoads.filter(load => load.parentID === element.id)
      loadLengthPosition.push(0)
      loadLengthPosition.push(element.lengthL)
      allLoads.forEach(load => {
        

        if(load instanceof MomentLoadElement || load instanceof ConcentratedLoadElement ){
          let calculatePoint = load.lengthL1*element.lengthL

          loadLengthPosition.push(calculatePoint)
        }
        else if(load instanceof DistributedLoadElement){
          let calculatePoint1 = load.lengthL1*element.lengthL
          let calculatePoint2 = load.lengthL2*element.lengthL

          loadLengthPosition.push(calculatePoint1)
          loadLengthPosition.push(calculatePoint2)
        }
      })
      let uniqLoadPoints = [...new Set(loadLengthPosition)];
      uniqLoadPoints.sort(this.compareNumbers);
      let resultLoadLengthPoints=[]

      for(let i=1;i < uniqLoadPoints.length;i++){
          resultLoadLengthPoints.push(uniqLoadPoints[i]-uniqLoadPoints[i-1])
      }

      beamElementsLoadLengthPosition.push(resultLoadLengthPoints);
    });

    return beamElementsLoadLengthPosition;
  }

  calculateLoadsPosition2(){
    let beamElementsLoadLengthPosition = [];

    this.props.beamElements.forEach((element, index) => {
      let loadLengthPosition = [];
      let allLoads = this.props.beamLoads.filter(load => load.parentID === element.id)
      loadLengthPosition.push(0)
        loadLengthPosition.push(element.lengthL)
      allLoads.forEach(load => {
        

        if(load instanceof MomentLoadElement || load instanceof ConcentratedLoadElement ){
          let calculatePoint = load.lengthL1*element.lengthL

          loadLengthPosition.push(calculatePoint)
        }
        else if(load instanceof DistributedLoadElement){
          let calculatePoint1 = load.lengthL1*element.lengthL
          let calculatePoint2 = load.lengthL2*element.lengthL

          loadLengthPosition.push(calculatePoint1)
          loadLengthPosition.push(calculatePoint2)
        }
      })
      let uniqLoadPoints = [...new Set(loadLengthPosition)];
      uniqLoadPoints.sort(this.compareNumbers);

      beamElementsLoadLengthPosition.push(uniqLoadPoints);
    });

    return beamElementsLoadLengthPosition;
  }
  componentDidUpdate(prevProps,stateprev) {
    if(init === 0){
    this.resize();
    init=1;
    }

 if(!this.props.globalMassLoad){
  if (( this.props.beamLoads !== prevProps.beamLoads)|| 
    (this.props.beamSupports !== prevProps.beamSupports) ||
    (this.props.beamElements !== prevProps.beamElements) ||
    (this.state.beamScale !== stateprev.beamScale)
    ||((this.props.supportReactions !== prevProps.supportReactions) && (!globalMode))
    || (this.props.GLOBAL_MODE !== prevProps.GLOBAL_MODE)
  ){

      if(!globalMode){

        this.scene.remove.apply(this.scene, this.scene.children);
        globalBeamObject.length=0;

      }else {
        // eslint-disable-next-line array-callback-return
        let tab=this.scene.children.filter(ele=>{
          if(ele.name === "textMeshELPosition" || ele.name ==="saports" || ele.name === (this.props.selectedModelElement.id+"loadTextMesh")
          || ele.name ===(this.props.selectedModelElement.id+"sectionLoad")|| ele.name ===(this.props.selectedModelElement.id+"sectionLoad2")|| ele.name ===(this.props.selectedModelElement.id+"load")){
            return ele;
          
          } });
        this.scene.remove.apply(this.scene, tab);

          // eslint-disable-next-line array-callback-return
         let tabs=globalBeamObject.filter(ele=>{
          if((ele.name !== "textMeshELPosition") && (ele.name !=="saports") && (ele.name !== (this.props.selectedModelElement.id+"loadTextMesh"))
          &&( ele.name !==(this.props.selectedModelElement.id+"sectionLoad"))&&( ele.name !==(this.props.selectedModelElement.id+"sectionLoad2"))&& (ele.name !==(this.props.selectedModelElement.id+"load"))){
            return ele;
          
          } });
          globalBeamObject.length=0;
          globalBeamObject=[...tabs]
         
            
      
              
      }

    tabb.length=0;
    let sum=0;
    this.props.beamElements.forEach(element => {
      sum=sum+element.lengthL;
    });

    if( this.state.sumOfElement !== sum )
    {
      this.setState({
        sumOfElement: sum,
          beamScale: (globalScale*(1/(sum)))
      }) 
    }
    
      let sectionElementOffset = 0;

      let beamElementsLoadLengthPosition = this.calculateLoadsPosition();
      let beamElementsLoadLengthPosition2 = this.calculateLoadsPosition2();
      
     this.props.beamElements.forEach((element, index) => {
     sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
       this.drawELPosition(beamElementsLoadLengthPosition[index],beamElementsLoadLengthPosition2[index],element, sectionElementOffset)
    });
     sectionElementOffset = 0;
     
      this.props.beamElements.forEach((element, index) => {
        sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
        if(!globalMode){
          this.drawElements(index, element, sectionElementOffset)
        }

        this.props.beamLoads.forEach(load => {
          this.drawLoads(load, sectionElementOffset, element)
        });

      });
    
      sectionElementOffset = 0;

      this.props.beamSupports.forEach((element, index) => {
        sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
        this.drawSupports(element, sectionElementOffset,index)
      });

      sectionElementOffset = 0;

      if(!globalMode){
        this.props.beamNodes.forEach((element, index) => {
          sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
            this.drawNodes(index, element,sectionElementOffset)
        });
    }
  

      this.renderer.render(this.scene, this.camera);
    }
    else{
      if(this.props.selectedModelElement !== null) {
        globalBeamObject.forEach(element => {
          if(element.geometry.name.id === this.props.selectedModelElement.id){   
              element.material.color.set("red")}
          else{
            if(element.geometry.name instanceof SupportElement){
              element.material.color.set("blue")}
            if(element.geometry.name instanceof LoadElement){ 
              element.material.color.set("blue")}
            if(element.geometry.name instanceof NodeElement){ 
              element.material.color.set("black")}
            if(element.geometry.name instanceof SectionElement){ 
              element.material.color.set("black")}
            }
        })
       }
      else{
        globalBeamObject.forEach( element => {
          if(element.geometry.name instanceof SupportElement){
            element.material.color.set("blue")}
          if(element.geometry.name instanceof LoadElement){ 
            element.material.color.set("blue")}
          if(element.geometry.name instanceof NodeElement){ 
            element.material.color.set("black")}
          if(element.geometry.name instanceof SectionElement){ 
            element.material.color.set("black")}
        }
        
        )
      }
    }
    tabb.length=0;
  }
    else{
      if(this.props.selectedModelElement !== null){
        // eslint-disable-next-line array-callback-return
        globalBeamObject.filter(gbmElement=>{
          if( gbmElement.geometry.name instanceof LoadElement ){
              gbmElement.material.color.set("blue")
          } });
        } 
      if(Array.isArray(this.props.selectedModelElement)){
          // eslint-disable-next-line array-callback-return
          globalBeamObject.filter(gbmElement=>{
          if( gbmElement.geometry.name instanceof LoadElement ){
            if(!(this.props.selectedModelElement.includes(gbmElement.geometry.name))){
              gbmElement.material.color.set("blue")
            }else{
              gbmElement.material.color.set("red")
            }
          } });

          
      }
    }

  }


  render() {
    return (
      <>{this.props.numberOfModels.length !== 0 ?
 
       <div className="BeamClass" ref={mount => {this.mount = mount}}/>
      :
      <div className="BeamClass" style={{display: "none" }} />}

      <div className="menu">
       <ul className="menu-options">
          <button onClick={()=> this.copyLoad()} style={{borderRadius: "2px",border: "1px solid #80808063", marginBottom: "1px"}} className="menu-option">{this.translate("copyPaste")}</button>
          <button onClick={()=> this.removeLoad()} style={{borderRadius: "2px",border: "1px solid #80808063"}} className="menu-option">{this.translate("removeLoad")}</button>
        </ul>
      </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeModelElement: value => dispatch(mefaActions.changeModelElement(value)),
  updateLoadL1Element: (index, value) =>dispatch(mefaActions.updateLoadL1Element(index, value)),
  deleteLoadElement: loadElement => dispatch(mefaActions.deleteLoadElement(loadElement)),
  addLoadElement: loadElement => dispatch(mefaActions.addLoadElement(loadElement)),
  updateLoadL1AndParentIdElement: (index, value,parentID) =>
  dispatch(mefaActions.updateLoadL1AndParentIdElement(index, value,parentID)),
  changeGlobalMode: mode => dispatch(mefaActions.changeGlobalMode(mode))
});

let existBeamElements =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].elements
  else return []
}

let existBeamNodes =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].nodes
  else return []
}

let existBeamLoads =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].loads
  else return []
}

let existBeamSupports =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].supports
  else return []
}

let existCurrentProfileId =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].profileId
  else return []
}

const mapStateToProps = state => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  selectedLanguage: state.mefa.present.selectedLanguage,
  beamElements: existBeamElements(state,state.mefa.present.models.length),
  beamLoads: existBeamLoads(state,state.mefa.present.models.length),
  beamSupports: existBeamSupports(state,state.mefa.present.models.length),
  beamNodes: existBeamNodes(state,state.mefa.present.models.length),
  numberOfModels: state.mefa.present.models,
  currentStateModel: state.mefa.present.models[state.mefa.present.selectedModelIndex],
  supportReactions: state.mefa.present.supportReactions,
  globalOffset: state.mefa.present.GLOBAL_OFFSET,
  profilModelId: existCurrentProfileId(state,state.mefa.present.models.length),
  GLOBAL_MODE: state.mefa.present.GLOBAL_MODE,
  globalMassLoad: state.mefa.present.GLOBAL_MASS_LOAD

});

export default connect(mapStateToProps, mapDispatchToProps)(Beam);