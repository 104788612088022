import React from "react";
import { connect } from "react-redux";
import './ModelToolBar.css';
import SectionElement from "../../../app/classes/element/SectionElement";
import LoadElement from "../../../app/classes/load/LoadElement";
import DistributedLoadElement from "../../../app/classes/load/DistributedLoadElement";
import mefaActions from "../../../app/mefa-app/duck/actions";
import Select from "react-select";
import ConcentratedLoadElement from "../../../app/classes/load/ConcentratedLoadElement";
import MomentLoadElement from "../../../app/classes/load/MomentLoadElement";
import fotoRot0Deg from '../../resources/rotation_0deg.png';
import fotoRot90Deg from '../../resources/rotation_90deg.png';
import fotoRot180Deg from '../../resources/rotation_180deg.png';
import fotoRot270Deg from '../../resources/rotation_270deg.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import translateText from '../../LanguageTranslator';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-responsive-tabs/styles.css';

const displacementOptions = [
  { value: "1-150", label: "1/150" },
  { value: "1-200", label: "1/200" }
];



const rotationOptions = [
  { value: 0, label: <img alt="0"src={fotoRot0Deg} ></img> },
  { value: 90, label: <img alt="90" src={fotoRot90Deg} ></img>},
  { value: 180, label: <img alt="180"src={fotoRot180Deg} ></img> },
  { value: 270, label: <img alt="270" src={fotoRot270Deg} ></img> }
];

class ModelToolBar extends React.Component {
 

  constructor(props) {
    super(props);
    this.state = {
      selectedOptionRotation: { value: "0" },
      selectedOptionDisplacement: { value: "1-150", label: "1/150" },
      valueDeflectionArrow:33333,
      counter: 0,
      lastLoad: undefined,
      valueGlobalOffset: Number(this.props.globalOffset).toFixed(2).replace(".", ","),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChangeDA = this.handleChangeDA.bind(this);
    this.handleSubmitDA = this.handleSubmitDA.bind(this);
  }

  sectionLengthInput = React.createRef();
  loadLengthL1Input = React.createRef();
  loadLengthL2Input = React.createRef();
  LoadForceInput = React.createRef();

  LoadForceInputMass = React.createRef();

  offsetInput = React.createRef();

  deflectionInput = React.createRef();


  handleChange(event) {
    this.setState({valueGlobalOffset: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  

  componentDidUpdate(){
    if(this.props.selectedOptionDisplacement !== this.state.valueDeflectionArrow){
     this.deflectionInput.value = this.props.selectedOptionDisplacement;
    }
  }

  handleChangeDA(event) {
  }

  handleSubmitDA(event) {
    event.preventDefault();
    
     let dotToComma=Number((this.deflectionInput.value).replace(",", "."));
     let newValidateValue = dotToComma;

     if(dotToComma < 150){
      newValidateValue = 150;
     }else if(dotToComma > 1000){
      newValidateValue = 1000;
     }

    if(newValidateValue >= 150 && newValidateValue <= 1000){
      this.props.changeDisplacement(newValidateValue);
      this.deflectionInput.value = newValidateValue;
    }
  }


  translate = (text) => {
    return (translateText(text, this.props.selectedLanguage));
}
  compareNumbers(a, b)
  {
      return a - b;
  }
  cDisplacement = event => {

  };

  cRotation = event => {
    this.props.changeRotation(event.value);
  };

  handleSubmitSectionInput = event => {
    event.preventDefault();
    if(this.props.selectedModelElement !== null && this.props.selectedModelElement !== "init"){
      let dotToComma=(this.sectionLengthInput.value).replace(",", ".");

    if(dotToComma >= 0.001 && dotToComma <= 100){

    this.props.updateSectionElement(this.props.selectedModelElement.id,dotToComma)

    let section=this.props.selectedModelElement;
    let modelElement = false;
    
    modelElement =new SectionElement(section.id,section.name,dotToComma)
    
    this.props.changeModelElement(modelElement);
    }else{
     // alert("Nieprawidłowa wartość")
    }

    }

  };

  changeGlobalMassLoad = ()=>{

    this.props.changeGlobalMassLoad(!this.props.globalMassLoad)
  }
  changeGlobalMassLoadOut = ()=>{

    this.props.changeGlobalMassLoad(!this.props.globalMassLoad)
    this.props.changeModelElement(null)
  }
  handleSubmitLoadInputL1 = event => {
    event.preventDefault();
    if(this.props.selectedModelElement !== null && this.props.selectedModelElement !== "init") {

      let parentId=this.props.selectedModelElement.parentID;
      let sectionElement = this.props.beamElements.find(element=> element.id === parentId)
      if(sectionElement !== null && sectionElement !== undefined ){
      let originalPoints=this.calculateLoadsPosition(sectionElement);
      let loadElementPosition=this.props.selectedModelElement.lengthL1*sectionElement.lengthL;
      let finalOffset=0;

      for(let i=1; i < originalPoints.length; i++){
          let offset = 0;
          offset=originalPoints[i-1]
          if(originalPoints[i] === loadElementPosition){
            finalOffset = offset;
            break;
          }
      }
     let dotToComma=(this.loadLengthL1Input.value).replace(",", ".");
     let offsetValue = (this.props.selectedModelElement.lengthL1*sectionElement.lengthL)-finalOffset;
     let diffValue = dotToComma - offsetValue;
     let finalValue=(this.props.selectedModelElement.lengthL1+(diffValue/sectionElement.lengthL))

     let distributedLength12Flag=true;
     if(this.props.selectedModelElement instanceof DistributedLoadElement){
      finalValue <= this.props.selectedModelElement.lengthL2
       ? distributedLength12Flag=true
       : distributedLength12Flag=false
     }

      if((finalValue >= 0.0 && finalValue <= 1) && distributedLength12Flag){

      this.props.updateLoadL1Element(this.props.selectedModelElement.id, finalValue);
       
      let load=this.props.selectedModelElement;
      let modelElement = false;

      if(load instanceof ConcentratedLoadElement){
        modelElement =new ConcentratedLoadElement(load.id,load.name,load.force,finalValue,
          load.parentID)
        
      }else if(load instanceof MomentLoadElement){
        modelElement =new MomentLoadElement(load.id,load.name,load.force,finalValue,
          load.parentID)
      }else{
        modelElement =new DistributedLoadElement(load.id,load.name,load.force,finalValue,
          load.lengthL2, load.parentID)
      }
       this.props.changeModelElement(modelElement);
    }else{
     // alert("nieprawidlowa wartosc")
    }
  }
    }
  };

  handleSubmitLoadInputL2 = event => {
    event.preventDefault();
    if(this.props.selectedModelElement !== null && this.props.selectedModelElement !== "init") {

      let parentId=this.props.selectedModelElement.parentID;
      let sectionElement = this.props.beamElements.find(element=> element.id === parentId)
      let dotToComma=(this.loadLengthL2Input.value).replace(",", ".");


      if((dotToComma >= 0.0 && dotToComma <= sectionElement.lengthL)
      && (dotToComma >= this.props.selectedModelElement.lengthL1)){

      this.props.updateLoadL2Element(this.props.selectedModelElement.id,
        (dotToComma/sectionElement.lengthL)
      );

      let load=this.props.selectedModelElement;
      let modelElement = false;
      
      modelElement =new DistributedLoadElement(load.id,load.name,load.force,load.lengthL1,
        dotToComma/sectionElement.lengthL, load.parentID)
      
       this.props.changeModelElement(modelElement);
      }else{
        //alert("nieprawidlowa wartosc")
      }
    }
  };

  handleSubmitLoadInputForce = event => {
    event.preventDefault();
    if(this.props.selectedModelElement !== null && this.props.selectedModelElement !== "init") {
      let dotToComma=(this.loadForceInput.value).replace(",", ".");

      let conditionFlag=true;
      if(this.props.selectedModelElement instanceof MomentLoadElement){
        dotToComma >= -1000 && dotToComma <= 1000 
        ? conditionFlag = true
        : conditionFlag = false
      }else{
        dotToComma >= 0 && dotToComma <= 1000 
        ? conditionFlag = true
        : conditionFlag = false
      }

      if(conditionFlag){

      this.props.updateLoadForceElement(this.props.selectedModelElement.id,dotToComma);

      let load=this.props.selectedModelElement;
      let modelElement = false;

      if(load instanceof ConcentratedLoadElement){
        modelElement =new ConcentratedLoadElement(load.id,load.name,dotToComma,load.lengthL1,
          load.parentID)
        
      }else if(load instanceof MomentLoadElement){
        modelElement =new MomentLoadElement(load.id,load.name,dotToComma,load.lengthL1,
          load.parentID)
      }else{
        modelElement =new DistributedLoadElement(load.id,load.name,dotToComma,load.lengthL1,
          load.lengthL2, load.parentID)
      }
       this.props.changeModelElement(modelElement);
    }else{
      //alert("wartosc nieprawidlowa")
    }
  }
  };


  handleSubmitLoadInputForceMass = event => {
    event.preventDefault();
    if(Array.isArray(this.props.selectedModelElement)){
      let dotToComma=(this.loadForceInputMass.value).replace(",", ".");

      this.props.selectedModelElement.forEach(ele=>{
      let conditionFlag=true;
      if(ele instanceof MomentLoadElement){
        dotToComma >= -1000 && dotToComma <= 1000 
        ? conditionFlag = true
        : conditionFlag = false
      }else{
        dotToComma >= 0 && dotToComma <= 1000 
        ? conditionFlag = true
        : conditionFlag = false
      }

      if(conditionFlag){

      this.props.updateLoadForceElement(ele.id,dotToComma);

      }
      })
      // let load=this.props.selectedModelElement;
      //  let modelElement = false;

     
      //   modelElement =new ConcentratedLoadElement(this.props.selectedModelElement[0].id,this.props.selectedModelElement[0].name,dotToComma,this.props.selectedModelElement[0].lengthL1,
      //     this.props.selectedModelElement[0].parentID)
        
      // }else if(load instanceof MomentLoadElement){
      //   modelElement =new MomentLoadElement(load.id,load.name,dotToComma,load.lengthL1,
      //     load.parentID)
      // }else{
      //   modelElement =new DistributedLoadElement(load.id,load.name,dotToComma,load.lengthL1,
      //     load.lengthL2, load.parentID)
      // }
      //  this.props.changeModelElement(modelElement);
   // }else{
      //alert("wartosc nieprawidlowa")
   // }
   
   this.props.changeGlobalMassLoad(false)
   this.props.changeModelElement( null)
  }
  };

  onChangeSectionInput = () => {
    if (
      this.props.selectedModelElement !== null &&
      this.props.selectedModelElement instanceof SectionElement
    ) {
      let finalValue = Number(this.props.selectedModelElement.lengthL).toFixed(2).replace(".", ",");
      this.sectionLengthInput.value = finalValue;
    } else if (this.props.selectedModelElement !== null) {
      this.sectionLengthInput.value = 0;
    } else if (this.props.selectedModelElement === "init") {
      this.sectionLengthInput.value = 0;
    }
  };
  onChangeOffsetInput =(event)=>{
    // this.offsetInput.value = event.value

  }
  calculateLoadsPosition(sectionElement){
  
      let loadLengthPosition = [];
      let allLoads = this.props.beamLoads.filter(load => load.parentID === sectionElement.id)
      loadLengthPosition.push(0)
      loadLengthPosition.push(sectionElement.lengthL)
      allLoads.forEach(load => {
          loadLengthPosition.push(load.lengthL1*sectionElement.lengthL)
      })
      let uniqLoadPoints = [...new Set(loadLengthPosition)];
      uniqLoadPoints.sort(this.compareNumbers);

    return uniqLoadPoints;
  }


  tk(){

    let parentId=this.props.selectedModelElement.parentID;
    let sectionElement = this.props.beamElements.find(element=> element.id === parentId)
    if(sectionElement !== null && sectionElement !== undefined ){
    let mov= (this.state.valueGlobalOffset).replace(",", ".")
    let beamLength=parseFloat(0);

    this.props.beamElements.forEach(element=> {
      beamLength = beamLength + element.lengthL;
    })

    let startBeam=parseFloat(0);
    let endBeam =parseFloat(beamLength);


    let beamLength2=parseFloat(0);

    let beamMapIds=[];


    this.props.beamElements.forEach((element,index)=> {
      let beamScope=[];
      let startElement=parseFloat(0);
      let endElement=parseFloat(0);
      
      if(index > 0){
        beamLength2 = beamLength2 + this.props.beamElements[index-1].lengthL
      }
       
      beamScope.push(element);

      if(index > 0){
        startElement=beamLength2;
        endElement=beamLength2+element.lengthL;
      }else{
        startElement=parseFloat(0);
        endElement=element.lengthL;
      }
      beamScope.push(startElement);
      beamScope.push(endElement);

      beamMapIds.push(beamScope);
     
    })

    let start=parseFloat(0);
    let end=parseFloat(0);

    let startElementBeam=parseFloat(0);
    beamMapIds.forEach((map,index)=>{
     
      if(map[0].id === this.props.selectedModelElement.parentID){
        start = map[1] +(this.props.selectedModelElement.lengthL1*map[0].lengthL);
        startElementBeam=map[1];
        end=Number(parseFloat(start)+parseFloat(mov)).toFixed(2)
      }
    })

   
    if((end >= startBeam) && (end <= endBeam)){
     let newParent;
     beamMapIds.forEach((map,index)=>{
     
      if((end > map[1]) && (end <= map[2])){
        newParent=map[0];
      }
    })

    beamMapIds.forEach((map,index)=>{
      if(map[0].id === newParent.id){
        startElementBeam=map[1];
      }
    }
    )
    
   let newPosition= end-startElementBeam
   
 
    if(this.props.selectedModelElement.parentID === newParent.id ){
        this.props.updateLoadL1Element(this.props.selectedModelElement.id, (newPosition/newParent.lengthL));
        // console.log("1newPosition: " + newPosition+ "   end: " + end+ "   startElementBeam: " + startElementBeam)
    }
    else{
      beamMapIds.forEach((map,index)=>{
        if(map[0].id === newParent.id){
          startElementBeam=map[1];
        }
      })
   
    newPosition= end-startElementBeam
   
     this.props.updateLoadL1AndParentIdElement(this.props.selectedModelElement.id, (newPosition/newParent.lengthL),newParent.id);
    // console.log("2newPosition: " + newPosition+ "   end: " + end+ "   end: " + end)
    }

  
    if(this.props.selectedModelElement.parentID === newParent.id ){
      let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
        this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
        this.props.selectedModelElement.parentID)
        this.props.changeModelElement(modelElement);
        }
        else{
          newPosition= end-startElementBeam
          let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
            this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
            newParent.id)
            this.props.changeModelElement(modelElement);
        }

  
    }else{
     // console.log("poza zakresem")
    }
  }
  }
  tk2(){
    let parentId=this.props.selectedModelElement.parentID;
    let sectionElement = this.props.beamElements.find(element=> element.id === parentId)
    if(sectionElement !== null && sectionElement !== undefined ){
      let mov= (this.state.valueGlobalOffset).replace(",", ".")
      let beamLength=parseFloat(0);

      this.props.beamElements.forEach(element=> {
          beamLength = beamLength + element.lengthL;
      })

      let startBeam=parseFloat(0);
      let endBeam =parseFloat(beamLength);
    
      let beamLength2=parseFloat(0);
      let beamMapIds=[];

      this.props.beamElements.forEach((element,index)=> {
        let beamScope=[];
        let startElement=parseFloat(0);
        let endElement=parseFloat(0);
          
        if(index > 0){
            beamLength2 = beamLength2 + this.props.beamElements[index-1].lengthL
        }
           
        beamScope.push(element);
    
        if(index > 0){
            startElement=beamLength2;
            endElement=beamLength2+element.lengthL;
        }else{
            startElement=parseFloat(0);
            endElement=element.lengthL;
        }
          beamScope.push(startElement);
          beamScope.push(endElement);
    
          beamMapIds.push(beamScope);
         
        })
        
        let start=parseFloat(0);
        let startElementBeam=parseFloat(0);
        let end=parseFloat(0);
        beamMapIds.forEach((map,index)=>{
         
          if(map[0].id === this.props.selectedModelElement.parentID){
            start = map[1] +this.props.selectedModelElement.lengthL1*map[0].lengthL;
            startElementBeam=map[1];
            end=Number(parseFloat(start)-parseFloat(mov)).toFixed(2)
          }
        })
    
        //console.log("start: " + start+ "   startElementBeam: " + startElementBeam+ "   end: " + end)
    
        if(end >= startBeam && end <= endBeam){
         let newParent;
         beamMapIds.forEach((map,index)=>{
         
          if(end >= map[1] && end < map[2]){
            newParent=map[0];
          }
        })
    
        beamMapIds.forEach((map,index)=>{
          if(map[0].id === newParent.id){
            startElementBeam=map[1];
          }
        })
        
       let newPosition= end-startElementBeam
       
     
        if(this.props.selectedModelElement.parentID === newParent.id ){
            this.props.updateLoadL1Element(this.props.selectedModelElement.id, (newPosition/newParent.lengthL));
          //  console.log("1newPosition: " + newPosition+ "   end: " + end+ "   end: " + end)
        }
        else{
          beamMapIds.forEach((map,index)=>{
            if(map[0].id === newParent.id){
              startElementBeam=map[1];
            }
          })
       
        newPosition= end-startElementBeam
       
         this.props.updateLoadL1AndParentIdElement(this.props.selectedModelElement.id, (newPosition/newParent.lengthL),newParent.id);
        // console.log("2newPosition: " + newPosition+ "   end: " + end+ "   end: " + end)
        }
    
        if(this.props.selectedModelElement.parentID === newParent.id ){
          let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
            this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
            this.props.selectedModelElement.parentID)
            this.props.changeModelElement(modelElement);
            }
            else{
              newPosition= end-startElementBeam
              let modelElement =new ConcentratedLoadElement(this.props.selectedModelElement.id,this.props.selectedModelElement.name,
                this.props.selectedModelElement.force,(newPosition/newParent.lengthL),
                newParent.id)
                this.props.changeModelElement(modelElement);
            }
    
      
        }else{
          
        }
        
      }
      }
 
  onChangeloadInputL1 = () => {
   
    if(this.props.selectedModelElement !== null && this.props.selectedModelElement instanceof LoadElement){

      let parentId=this.props.selectedModelElement.parentID;
      let sectionElement = this.props.beamElements.find(element=> element.id === parentId)
      if(sectionElement !== null && sectionElement !== undefined ){
      let originalPoints=this.calculateLoadsPosition(sectionElement);
      let loadElementPosition=this.props.selectedModelElement.lengthL1*sectionElement.lengthL;
      let finalOffset=0;

      for(let i=1; i < originalPoints.length; i++){
          let offset = 0;
          offset=originalPoints[i-1]
          if(originalPoints[i] === loadElementPosition){
            finalOffset = offset;
            break;
          }
      }
      let finalValue= Number(((this.props.selectedModelElement.lengthL1*sectionElement.lengthL)-finalOffset)).toFixed(2).replace(".", ",");
      this.loadLengthL1Input.value = finalValue;

    }
    } else if (this.props.selectedModelElement !== null) {
      this.loadLengthL1Input.value = 0;
    } else if (this.props.selectedModelElement === "init") {
      this.loadLengthL1Input.value = 0;
    }
  };

  onChangeloadInputL2 = () => {
    if(this.props.selectedModelElement !== null && this.props.selectedModelElement instanceof DistributedLoadElement
    ) {

      let parentId=this.props.selectedModelElement.parentID;
      let sectionElement = this.props.beamElements.find(element=> element.id === parentId)
      let finalValue= Number(this.props.selectedModelElement.lengthL2*sectionElement.lengthL).toFixed(2).replace(".", ",");
      this.loadLengthL2Input.value = finalValue;

    } else if (this.props.selectedModelElement !== null) {
      this.loadLengthL2Input.value = 0;
    } else if (this.props.selectedModelElement === "init") {
      this.loadLengthL2Input.value = 0;
    }
  };

  onChangeLoadForceInput = () => {
    if (
      this.props.selectedModelElement !== null &&
      this.props.selectedModelElement instanceof LoadElement
    ) {
      let finalValue = Number(this.props.selectedModelElement.force).toFixed(2).replace(".", ",");
      this.loadForceInput.value = finalValue;
    } else if (this.props.selectedModelElement !== null) {
      this.loadForceInput.value = 0;
    } else if (this.props.selectedModelElement === "init") {
      this.loadForceInput.value = 0;
    }
  };



  sectionInput = () => {
    return (
      <form onSubmit={this.handleSubmitSectionInput}>
        <label>
          L[m]=
          <input
            type="double"
            onChange={this.onChangeSectionInput()}
            readOnly={
              this.props.selectedModelElement === "init" ||
              this.props.selectedModelElement === null ||
              !(this.props.selectedModelElement instanceof SectionElement)
            }
            defaultValue={0}
            ref={sectionLengthInput =>
              (this.sectionLengthInput = sectionLengthInput)
            }
          />
        </label>
      </form>
    );
  };

correctUnitToDisplay(){
  let force = "P[-]=";
  if(this.props.selectedModelElement instanceof ConcentratedLoadElement){
    force = "P[kN]=";
  }else if(this.props.selectedModelElement instanceof MomentLoadElement){
    force = "P[kNm]=";
  }else if(this.props.selectedModelElement instanceof DistributedLoadElement){
    force = "P[kN/m]=";
  }
  return force;
}

  loadInput = () => {
    let force = this.correctUnitToDisplay();
    return (
      <>
      <div style={{display: "flex"}}>
      <button  style={{transition:"0.2s",marginRight:"5px",height: "28px",width:"26px",borderRadius:"4px",background:"#337ab7"}}>
                <SettingsIcon fontSize="small" style={this.props.globalMassLoad ?{background: "whitesmoke",color:"white"}: {color:"white"}}
                  onClick={() => this.changeGlobalMassLoad()}/>
              </button>
      <div style={{display: "flex",margin: "0 5px", gap:"10px"}}>
        <form onSubmit={this.handleSubmitLoadInputL1}>
          <label style={{marginLeft:"3px"}}>
            L1=
            <input
              type="double"
              onChange={this.onChangeloadInputL1()}
              readOnly={
                this.props.selectedModelElement === "init" ||
                this.props.selectedModelElement === null ||
                !(this.props.selectedModelElement instanceof LoadElement)
              }
              defaultValue={0}
              ref={loadLengthL1Input =>
                (this.loadLengthL1Input = loadLengthL1Input)
              }
            />
          </label>
        </form>

        <form onSubmit={this.handleSubmitLoadInputL2}>
        <label style={{marginLeft:"3px"}}>
            L2=
            <input
              type="double"
              onChange={this.onChangeloadInputL2()}
              readOnly={
                this.props.selectedModelElement === "init" ||
                this.props.selectedModelElement === null ||
                !(
                  this.props.selectedModelElement instanceof
                  DistributedLoadElement
                )
              }
              defaultValue={0}
              ref={loadLengthL2Input =>
                (this.loadLengthL2Input = loadLengthL2Input)
              }
            />
          </label>
        </form>

        <form onSubmit={this.handleSubmitLoadInputForce}>
        <label style={{marginLeft:"3px"}}>
           {force}
            <input
              type="double"
              onChange={this.onChangeLoadForceInput()}
              readOnly={
                this.props.selectedModelElement === "init" ||
                this.props.selectedModelElement === null ||
                !(this.props.selectedModelElement instanceof LoadElement)
              }
              defaultValue={0}
              ref={loadForceInput => (this.loadForceInput = loadForceInput)}
            />
          </label>
        </form>
        </div>
        {this.props.selectedModelElement instanceof LoadElement ? (
            <div style={{marginLeft: "2%"}}> 
              
               
            <form onSubmit={this.handleSubmit}>
              <button  style={{marginRight:"2px",height: "26px",width:"26px",borderRadius:"4px",background:"#337ab7"}}>
                <ArrowBackIcon fontSize="small" 
                  htmlColor="white" 
                  style={{color:"white", fontSize:"1.15rem"}}
                  onClick={() => this.tk2()}/>
              </button>
            
              <label style={{fontWeight: "bold"}}>
                <input className="inputArrow" type="double" value={this.state.valueGlobalOffset} onChange={this.handleChange} />
              </label>
              
              <button style={{marginLeft:"2px",height: "26px",width:"26px",borderRadius:"4px",background:"#337ab7"}}>
                <ArrowForwardIcon fontSize="small"
                    htmlColor="white"
                    style={{color:"white",fontSize:"1.15rem"}}
                    onClick={() => this.tk()}/>
              </button>
              
              </form>
            </div>
          ) : (
            false
          )}
        </div>
      </>
    );
  };
  
  loadInputMassLoad = () => {
    let force = this.correctUnitToDisplay();
    return (
      <>
      <div style={{display: "flex"}}>
       
      <button  style={this.props.globalMassLoad ?{transition:"0.2s",marginRight:"5px",height: "28px",width:"26px",borderRadius:"4px",background:"#f90b0d9c"}:{transition:"0.2s",marginRight:"5px",height: "26px",borderRadius:"4px",background:"#9db3c9b5"}}>
                <SettingsIcon fontSize="small" style={{ color:"white"}}
                  onClick={() => this.changeGlobalMassLoadOut()}/>
              </button>
        <form onSubmit={this.handleSubmitLoadInputForceMass}>
          
          <label>
           {force}
            <input
              type="double"
              defaultValue={0}
              ref={loadForceInputMass => (this.loadForceInputMass = loadForceInputMass)}
            />
          </label>
        </form>

       
        </div>
      </>
    );
  };
 
  render() {
    let ref =fotoRot0Deg;
if(this.props.selectedOptionRotation === 0){ref =fotoRot0Deg}
if(this.props.selectedOptionRotation === 90){ref =fotoRot90Deg}
if(this.props.selectedOptionRotation === 180){ref =fotoRot180Deg}
if(this.props.selectedOptionRotation === 270){ref =fotoRot270Deg}
    
return (
  <>
    <div className="parentModelToolBar">
      <div className="div1parentModelToolBar">
        <label> {this.translate("DispRotHeader")}</label>
        <div className="parentDropDown" >
          <div className="div1DropDown">
          <div>
               <form onSubmit={this.handleSubmitDA}>
                <label style={{display: "flex", alignItems: "center", fontSize: "14px"}}>
                <span>1<span>/</span></span>
                <input  style={{marginRight: "1px",minWidth: "50px", borderRadius: "4px", height: "38px", borderColor: "#cccccc", marginLeft:"4px", paddingLeft:"5px", flexGrow: "1"}}type="double"  onChange={this.handleChangeDA} defaultValue={this.props.selectedOptionDisplacement} ref={deflectionInput =>
              (this.deflectionInput = deflectionInput) 
            }
           />
                </label>
               
                
                </form>
               </div>
          </div>
          <div className="div2DropDown">
            {" "}
            <div >
              <Select
                options={rotationOptions}
                onChange={this.cRotation}
                value={{
                  value: `${this.props.selectedOptionRotation}`,
                  label: <img src={ref} alt=""></img>
                }}
              />
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="div2parentModelToolBar">
      <label>{this.translate("SectionHeader")}</label>
        {this.sectionInput()}
      </div>
      <div className="div3parentModelToolBar">
        <label>{this.translate("LoadHeader")}</label>
       
        { !this.props.globalMassLoad ? this.loadInput() : this.loadInputMassLoad()}
      </div>
    </div>
  </>
);


  }
}

const ModelChoice = props => {
  var handleToUpdate = props.changee;
  const mystyle = {
    color: "black",
    backgroundColor: "rgba(156, 128, 128, 0.726)",
    padding: "2px",
    fontFamily: "Arial"
  };
  const mystyle2 = {
    color: "black"
  };
  return (
    <>
      {props.models.map(model => (
        <button style={props.selectedModelId === model.id ? mystyle : mystyle2 }key={model.id} onClick={() => handleToUpdate(model.id)} >
          {model.name}
        </button>
      ))}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  changeModel: model => dispatch(mefaActions.changeModel(model)),
  updateSectionElement: (index, value) =>
    dispatch(mefaActions.updateSectionElement(index, value)),
  updateLoadL1Element: (index, value) =>
    dispatch(mefaActions.updateLoadL1Element(index, value)),
  updateLoadL1AndParentIdElement: (index, value,parentID) =>
    dispatch(mefaActions.updateLoadL1AndParentIdElement(index, value,parentID)),
  updateLoadL2Element: (index, value) =>
    dispatch(mefaActions.updateLoadL2Element(index, value)),
  updateLoadForceElement: (index, value) =>
    dispatch(mefaActions.updateLoadForceElement(index, value)),
  changeRotation: rotation => dispatch(mefaActions.changeRotation(rotation)),
  changeDisplacement: displacement =>
    dispatch(mefaActions.changeDisplacement(displacement)),
  changeModelElement: value => dispatch(mefaActions.changeModelElement(value)),
  changeGlobalOffset: value => dispatch(mefaActions.changeGlobalOffset(value)),
  changeGlobalMassLoad: value =>  dispatch(mefaActions.changeGlobalMassLoad(value)),
  changeDeflectionArrow: deflectionArrow => dispatch(mefaActions.changeDeflectionArrow(deflectionArrow))

});

const mapStateToProps = state => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  selectedModelId:state.mefa.present.selectedModelId,
  selectedLanguage: state.mefa.present.selectedLanguage,
  models: state.mefa.present.models,
  selectedOptionDisplacement:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].displacement,
  selectedOptionRotation:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].rotation,
  beamElements:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].elements,
    beamLoads:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].loads,
    globalOffset: state.mefa.present.GLOBAL_OFFSET,
    globalMassLoad: state.mefa.present.GLOBAL_MASS_LOAD,
    DEFLECTION_ARROW: state.mefa.present.DEFLECTION_ARROW
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ModelToolBar,
  ModelChoice
);
