import React from "react";
import './LanguageSelector.css';
import Select from "react-select";
import "react-dropdown/style.css";
import { connect } from "react-redux";
import actions from "../duck/actions";

const options = [
  { value: "pl", label: "Polski" },
  { value: "en", label: "English" },
  { value: "de", label: "Deutsch" },
  { value: "da", label: "Dansk" },
  { value: "it", label: "Italiano" },
  { value: "nl", label: "Nederlands" },
  { value: "hu", label: "Hungarian" },
  { value: "lv", label: "Latvian" },

];

function getOptionByLanguage(language) {
  let optionn;
  options.map(option =>
    option.value === language ? (optionn = option) : options[0]
  );
  return optionn;
}

class LanguageSelector extends React.Component {
  state = {
    selectedOption: getOptionByLanguage(this.props.selectedLanguage)
  };

  cLanguage = event => {
    this.setState({ selectedOption: event });
    this.props.changeLanguage(event);
  };
  render() {
    return (
      <div className="languageSelector" >
        <Select
          options={options}
          onChange={this.cLanguage}
          value={this.state.selectedOption}
        />
      </div>
    );
  }
  componentDidUpdate(){
    if(this.props.selectedLanguage !== this.state.selectedOption.value){
      this.setState({selectedOption: getOptionByLanguage(this.props.selectedLanguage)})
    }
  }
}

const mapDispatchToProps = dispatch => ({
  changeLanguage: language => dispatch(actions.changeLanguage(language))
});

const mapStateToProps = state => ({
  selectedLanguage: state.mefa.present.selectedLanguage
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
