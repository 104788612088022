import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import './ProjectsTreeView.css';
import { connect } from "react-redux";
import translateText from "../../LanguageTranslator";
import mefaActions from "../../../app/mefa-app/duck/actions";
import Modal from "react-responsive-modal";
import SvgIcon from '@material-ui/core/SvgIcon';
import SectionElement from "../../../app/classes/element/SectionElement";
import SupportElement from "../../../app/classes/support/SupportElement";
import NodeElement from "../../../app/classes/node/NodeElement";
import LoadElement from "../../../app/classes/load/LoadElement";
import BeamModel from "../../../app/classes/model/BeamModel";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';



function MinusSquare(props) {
  return (
      <SvgIcon size="small" fontSize="inherit" {...props}>
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
      <SvgIcon size="small" fontSize="inherit" {...props}>
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
  );
}



class ProjectsTreeView extends React.Component {
state={
  openNameModal: false,
  selectedElement: false
}

elementNameInput = React.createRef();

  translate = text => {
    return translateText(text, this.props.selectedLanguage);
  };

  deleteModel = model =>{
    if(this.props.selectedModelId !== model.id){
    this.props.deleteModel(model)
    this.props.changeModel(this.props.selectedModelId)
    }else if(this.props.models.length === 1 && this.props.selectedModelId === model.id){
      this.props.deleteModel(model)
    }
    else{
      //alert("nie mozna usunac wybranego modelu")
    }
  };



  handleSubmitName = event => {
    
    if(this.state.selectedElement instanceof LoadElement){
      this.props.updateLoadElementName(this.state.selectedElement.id,this.elementNameInput.value)
    }else if(this.state.selectedElement instanceof SupportElement){
      this.props.updateSupportElementName(this.state.selectedElement.id,this.elementNameInput.value)
    }else if(this.state.selectedElement instanceof NodeElement){
      this.props.updateNodeElementName(this.state.selectedElement.id,this.elementNameInput.value)
    }else if(this.state.selectedElement instanceof SectionElement){
      this.props.updateSectionElementName(this.state.selectedElement.id,this.elementNameInput.value)
    }else if(this.state.selectedElement instanceof BeamModel){
      this.props.updateModelName(this.state.selectedElement.id,this.elementNameInput.value)
    }else if(this.state.selectedElement === "elementsNodeParent"){
      this.props.updateElementNodeName(this.elementNameInput.value);
    

    }else if(this.state.selectedElement === "supportsNodeParent"){
      this.props.updateSupportNodeName(this.elementNameInput.value);
     

    }else if(this.state.selectedElement === "loadsNodeParent"){
      this.props.updateLoadNodeName(this.elementNameInput.value);
    

    }else if(this.state.selectedElement === "nodesNodeParent"){
      this.props.updateNodeNodeName(this.elementNameInput.value);
     

    }
    
   this.onCloseModal();
  };
  showTreeViewElements = () => {
    const projects = this.props.models;
    const selectedModelId = this.props.selectedModelId;

    return (
      <>
      {projects.length !== 0 ?
        <TreeView  defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
>
          <TreeItem
            nodeId="projects"
            label={`${this.translate("projects")} (${projects.length})`}
          >
            {projects.map(model => (
              <div className="arecnt" key={model.id}>
              <TreeItem key={model.id} nodeId={(model.id).toString()} style={{ color: ((selectedModelId !== null )&& model.id === selectedModelId) && "#3f51b5" }}label={(model.name).toString()} >
                
                  <div className="wk">
                  { (selectedModelId !== null )&& model.id !== selectedModelId &&
                  <>
                  <DeleteRoundedIcon style={{fontSize:"1.2rem"}} variant="contained" color="secondary" onClick={() => this.deleteModel(model)} ></DeleteRoundedIcon>
                  
                  </>
                }
                <EditIcon style={{fontSize:"1.2rem"}} variant="contained" color="primary" onClick={()=> this.onOpenModal(model)} ></EditIcon>
                  </div>
                  
                <div>
                <ElementTreeItem
                  selectedModelId={selectedModelId}
                  modelId={model.id}
                  elements={model.elements}
                  elementName={this.translate("elements")}
                  elementCustomName={this.props.customElementNodeParent}
                  elementInf={"elementsNodeParent"}
                  changete={this.props.changeModelElement}
                  modal={this.onOpenModal}
                />
                <ElementTreeItem
                  selectedModelId={selectedModelId}
                  modelId={model.id}
                  elements={model.supports}
                  elementName={this.translate("supports")}
                  elementCustomName={this.props.customSupportNodeParent}
                  elementInf={"supportsNodeParent"}
                  changete={this.props.changeModelElement}
                  modal={this.onOpenModal}
                />
                <ElementTreeItem
                  selectedModelId={selectedModelId}
                  modelId={model.id}
                  elements={model.loads}
                  elementName={this.translate("loads")}
                  elementCustomName={this.props.customLoadNodeParent}
                  elementInf={"loadsNodeParent"}
                  changete={this.props.changeModelElement}
                  modal={this.onOpenModal}
                />
                <ElementTreeItem
                selectedModelId={selectedModelId}
                  modelId={model.id}
                  elements={model.nodes}
                  elementName={this.translate("nodes")}
                  elementCustomName={this.props.customNodeNodeParent}
                  elementInf={"nodesNodeParent"}
                  changete={this.props.changeModelElement}
                  modal={this.onOpenModal}
                />
                </div>
              </TreeItem>
              
              
              </div>))}
          </TreeItem>
        </TreeView>
        : null}
      </>
    );
  };
 
  onOpenModal = element => {
    this.setState({
      openNameModal: true,
      selectedElement: element
    });
  };

  

  onCloseModal = () => {
    this.setState({
      openNameModal: false,
      selectedElementId: false,
    });
  };

  handleChangeAgentInput=(event)=> {
    this.setState({agentInput: event.target.value});
  }

  showModal = () => {
    return (<>
      <Modal open={this.state.openNameModal} onClose={this.onCloseModal} center>
        
      <div className="modalInformationWrapper">
            <label className="programInformation">
            {this.translate("changeName")}
            </label>
  
            <div className="fieldsWrapper">
              <div className="elementField">
                <label>
                {this.translate("changeName")}
                </label>
                <input type="text"
                defaultValue={this.state.selectedElement.name}
                ref={elementNameInput =>
                  (this.elementNameInput = elementNameInput)
                }
                />
              </div>
              <div className="buttonsWrapper">
                <button className="buttonConfirm" onClick={() => this.handleSubmitName()}><span>OK</span></button>
                <button className="buttonCancel" onClick={() => this.onCloseModal()}><CloseIcon/></button>
              </div>
            </div>
          </div>
      </Modal>
      </>
    );
  };

  render() {
    return <>{this.showTreeViewElements()}
     {this.showModal()}
    </>;
  }
}

const ElementTreeItem = props => {
  let handleToUpdate = props.changete;
  let openModal = props.modal;
  let elementCustomName= props.elementCustomName;
  let modelId = props.modelId;
  let selectedModelId = props.selectedModelId;
  
  return (
    <TreeItem
      nodeId={props.elementName + props.modelId}
      // eslint-disable-next-line eqeqeq
      label={`${elementCustomName != undefined ? elementCustomName :props.elementName} (${props.elements.length})`}
    >
      {/* <EditIcon  variant="contained" color="primary"  key={22} onClick={()=>openModal(elementInf)}>ZN</EditIcon> */}
      {props.elements.map(element => (
      <div key={element.id} style = {{display: "flex"}}>
        <TreeItem style= {{paddingRight: "10px"}}key={element.id} nodeId={(element.id).toString()} label={element.name}
          onClick={() => handleToUpdate(element)  } >
        </TreeItem>
      {(selectedModelId !== null ) && modelId === selectedModelId &&
        <EditIcon style={{fontSize:"1.2rem"}} variant="contained" color="primary" key={element.id+element.name} onClick={()=>openModal(element)}>ZN</EditIcon>
      }

      </div>
          
      ))}
    
    </TreeItem>
  );
};

const mapDispatchToProps = dispatch => ({
  changeModelElement: modelElement =>dispatch(mefaActions.changeModelElement(modelElement)),
  deleteModel: model =>dispatch(mefaActions.deleteModel(model)),
  changeModel: model => dispatch(mefaActions.changeModel(model)),
  updateLoadElementName: (index, value) => dispatch(mefaActions.updateLoadElementName(index, value)),
  updateSectionElementName: (index, value) => dispatch(mefaActions.updateSectionElementName(index, value)),
  updateSupportElementName: (index, value) => dispatch(mefaActions.updateSupportElementName(index, value)),
  updateNodeElementName: (index, value) => dispatch(mefaActions.updateNodeElementName(index, value)),
  updateModelName: (index, value) => dispatch(mefaActions.updateModelName(index, value)),
  updateElementNodeName: (item) => dispatch(mefaActions.updateSectionNodeName(item)),
  updateLoadNodeName: (item) => dispatch(mefaActions.updateLoadNodeName(item)),
  updateSupportNodeName: (item) => dispatch(mefaActions.updateSupportNodeName(item)),
  updateNodeNodeName: (item) => dispatch(mefaActions.updateNodeNodeName(item))
});

let existNodeElement =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].elementNodeParent
  else return null
}

let existNodeNode =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].nodeNodeParent
  else return null
}

let existNodeLoad =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].loadNodeParent
  else return null
}

let existNodeSupport =(state, numberOfModel)=> {
  if (numberOfModel !== 0) return state.mefa.present.models[state.mefa.present.selectedModelIndex].supportNodeParent
  else return null
}
const mapStateToProps = state => ({
  models: state.mefa.present.models,
  selectedLanguage: state.mefa.present.selectedLanguage,
  selectedModelId: state.mefa.present.selectedModelId,
  customElementNodeParent:existNodeElement(state,state.mefa.present.models.length),
  customNodeNodeParent:existNodeNode(state,state.mefa.present.models.length),
  customSupportNodeParent:existNodeSupport(state,state.mefa.present.models.length),
  customLoadNodeParent:existNodeLoad(state,state.mefa.present.models.length),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ProjectsTreeView,
  ElementTreeItem
);
