class SectionElement {
  constructor(id, name, lengthL) {
    this.id = id;
    this.name = name;
    this.lengthL = lengthL;
    this.classType="SectionElement";
  }
}

export default SectionElement;
