import React from 'react';
import FileReaderInput from 'react-file-reader-input';
import { connect } from "react-redux";
import mefaActions from "../../../app/mefa-app/duck/actions";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
 
 
class SimpleReactFileUploader extends React.Component {
  handleChange = (event, results) => {
      event.persist();
     
      results.forEach(result => {
        const [file] = result;

        if(file.target.result) {
          try {
            let savedProject=JSON.parse(file.target.result)
            this.props.loadProject(savedProject);
          } catch(e) {}
      }
      });
  }
  render() {
    
    return (
      <button style={{marginLeft:"3px",borderRadius:"4px",border:"2px solid gray", backgroundColor:"whitesmoke",    padding: "1px 5px"}}>
        <FileReaderInput as="binary" id="my-file-input" onChange={this.handleChange}>
          
            <OpenInBrowserIcon style={{fontSize:"1.8rem",color: "rgb(0 0 0 / 74%)"}}fontSize="large"/>
          
        </FileReaderInput>
        </button> 
    );
  }
}

const mapDispatchToProps = dispatch => ({
    loadProject: (value) => dispatch(mefaActions.loadProjectFromFile(value)),
  })
  
  const mapStateToProps = state => ({
    stateC: state.mefa.present
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(SimpleReactFileUploader)