import React from "react";
import "./DeflectionPanel.css";
import * as THREE from "three";
import mefaActions from "../../../app/mefa-app/duck/actions";
import { connect } from "react-redux";
import fontHelvetiker from "../../../components/fonts/helvetiker_typeface.json";
import LineMaterial from "../../../lib/LineMaterial";
import LineGeometry from "../../../lib/LineGeometry";
import Line2 from "../../../lib/Line2";

const loader = new THREE.FontLoader();
const font = loader.parse(fontHelvetiker);

let textMaterialNode = new THREE.MeshBasicMaterial({ color: "red" });

var matLine2 = new LineMaterial( {
  color: 'red',
  linewidth: 1.5, 
} );

const textSize = 0.1;
const textHeight = 0.0008;

let w = 475;
let h = 90;
let globalScale = 4.5
let frustumSize = 1.1;
let init=0;
class DeflectionPanel extends React.Component {
state={
  deflectionScale: globalScale,
  sumOfElement: 1
};


  abc=()=>{ this.setState({deflectionScale: this.state.deflectionScale -0.02})
}

resize=()=>{
    
  if(this.mount && this.mount.clientWidth !== null){
    let width = this.mount.clientWidth-1;
    let height = 90
   
    let sum=0;
    this.props.beamElements.forEach(element => {
      sum=sum+element.lengthL;
    });

    globalScale =width/110;
    this.setState({
      deflectionScale: globalScale*(1/sum)
    })
  
    let aspect = width / height;
    this.renderer.setSize(width, height);
    this.camera.left = ((frustumSize * aspect) / -2) + globalScale/2;
    this.camera.right = ((frustumSize * aspect) / 2) + globalScale/2;
    this.camera.top = frustumSize / 2;
    this.camera.bottom = frustumSize / -2;
    
    this.camera.updateProjectionMatrix();
  }
}
  calculateOffset(index, sectionElementOffset){
    if(index > 0){
      sectionElementOffset = sectionElementOffset + this.props.beamElements[index-1].lengthL;
    }
  return sectionElementOffset
  }
  drawElements=(element,offsetSectionElement)=>{
    let positions = [];
    
    var matLine = new LineMaterial( {
      color: 'black',
      linewidth: 2.5
    } );

    matLine.resolution.set(w,h);
  
    positions.push(offsetSectionElement*this.state.deflectionScale, 0, 0 );
    positions.push((offsetSectionElement+element.lengthL)*this.state.deflectionScale , 0, 0 );

    var geometry = new LineGeometry();

    geometry.setPositions( positions );

    var line = new Line2( geometry, matLine );
    this.scene.add( line );
  }

  drawPoints=()=>{

  
    if(this.props.mainCharPointsDeflection.length > 0){
      let positions = [];
    
      matLine2.resolution.set(w,h);
      
     let offsetSectionElement=0;
      for(let j = 0; j < this.props.mainCharPointsDeflection.length; j++){

           let points =this.props.mainCharPointsDeflection[j];
        
           let elementLength =this.props.beamElements[j].lengthL
           offsetSectionElement = this.calculateOffset(j, offsetSectionElement)

           let scaleY= Math.abs(this.props.globalMaxV) > Math.abs(this.props.globalMinV) ? Math.abs(this.props.globalMaxV):Math.abs(this.props.globalMinV);

           for(let i = 0; i < points.length; i++){
              // eslint-disable-next-line eqeqeq
              if((points[i] === this.props.globalMaxV || points[i] === this.props.globalMinV ) && points[i].toFixed(2) != 0)
              {
                let textGeometry = new THREE.TextGeometry(Number(points[i]).toFixed(1),
                {font: font, size: textSize, height: textHeight,});
                let textMesh = new THREE.Mesh(textGeometry, textMaterialNode);
                textMesh.name="tr"
  
                points[i] > 0 
                ? textMesh.position.set(((((i/(points.length-1)*elementLength)+offsetSectionElement)-0.1/this.state.deflectionScale))*this.state.deflectionScale,-points[i]/(scaleY*3.0) -0.15, 0)
                : textMesh.position.set(((((i/(points.length-1)*elementLength)+offsetSectionElement)-0.1/this.state.deflectionScale))*this.state.deflectionScale, -points[i]/(scaleY*3.0) +0.05, 0)
  
                this.scene.add(textMesh);
            }
              if(i === 0 ){positions.push(offsetSectionElement*this.state.deflectionScale, 0, 0.1);}

              positions.push(((i/(points.length-1)*elementLength)+offsetSectionElement)*this.state.deflectionScale, -points[i]/(scaleY*3.0), 0.1);

              if(i === (points.length-1) ){positions.push((offsetSectionElement+elementLength)*this.state.deflectionScale, 0, 0.1);}
            }
      }
      let geometry = new LineGeometry();
   geometry.setPositions( positions );
  
   let line = new Line2( geometry, matLine2 );
   line.name="tr"
   this.scene.add( line );
  }
}

  drawNodes=(sectionElementOffset)=>{
    var geometryP = new THREE.SphereGeometry(0.045, 22, 22);
    var material = new THREE.MeshBasicMaterial({ color: "black" });
    var sphere = new THREE.Mesh(geometryP, material);
    sphere.position.set((sectionElementOffset)*this.state.deflectionScale, 0, 0);
     
    this.scene.add(sphere);
}
  componentDidMount() {
    let width = this.mount.clientWidth;
    let height = h;

    this.scene = new THREE.Scene();

    
    let aspect = width / height;

    this.camera = new THREE.OrthographicCamera(
      ((frustumSize * aspect) / -2)+2.25,
      ((frustumSize * aspect) / 2)+2.25,
      frustumSize / 2,
      frustumSize / -2,
      0.01,
      100
    );

    this.scene.add(this.camera);
    this.camera.position.copy(new THREE.Vector3(0, 0, 1));

    this.camera.lookAt(new THREE.Vector3(0, 0, -1));
    this.renderer = new THREE.WebGLRenderer({ antialias: true,preserveDrawingBuffer: true });
    this.renderer.setClearColor("#FFFFFF");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    window.addEventListener("resize",this.resize );

   // this.mount.addEventListener('scroll', this.abc);
   
    this.renderScene();
    this.resize();
  }

  renderScene(){
    var animate = () => {
      requestAnimationFrame(animate);
     
      this.renderer.render(this.scene, this.camera);
    };
    animate();
  }

  componentDidUpdate(prevProps,stateprev) {
    if(init === 0){
      this.resize();
      init=1;
      }
    if( this.props.currentStateDeflectionChart !== prevProps.currentStateDeflectionChart ||
     this.state.deflectionScale !== stateprev.deflectionScale )
    {
      let sum=0;
      this.props.currentStateModel.elements.forEach(element => {
        sum=sum+element.lengthL;
      });

    if( this.state.sumOfElement !== sum )
    {
       this.setState({
         sumOfElement: sum,
          deflectionScale: (globalScale*(1/(sum)))
       })
    }

   if(!this.props.GLOBAL_MODE){

      this.scene.remove.apply(this.scene, this.scene.children);

    }else {
      // eslint-disable-next-line array-callback-return
      let tab=this.scene.children.filter(ele=>{
        if(ele.name === "tr"){
          return ele;
        
        } });
      this.scene.remove.apply(this.scene, tab);

      }
    if(!this.props.GLOBAL_MODE){
    let sectionElementOffset = 0;
   
    this.props.beamElements.forEach((element, index) => {
      sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
          this.drawElements(element,sectionElementOffset)
      });

      sectionElementOffset = 0;

      this.props.beamNodes.forEach((node, index) => {
        sectionElementOffset = this.calculateOffset(index, sectionElementOffset)
          this.drawNodes(sectionElementOffset)
       });
      }
  
       this.drawPoints();
  
       this.renderer.render(this.scene, this.camera);
      }
      
  }

  render() {
    return (
      <>
        <div className="deflectionChart"  ref={mount => {this.mount = mount;}}
        />
      </>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  changeModelElement: value => dispatch(mefaActions.changeModelElement(value)),
  updateLoadL1Element: (index, value) =>dispatch(mefaActions.updateLoadL1Element(index, value))
});

const mapStateToProps = state => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  beamElements:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].elements,
  beamLoads:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].loads,
  beamSupports:
    state.mefa.present.models[state.mefa.present.selectedModelIndex].supports,
  beamNodes: state.mefa.present.models[state.mefa.present.selectedModelIndex].nodes,
  mainCharPointsDeflection: state.mefa.present.charPointsDeflectionTemp,
  globalMaxV: state.mefa.present.globalMaxV,
  globalMinV: state.mefa.present.globalMinV,
  currentStateModel: state.mefa.present.models[state.mefa.present.selectedModelIndex],
  currentStateDeflectionChart: state.mefa.present.charPointsDeflectionTemp,
  GLOBAL_MODE: state.mefa.present.GLOBAL_MODE
});

export default connect(mapStateToProps, mapDispatchToProps)(DeflectionPanel);