const materials = [
  {
    id: 0,
    name: "S250GD-Z275-N-A",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 250.0
  },
  {
    id: 1,
    name: "S235JR",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 235.0
  },
  {
    id: 2,
    name: "V2A",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 220.0
  },
  {
    id: 3,
    name: "V4A",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 240.0
  },
  {
    id: 4,
    name: "S235",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 235.0
  },
  {
    id: 5,
    name: "S275J2H",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 275.0
  },
  {
    id: 6,
    name: "S235JRH",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 235.0
  },
  {
    id: 7,
    name: "DX51D+Z",
    moduleOfElastic: 210.0,
    yieldStrengthFyk: 140.0
  }
];

export default materials;