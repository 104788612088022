const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
const CHANGE_PROFILE = "CHANGE_PROFILE";
const CHANGE_MODEL_ELEMENT = "CHANGE_MODEL_ELEMENT";
const CHANGE_MODEL = "CHANGE_MODEL";
const CHANGE_SELECTED_MODEL_ID = "CHANGE_SELECTED_MODEL_ID";

const UPDATE_LOAD_L1_ELEMENT = "UPDATE_LOAD_L1_ELEMENT";
const UPDATE_LOAD_L1_AND_PARENT_ID__ELEMENT ="UPDATE_LOAD_L1_AND_PARENT_ID__ELEMENT";
const UPDATE_LOAD_L2_ELEMENT = "UPDATE_LOAD_L2_ELEMENT";
const UPDATE_LOAD_FORCE_ELEMENT = "UPDATE_LOAD_FORCE_ELEMENT";
const UPDATE_SECTION_ELEMENT = "UPDATE_SECTION_ELEMENT";
const UPDATE_MODEL = "UPDATE_MODEL";

const UPDATE_SECTION_ELEMENT_NAME ="UPDATE_SECTION_ELEMENT_NAME";
const UPDATE_LOAD_ELEMENT_NAME ="UPDATE_LOAD_ELEMENT_NAME";
const UPDATE_SUPPORT_ELEMENT_NAME ="UPDATE_SUPPORT_ELEMENT_NAME";
const UPDATE_NODE_ELEMENT_NAME ="UPDATE_NODE_ELEMENT_NAME";

const CHANGE_ROTATION = "CHANGE_ROTATION";
const CHANGE_DISPLACEMENT = "CHANGE_DISPLACEMENT";

const CHANGE_MODEL_ELEMENT_BY_ID = "CHANGE_MODEL_ELEMENT_BY_ID";

const ADD_SECTION_ELEMENT = "ADD_SECTION_ELEMENT";
const DELETE_SECTION_ELEMENT = "DELETE_SECTION_ELEMENT";

const ADD_SUPPORT_ELEMENT = "ADD_SUPPORT_ELEMENT";
const ADD_NODE_ELEMENT = "ADD_NODE_ELEMENT";

const ADD_LOAD_ELEMENT = "ADD_LOAD_ELEMENT";
const ADD_MOMENT_LOAD_ELEMENT = "ADD_MOMENT_LOAD_ELEMENT";
const ADD_DISTRIBUTED_LOAD_ELEMENT = "ADD_DISTRIBUTED_LOAD_ELEMENT";
const ADD_CONCENTRATED_LOAD_ELEMENT = "ADD_CONCENTRATED_LOAD_ELEMENT";
const DELETE_LOAD_ELEMENT = "DELETE_LOAD_ELEMENT";

const UPDATE_SUPPORT_ELEMENT_LEFT = "UPDATE_SUPPORT_ELEMENT_LEFT";
const UPDATE_SUPPORT_ELEMENT_RIGHT = "UPDATE_SUPPORT_ELEMENT_RIGHT";

const ADD_MODEL = "ADD_MODEL";
const ADD_INITIAL_MODEL = "ADD_INITIAL_MODEL";
const DELETE_MODEL = "DELETE_MODEL";
const UPDATE_MODEL_NAME = "UPDATE_MODEL_NAME";

const LOAD_PROJECT_FROM_FILE ="LOAD_PROJECT_FROM_FILE";

const UPDATE_SUPPORT_NODE_NAME = "UPDATE_SUPPORT_NODE_NAME";
const UPDATE_NODE_NODE_NAME = "UPDATE_NODE_NODE_NAME";
const UPDATE_SECTION_NODE_NAME = "UPDATE_SECTION_NODE_NAME";
const UPDATE_LOAD_NODE_NAME = "UPDATE_LOAD_NODE_NAME";

const UPDATE_SUPPORT_REACTIONS = "UPDATE_SUPPORT_REACTIONS";
const UPDATE_SUPPORT_ELEMENT = "UPDATE_SUPPORT_ELEMENT";

const CHANGE_CHAR_POINTS_MOMENT_TEMP = "CHANGE_CHAR_POINTS_MOMENT_TEMP";
const CHANGE_CHAR_POINTS_DEFLECTION_TEMP = "CHANGE_CHAR_POINTS_DEFLECTION_TEMP";

const CHANGE_M_V = "CHANGE_M_V";

const UPDATE_PDF_DATA = "UPDATE_PDF_DATA";

const CHANGE_SAFTLY_FACTOR = "CHANGE_SAFTLY_FACTOR";

const CHANGE_DEFLECTION_ARROW = "CHANGE_DEFLECTION_ARROW";

const CHANGE_GLOBAL_OFFSET="CHANGE_GLOBAL_OFFSET";

const UPDATE_ALL_GLOBAL_DATA="UPDATE_ALL_GLOBAL_DATA";

const CHANGE_GLOBAL_MODE="CHANGE_GLOBAL_MODE";

const CHANGE_GLOBAL_MASS_LOAD="CHANGE_GLOBAL_MASS_LOAD";

const CHANGE_BENDING_MOMENT_VALUE_MODE = "CHANGE_BENDING_MOMENT_VALUE_MODE";

export default {
  CHANGE_LANGUAGE,
  CHANGE_PROFILE,
  CHANGE_MODEL_ELEMENT,
  CHANGE_MODEL,
  CHANGE_SELECTED_MODEL_ID,

  CHANGE_ROTATION,
  CHANGE_DISPLACEMENT,

  UPDATE_LOAD_L1_ELEMENT,
  UPDATE_LOAD_L1_AND_PARENT_ID__ELEMENT,
  UPDATE_LOAD_L2_ELEMENT,
  UPDATE_LOAD_FORCE_ELEMENT,
  UPDATE_SECTION_ELEMENT_NAME,
  UPDATE_LOAD_ELEMENT_NAME,
  UPDATE_SUPPORT_ELEMENT_NAME,
  UPDATE_NODE_ELEMENT_NAME, 

  UPDATE_SUPPORT_REACTIONS,
  UPDATE_SUPPORT_ELEMENT,

  UPDATE_MODEL,
  UPDATE_MODEL_NAME,

  UPDATE_SECTION_ELEMENT,
  CHANGE_MODEL_ELEMENT_BY_ID,

  ADD_MODEL,
  ADD_INITIAL_MODEL,

  ADD_SECTION_ELEMENT,
  ADD_SUPPORT_ELEMENT,
  ADD_NODE_ELEMENT,
  ADD_LOAD_ELEMENT,

  ADD_MOMENT_LOAD_ELEMENT,
  ADD_DISTRIBUTED_LOAD_ELEMENT,
  ADD_CONCENTRATED_LOAD_ELEMENT,

  DELETE_MODEL,

  DELETE_LOAD_ELEMENT,
  DELETE_SECTION_ELEMENT,

  UPDATE_SUPPORT_ELEMENT_LEFT,
  UPDATE_SUPPORT_ELEMENT_RIGHT,

  LOAD_PROJECT_FROM_FILE,

  UPDATE_SUPPORT_NODE_NAME,
  UPDATE_NODE_NODE_NAME,
  UPDATE_SECTION_NODE_NAME,
  UPDATE_LOAD_NODE_NAME,

  CHANGE_CHAR_POINTS_MOMENT_TEMP,
  CHANGE_CHAR_POINTS_DEFLECTION_TEMP,

  CHANGE_M_V,

  UPDATE_PDF_DATA,
  CHANGE_SAFTLY_FACTOR,
  CHANGE_DEFLECTION_ARROW,

  CHANGE_GLOBAL_OFFSET,
  UPDATE_ALL_GLOBAL_DATA,
  CHANGE_GLOBAL_MODE,
  CHANGE_GLOBAL_MASS_LOAD,
  CHANGE_BENDING_MOMENT_VALUE_MODE
};
