import React from 'react';
import './App.css';
import Explorer from './components/mainPanels/explorer/Explorer'
import ProfilePanel from './app/mefa-app/components/ProfilePanel';
import ModelToolBar from './components/mainPanels/modelToolBar/ModelToolBar';
import actionss from './app/mefa-app/duck/actions'
import { connect } from 'react-redux'
import Tools from './components/mainPanels/tools/Tools';
import 'react-dropdown/style.css'
import MenuTools from './components/mainPanels/menuTools/MenuTools';
import translateText from './components/LanguageTranslator';
import SectionElement from '../src/app/classes/element/SectionElement'
import NodeElement from '../src/app/classes/node/NodeElement'
import SupportElement from '../src/app/classes/support/SupportElement'
import ConcentratedLoadElement from '../src/app/classes/load/ConcentratedLoadElement';
import BeamModel from '../src/app/classes/model/BeamModel'
import PanelSummary from './components/mainPanels/summaryPanel/SummaryPanel';
import Beam from './components/mainPanels/beam/Beam';
import BendingMomentPanel from './components/mainPanels/bendingMomentPanel/BendingMomentPanel';
import DeflectionPanel from './components/mainPanels/deflectionPanel/DeflectionPanel';
import PdfRaport from './components/documents/PdfRaport'
import html2canvas from 'html2canvas';
import CloseIcon from '@material-ui/icons/Close';
import Modal from "react-responsive-modal";


let profile= {
  id: 1,
    name: "22/16/2,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 0.78,
    centroidDistanceEo: 0.41,
    centroidDistanceEu: 1.19,
    centroidDistanceEz: 1.1,
    radiOfGyrationIy: 0.38,
    radiOfGyrationIz: 0.84,
    momentsOfInteriaJy: 0.11,
    momentsOfInteriaJz: 0.55,
    sectionModuliWyo: 0.28,
    sectionModuliWyu: 0.1,
    sectionModuliWz: 0.5,
    weight: 0.008
}

const options = [
  { value: "pl", label: "Polski" },
  { value: "en", label: "English" },
  { value: "de", label: "Deutsch" },
  { value: "da", label: "Dansk" },
  { value: "it", label: "Italiano" },
  { value: "nl", label: "Nederlands" },
  { value: "hu", label: "Hungarian" },
  { value: "lv", label: "Latvian" },

];


class MefaApp extends React.Component {
state={
  beamChart: 0,
  bendingMomentChart: 0,
  deflectionChart: 0,
  openModal: false,
  generatedDocumentModal: false,
  agentInput: "",
  objectInput: "",
  adviceInput: "",
  loading: true
}

sectionInputValue = React.createRef();

componentDidMount() {
  this.fakeRequest().then(() => {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.remove();  
      this.setState({ loading: false });
    }
  });
}

fakeRequest = () => {
  return new Promise(resolve => setTimeout(() => resolve(), 1000));
};

translate = (text,selectedLanguage) => {
    return (translateText(text, selectedLanguage));
}

create_UUID=()=>{
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        // eslint-disable-next-line no-mixed-operators
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

cLanguage = (event) => {
    this.props.changeLanguage(event)

    let parentID= this.create_UUID();
        let model = new BeamModel(this.create_UUID(), this.translate("modelChild",event.value) +  ' ' +(this.props.selectedModelId+1), 0, 200, profile,
        [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild",event.value) +  ' ' +1, 1.0, 0.5,parentID)],
        [new SupportElement(this.create_UUID(), this.translate("supportChild",event.value) + ' ' + 1, 0), new SupportElement(this.create_UUID(), this.translate("supportChild",event.value) + ' ' + 2, 0)],
        [new SectionElement(parentID, this.translate("elementChild",event.value) + ' ' + 1, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild",event.value) + ' ' + 1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild",event.value) + ' ' + 2, 1)])

    this.props.addInitialModel(model);
    this.props.changeSelectedModelId(model.id);
}

downloaded=()=>{
  this.setState({
    beamChart: 0,
    bendingMomentChart: 0,
    deflectionChart: 0,
    generatedDocumentModal: false,
  })
}

detectLanguage = () => {
  let browserLanguage = ( navigator.languages && navigator.languages[0] )
  || ( navigator.language || navigator.userLanguage ); 

  let programLangauge ="en";

  options.forEach(language => {
    if(browserLanguage.includes(language.value)){
      programLangauge = language.value;
    }
  });
  
  this.cLanguage({ value: `${programLangauge}`})
}

loadBendingMomentValue =(value)=>{
  if(this.props.bendingMomentValueMode){
    this.sectionInputValue.value =  value;
  }
}

loadCanvases=()=> {
  this.onOpenModal();

  window.scrollTo({ top: 0 });
  

  const inputBeam = document.getElementsByClassName('BeamClass');

  html2canvas(inputBeam[0]).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      this.setState({beamChart:imgData})});
  
  const inputBendingMoment = document.getElementsByClassName('bendingMomentChart');
  
  html2canvas(inputBendingMoment[0]).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      this.setState({bendingMomentChart:imgData})});
  
  const inputDeflection = document.getElementsByClassName('deflectionChart');
  
  html2canvas(inputDeflection[0]).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      this.setState({deflectionChart:imgData})});
  }

  onOpenModal = () => {
    this.setState({
    openModal: true
  });
  };

  onCloseModal = () => {
      this.setState({
      openModal: false,
      generatedDocumentModal: true
      });
  };

  handleChangeAgentInput=(event)=> {
    this.setState({agentInput: event.target.value});
  }

  handleChangeObjectInput=(event)=> {
    this.setState({objectInput: event.target.value});
  }

  handleChangeAdviceInput=(event)=> {
    this.setState({adviceInput: event.target.value});
  }

  changeBendingMomentValueMode= ()=>{

    this.props.changeBendingMomentValueMode(!this.props.bendingMomentValueMode)
  
    if(!this.props.bendingMomentValueMode){
      document.getElementsByClassName("bendingMomentChart")[0].style.cursor = "pointer";
    }else{
      document.getElementsByClassName("bendingMomentChart")[0].style.cursor = "auto";
    }
   
  }

  showModal = () => {
    const language = this.props.selectedLanguage;
    return (
        <Modal
          open={this.state.openModal}
          onClose={this.onCloseModal}
          center
        >
          <div className="modalInformationWrapper">
            <label className="programInformation">
              {this.translate("programInformation", language)}
            </label>
  
            <div className="fieldsWrapper">
              <div className="elementField">
                <label>
                  {this.translate("writerH", language)}
                </label>
                <input type="text"
                 value={this.state.agentInput}
                 onChange={this.handleChangeAgentInput}
                />
              </div>
  
              <div className="elementField">
                <label>
                  {this.translate("objectH", language)}
                </label>
                <input
                  type="text"
                  value={this.state.objectInput}
                  onChange={this.handleChangeObjectInput}
                />
              </div>
  
              <div className="elementField">
                <label>
                  {this.translate("adviceH", language)}
                </label>
                <textarea
                  rows="4"
                  type="text"
                  style={{resize: "none"}}
                  value={this.state.adviceInput}
                  onChange={this.handleChangeAdviceInput}
                />
              </div>
              <div className="buttonsWrapper">
                <button className="buttonConfirm" onClick={() => this.onCloseModal()}><span>OK</span></button>
                <button className="buttonCancel" onClick={()=>this.downloaded()}><CloseIcon/></button>
              </div>
            </div>
          </div>
        </Modal>
    );
  };
  
  
  panel = () => {
    const numberOfModel = this.props.numberOfModel;
    const language = this.props.selectedLanguage;

    return (
      <div className="parent">
          <div className="menuToolsPanel"><MenuTools/></div>
          <div className="explorerPanel"> <Explorer /></div>
         
          <div className="beamPanel" style={{ display: !numberOfModel && "none" }}><Beam/></div>
          
          { numberOfModel !== 0 
            &&
              <>
                <div className="toolsPanel">
                  <Tools/> 
                </div>

                <div className="modelToolBarPanel">
                  <ModelToolBar/>
                </div>
                
                <div className="bendingMomentPanel" >
                  <div className="momentLabelWrapper">
                  <label style={{marginBottom: ".2rem", marginLeft:"6px"}}>
                      {this.translate("bendingMomentChartHeader",language)}
                    </label>

                    <button class="bendingMomentValueModeButton" onClick={() => this.changeBendingMomentValueMode()} style={!this.props.bendingMomentValueMode ? { background: "#337ab7", color: "white"} : { background: "#f90b0d9c", color: "white"}}>L</button>
                    
                    {this.props.bendingMomentValueMode &&  <input
                        type="double"
                        class="bendingMomentValueModeInput"
                        defaultValue={Number(parseFloat(0))}
                        ref={sectionInputValue =>(this.sectionInputValue = sectionInputValue)
                        }
                      />
                    }

                  </div>
                  <BendingMomentPanel  loadBendingMomentValue={this.loadBendingMomentValue}  />
                </div> 

                <div className="deflectionPanel">
                  <div className="deflectionLabelWrapper">
                    <label style={{marginBottom: ".2rem", marginLeft:"6px"}}>
                      {this.translate("deflectionChartHeader",language)}
                    </label>
                  </div>
                  <DeflectionPanel />
                </div>

                <div className="profilePanel">
                  <div className="specialDiv">
                    <ProfilePanel />
                    </div>
                </div>

                <div className="panelSummaryPanel"> 
                  <PanelSummary 
                    loadCanvases={this.loadCanvases} 
                    numberOfModel={this.props.numberOfModel} />
                </div>

              </>
          }
        
        

        { (this.state.bendingMomentChart && this.state.beamChart && this.state.deflectionChart )
          ? this.showModal() 
          : false 
        }
           
        { (this.state.bendingMomentChart && this.state.beamChart && this.state.deflectionChart && this.state.generatedDocumentModal) 
          ? <PdfRaport 
            advice={this.state.adviceInput} 
            agent={this.state.agentInput} 
            object={this.state.objectInput}
            downloaded={this.downloaded} 
            bc={this.state.beamChart} 
            dc={this.state.deflectionChart } 
            bmc={this.state.bendingMomentChart }/> 
          : null }
        
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; 
    }
    return (
    <>
      {this.props.selectedLanguage === null ? this.detectLanguage() : this.panel() }
    </>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  changeSectionElement: (value) => dispatch(actionss.changeModelElement(value)),
  changeLanguage: language => dispatch(actionss.changeLanguage(language)),
  updateLoadL1Element: (index, value) => dispatch(actionss.updateLoadL1Element(index, value)),
  updateModel: model => dispatch(actionss.updateModel(model)),
  addInitialModel: model => dispatch(actionss.addInitialModel(model)),
  changeSelectedModelId: model=> dispatch(actionss.changeSelectedModelId(model)),
  changeModelElement: ele => dispatch(actionss.changeModelElement(ele)),
  changeBendingMomentValueMode: value =>  dispatch(actionss.changeBendingMomentValueMode(value))
})

const mapStateToProps = state => ({
  selectedModelElement: state.mefa.present.selectedModelElement,
  selectedLanguage: state.mefa.present.selectedLanguage,
  selectedModelId:state.mefa.present.selectedModelId,
  url:state.mefa.present.chujtest,
  numberOfModel: state.mefa.present.models.length,
  bendingMomentValueMode: state.mefa.present.BENDING_MOMENT_VALUE_MODE
  
})


export default connect(mapStateToProps, mapDispatchToProps)(MefaApp)