import React from 'react';
import translateText from '../../LanguageTranslator';
import { connect } from 'react-redux';
import './Tools.css';
import mefaActions from '../../../app/mefa-app/duck/actions';
import SectionElement from '../../../app/classes/element/SectionElement'
import NodeElement from '../../../app/classes/node/NodeElement'
import SupportElement from '../../../app/classes/support/SupportElement'
import FixedSupportElement from '../../../app/classes/support/FixedSupportElement'
import NoneSupportElement from '../../../app/classes/support/NoneSupportElement';
import ConcentratedLoadElement from '../../../app/classes/load/ConcentratedLoadElement';
import MomentLoadElement from '../../../app/classes/load/MomentLoadElement';
import BeamModel from '../../../app/classes/model/BeamModel'
import DistributedLoadElement from '../../../app/classes/load/DistributedLoadElement'
import LoadElement from '../../../app/classes/load/LoadElement'
import Modal from "react-responsive-modal";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';


import fotoAddSection from '../../resources/palette_addSpan.gif';
import fotoDeleteSection from '../../resources/palette_removeSpan.gif';
import fotoAddConcentratedLoad from '../../resources/palette_addLoad_concentrated.gif';
import fotoAddMomentLoad from '../../resources/palette_addLoad_moment.gif';
import fotoAddDistributedLoad from '../../resources/palette_addLoad_distributed.gif';
import fotoDeleteLoad from '../../resources/palette_deleteLoad.gif';

import fotoSchema1 from '../../resources/palette_scheme1.gif';
import fotoSchema2 from '../../resources/palette_scheme2.gif';
import fotoSchema3 from '../../resources/palette_scheme3.gif';
import fotoSchema4 from '../../resources/palette_scheme4.gif';
import fotoSchema5 from '../../resources/palette_scheme5.gif';
import fotoSchema6 from '../../resources/palette_scheme6.gif';
import fotoSchema7 from '../../resources/palette_scheme_console1.gif';

import fotoNodeL from '../../resources/palette_changeBC_left.gif';
import fotoNodeFixL from '../../resources/palette_changeBCTo2_left.gif';
import fotoNodeNoneL from '../../resources/palette_changeBCTo3_left.gif';

import fotoNodeR from '../../resources/palette_changeBC_right.gif';
import fotoNodeFixR from '../../resources/palette_changeBCTo2_right.gif';
import fotoNodeNoneR from '../../resources/palette_changeBCTo3_right.gif';

import fotoLogoMefa from "../../resources/mefa-logo.png";

let profile= {
    id: 1,
    name: "22/16/2,0 (fbv)",
    materialName: "S250GD-Z275-N-A",
    shearAreasA: 0.78,
    centroidDistanceEo: 0.41,
    centroidDistanceEu: 1.19,
    centroidDistanceEz: 1.1,
    radiOfGyrationIy: 0.38,
    radiOfGyrationIz: 0.84,
    momentsOfInteriaJy: 0.11,
    momentsOfInteriaJz: 0.55,
    sectionModuliWyo: 0.28,
    sectionModuliWyu: 0.1,
    sectionModuliWz: 0.5,
    weight: 0.008
  }
class Tools extends React.Component {
    state={
        openModal: false
    }

    translate = (text) => {
        return (translateText(text, this.props.selectedLanguage));
    }

    onOpenModal = () => {
        this.setState({
        openModal: true,

        });
    };

    onCloseModal = () => {
        this.setState({
        openModal: false,
        });
    };

    showModal = () => {
        return (
        <>
        <Modal open={this.state.openModal} onClose={this.onCloseModal} center>
        
        <div className="modalInformationWrapper">
              <label style={{padding: "7px 11px"}} className="programInformation">
              <ErrorOutlineIcon style={{width:"1.1em", height: "1.1em"}} />
              </label>
    
              <div className="fieldsWrapper" >
                <div className="elementField" style={{width:"100%", fontSize: "15px", margin: ".5rem", justifyContent:"center", flexDirection:"row"}}>
                <label type="text">{this.translate("supportWarning")}</label>
                </div>
                <div className="buttonsWrapper" style={{justifyContent:"center"}}>
                  <button className="buttonConfirm" onClick={() => this.onCloseModal()}><span>OK</span></button>
                </div>
              </div>
            </div>
        </Modal>
        </>
        );
    };

    handleSubmit = event => {
        event.preventDefault();
        this.onCloseModal();
    };

    compareNumbers2(a, b)
    { return b - a;}

    create_UUID=()=>{
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            // eslint-disable-next-line no-mixed-operators
            return (c ==='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }

    addSection = () => {
        let sectionElement = new SectionElement(this.create_UUID(), this.translate("elementChild") + ' ' + (this.props.beamElements.length + 1), 1);
        let nodeElement = new NodeElement(this.create_UUID(), this.translate("nodeChild") + ' ' + (this.props.beamNodes.length + 1), this.props.beamNodes.length);
        let supportElement = new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + (this.props.beamSupports.length + 1), 1);
        this.props.addSectionElement(sectionElement)
        this.props.addSupportElement(supportElement)
        this.props.addNodeElement(nodeElement)
        this.props.updateSupportElement();
    }

    deleteSection = () =>{
        if(this.props.beamElements.length > 1){
            this.props.deleteSection(this.props.selectedModelElement)
        }
    }
   
    updateFixedSupportLeft = () => {
        let supportElement = new FixedSupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + (1), 1);
        this.props.updateSupportElementLeft(supportElement)
    }
  
    updateFixedSupportRight = () => {
        let supportElement = new FixedSupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + (this.props.beamSupports.length), 1);
        this.props.updateSupportElementRight(supportElement)
    }
    updateNoneSupportRight = () => {
       
        if(((this.props.beamElements.length === 2 ) && this.props.beamSupports[0] instanceof NoneSupportElement) ||
         ((this.props.beamElements.length === 1 ) && !(this.props.beamSupports[0] instanceof FixedSupportElement)  ) )
        {
            this.onOpenModal()
        }else{
            let supportElement = new NoneSupportElement(this.create_UUID(), this.translate("supportChild") + ' '+ (this.props.beamSupports.length), 1);
            this.props.updateSupportElementRight(supportElement)
        }
    }

    updateNoneSupportLeft = () => {
        
        if(((this.props.beamElements.length === 2 ) && this.props.beamSupports[(this.props.beamSupports.length-1)] instanceof NoneSupportElement) ||
         ((this.props.beamElements.length === 1 ) && !(this.props.beamSupports[(this.props.beamSupports.length-1)] instanceof FixedSupportElement) ) )
        {
            this.onOpenModal()
        }else{
            let supportElement = new NoneSupportElement(this.create_UUID(), this.translate("supportChild") + ' ' +(1), 1);
            this.props.updateSupportElementLeft(supportElement)
        }
    }

    updateSupportRight = () => {
        if((this.props.beamElements.length === 1 ) && this.props.beamSupports[0] instanceof NoneSupportElement) 
        
       {
        this.onOpenModal()
       }else{
        let supportElement = new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + (this.props.beamSupports.length), 1);
        this.props.updateSupportElementRight(supportElement)
        }
    }

    updateSupportLeft = () => {
        if((this.props.beamElements.length === 1 ) && this.props.beamSupports[(this.props.beamSupports.length-1)] instanceof NoneSupportElement) 
       {
        this.onOpenModal()
       }else{
        let supportElement = new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + 1, 1);
        this.props.updateSupportElementLeft(supportElement)
       }
    }

    calLoadsPos(sectionElementId){
  
        let loadLengthPosition = [];
        let allLoads = this.props.beamLoads.filter(load => load.parentID === sectionElementId)
        allLoads.forEach(load => {
            loadLengthPosition.push(load.lengthL1)
        })
        let uniqLoadPoints = [...new Set(loadLengthPosition)];
        uniqLoadPoints.sort(this.compareNumbers2);
        let posOn=Number(0.5);
        uniqLoadPoints.forEach(load => {
          if(load === posOn){
            posOn=(Number(posOn).toFixed(2))/2;
          }
        })
      
      return posOn;
    }
     
    addConcentratedLoad = () => {
        if(this.props.selectedModelElement instanceof SectionElement){
           
        let pos= this.calLoadsPos(this.props.selectedModelElement.id)
        let concentratedLoadElement = new ConcentratedLoadElement(this.create_UUID(), 
        this.translate("loadChild")+" "+ (this.props.beamLoads.length + 1), 1,pos,this.props.selectedModelElement.id)
    
        this.props.addLoadElement(concentratedLoadElement)
        }
    }

    addMomentLoad = () => {
        if(this.props.selectedModelElement instanceof SectionElement){
            let pos= this.calLoadsPos(this.props.selectedModelElement.id)
        let momentLoadElement = new MomentLoadElement(this.create_UUID(),
        this.translate("loadChild")+" " +(this.props.beamLoads.length + 1), 0.5, pos,this.props.selectedModelElement.id)
    
        this.props.addLoadElement(momentLoadElement)
    }
    }

    addDistributedLoad= () => {
        if(this.props.selectedModelElement instanceof SectionElement){
            
        let distributedLoadElement = new DistributedLoadElement(this.create_UUID(),
        this.translate("loadChild")+" "+ (this.props.beamLoads.length + 1), 1, 0,1,this.props.selectedModelElement.id)
    
        this.props.addLoadElement(distributedLoadElement)
    }
    }

    deleteLoad=()=>{
        if(this.props.selectedModelElement instanceof LoadElement){
            this.props.deleteLoadElement(this.props.selectedModelElement)
        }
    }

    addSchema1=()=>{
        let parentID= this.create_UUID();
        let model = new BeamModel(this.props.selectedModelId, (this.props.currentModel.name), 0, 200, profile,
        [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1.0, 0.5,parentID)],
        [new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + 1, 0.0), new SupportElement(this.create_UUID(), this.translate("supportChild") + ' ' + 2, 0.0)],
        [new SectionElement(parentID, this.translate("elementChild") + ' ' + 1, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") + ' ' + 1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") + ' ' + 2, 1)])

       this.props.updateModel(model)
    }

    addSchema2=()=>{
        let parentID=this.create_UUID();
        let model = new BeamModel(this.props.selectedModelId, (this.props.currentModel.name), 0, 200, profile,
        [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1.0, 0.25,parentID),
        new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +2, 1.0, 0.5,parentID),
        new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +3, 1.0, 0.75,parentID)],
        [new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +1, 0), new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +2, 0)],
        [new SectionElement(parentID, this.translate("elementChild") +  ' ' +1, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +2, 1)])

       this.props.updateModel(model)
    }

    addSchema3=()=>{
        let parentID1=this.create_UUID();
        let parentID2=this.create_UUID();
        let model = new BeamModel(this.props.selectedModelId, (this.props.currentModel.name), 0, 200, profile,
        [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1.0, 0.5,parentID1),
        new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +2, 1.0, 0.5,parentID2)],
        [new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +1, 0), 
        new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +2, 0),
        new FixedSupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +3, 0)],
        [new SectionElement(parentID1, this.translate("elementChild") +  ' ' +1, 1),new SectionElement(parentID2, this.translate("elementChild") +  ' ' +2, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +2, 1),
        new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +3, 1)])

       this.props.updateModel(model)
    }
    
    addSchema4=()=>{
        let parentID1=this.create_UUID();
       
        let model = new BeamModel(this.props.selectedModelId, (this.props.currentModel.name),0, 200, profile,
        [new DistributedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1.0, 0,1,parentID1)],
        [new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +1, 0), 
        new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +2, 0)],
        [new SectionElement(parentID1, this.translate("elementChild") +  ' ' +1, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +2, 1)])

       this.props.updateModel(model)
    }

    addSchema5=()=>{
        let parentID= this.create_UUID();
        let model = new BeamModel(this.props.selectedModelId, (this.props.currentModel.name), 0, 200, profile,
        [new MomentLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1, 0.5,parentID)],
        [new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +1, 0), new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +2,0)],
        [new SectionElement(parentID, this.translate("elementChild") +  ' ' +1, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +2, 1)])

       this.props.updateModel(model)
    }

    addSchema6=()=>{
        let parentID1=this.create_UUID();
        let parentID2=this.create_UUID();
        let model = new BeamModel(this.props.selectedModelId,(this.props.currentModel.name), 0, 200, profile,
        [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1.0, 1,parentID2)],
        [new FixedSupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +1, 0), 
        new SupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +2,0),
        new NoneSupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +3, 0)],
        [new SectionElement(parentID1, this.translate("elementChild") +  ' ' +1, 1),new SectionElement(parentID2, this.translate("elementChild") +  ' ' +2, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +2, 1),
        new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +3, 1)])

       this.props.updateModel(model)
    }
  
    addSchema7=()=>{
        let parentID= this.create_UUID();
        let model = new BeamModel(this.props.selectedModelId, (this.props.currentModel.name), 0, 200, profile,
        [new ConcentratedLoadElement(this.create_UUID(), this.translate("loadChild") +  ' ' +1, 1.0, 1,parentID)],
        [new FixedSupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +1, 0), 
        new NoneSupportElement(this.create_UUID(), this.translate("supportChild") +  ' ' +2, 0)],
        [new SectionElement(parentID, this.translate("elementChild") +  ' ' +1, 1)],
        [new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +1, 0), new NodeElement(this.create_UUID(), this.translate("nodeChild") +  ' ' +2, 1)])

       this.props.updateModel(model)
    }


    render() {
        return (
        <div className="toolsWrapper">
            <div className="buttonsWrapper">
                <div className="toolSection">
                <label>{this.translate("geometry")}</label> 

                    <div className="geometrySection">
                        <div className="geometryButton"><ToolButton text={this.translate("addSection")} fun={this.addSection} foto={fotoAddSection}/></div>
                        <div className="geometryButton"><ToolButton text={this.translate("deleteSection")}fun={this.deleteSection} foto={fotoDeleteSection}/></div>
                    </div> 
                </div>

                <div className="toolSection">
                    <label> {this.translate("load")}</label> 
            
                    <div className="loadSection">
                        <div className="loadButton"><ToolButton text={this.translate("singleLoad")}fun={this.addConcentratedLoad} foto={fotoAddConcentratedLoad}/></div>
                        <div className="loadButton"><ToolButton text={this.translate("singleMoment")}fun={this.addMomentLoad} foto={fotoAddMomentLoad}/></div>
                        <div className="loadButton"><ToolButton text={this.translate("lineLoad")}fun={this.addDistributedLoad} foto={fotoAddDistributedLoad} /></div>
                        <div className="loadButton"><ToolButton text={this.translate("deleteLoad")}fun={this.deleteLoad} foto={fotoDeleteLoad}/></div>
                    </div>
                </div>

                <div className="toolSection">
                    <label>  {this.translate("support")}</label> 

                    <div className="supportSection">
                        <div className="supportButton"><ToolButton text={this.translate("nodeL")} fun={this.updateSupportLeft} foto={fotoNodeL}/></div>
                        <div className="supportButton"><ToolButton text={this.translate("nodeR")} fun={this.updateSupportRight} foto={ fotoNodeR}/></div>
                        <div className="supportButton"><ToolButton text={this.translate("fixNodeL")} fun={this.updateFixedSupportLeft} foto={fotoNodeFixL}/></div>
                        <div className="supportButton"><ToolButton text={this.translate("fixNodeR")} fun={this.updateFixedSupportRight} foto={fotoNodeFixR}/></div>
                        <div className="supportButton"><ToolButton text={this.translate("noneNodeL")} fun={this.updateNoneSupportLeft} foto={fotoNodeNoneL}/></div>
                        <div className="supportButton"><ToolButton text={this.translate("noneNodeR")} fun={this.updateNoneSupportRight} foto={fotoNodeNoneR}/></div>
                    </div> 
                </div>

                <div className="toolSection">
                    <label>  {this.translate("schemas")}</label>

                    <div className="schemaSection">
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase1")} fun={this.addSchema1} foto={fotoSchema1}/></div>
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase2")} fun={this.addSchema2} foto={fotoSchema2}/></div>
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase3")} fun={this.addSchema3} foto={fotoSchema3}/></div>
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase4")} fun={this.addSchema4} foto={fotoSchema4}/></div>
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase5")} fun={this.addSchema5} foto={fotoSchema5}/></div>
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase6")} fun={this.addSchema6} foto={fotoSchema6}/></div>
                        <div className="schemaButton"><ToolButton text={this.translate("schemaLoadCase7")} fun={this.addSchema7} foto={fotoSchema7}/></div>
                    </div>
                </div>

                {this.showModal()}

            </div>

            <div className="mefaInfoSectionWrapper">
            <div className="mefaInfoSection">
                    <div className="imgContainer">
                        <img src={fotoLogoMefa} alt="mefa-logo"/>
                    </div>
                    <div className="companyInfo">
                        {this.props.selectedLanguage !== "en" &&
                        <>
                            <span className="companyName"> {this.translate("companyName",this.props.selectedLanguage)}</span>
                            <span className="streetName"> {this.translate("streetName",this.props.selectedLanguage)}</span>
                            <span className="cityName"> {this.translate("cityName",this.props.selectedLanguage)}</span>
                            <a href={"https://"+this.translate("websiteName",this.props.selectedLanguage)} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="websiteName"> 
                                {this.translate("websiteName",this.props.selectedLanguage)}
                            </a>
                        </>
                        }
                    </div>
                </div>
            </div> 
            
        </div>  
       );
    }
}

const ToolButton = (props) => {
    let handleActionClick = props.fun

    return (<>
               <Button title={props.text}style={{height:"100%",width: "100%",background: "white",padding: "3px"}} variant="contained" 
        color="default" size="small" onClick={() => handleActionClick()}><img  alt="fot" style ={{width: "auto", height: "auto"}}
        src ={props.foto}/><label style={{fontSize: "0.55rem", margin: "0px"}}>{props.text}</label></Button>

    </>);
}

const mapDispatchToProps = dispatch => ({
    addSectionElement: sectionElement => dispatch(mefaActions.addSectionElement(sectionElement)),
    deleteSection: section => dispatch(mefaActions.deleteSectionElement(section)),
    addSupportElement: supportElement => dispatch(mefaActions.addSupportElement(supportElement)),
    addLoadElement: loadElement => dispatch(mefaActions.addLoadElement(loadElement)),
    updateSupportElementLeft: supportElement => dispatch(mefaActions.updateSupportElementLeft(supportElement)),
    updateSupportElementRight: supportElement => dispatch(mefaActions.updateSupportElementRight(supportElement)),
    addNodeElement: nodeElement => dispatch(mefaActions.addNodeElement(nodeElement)),
    changeSectionElement: (value) => dispatch(mefaActions.changeModelElement(value)),
    deleteLoadElement: loadElement => dispatch(mefaActions.deleteLoadElement(loadElement)),
    updateModel: model => dispatch(mefaActions.updateModel(model)),
    updateSupportElement: () => dispatch(mefaActions.updateSupportElement())
})

const mapStateToProps = state => ({
    selectedLanguage: state.mefa.present.selectedLanguage,
    selectedModelElement: state.mefa.present.selectedModelElement,
    beamElements: state.mefa.present.models[state.mefa.present.selectedModelIndex].elements,
    beamLoads: state.mefa.present.models[state.mefa.present.selectedModelIndex].loads,
    beamSupports: state.mefa.present.models[state.mefa.present.selectedModelIndex].supports,
    beamNodes: state.mefa.present.models[state.mefa.present.selectedModelIndex].nodes,
    currentModel:state.mefa.present.models[state.mefa.present.selectedModelIndex],
    numberModels: state.mefa.present.models.length,
    selectedModelId:state.mefa.present.selectedModelId,
    currentState: state.mefa.present
})

export default connect(mapStateToProps, mapDispatchToProps)(Tools)